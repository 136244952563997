import { BlockStack, Button, Checkbox, ChoiceList, DatePicker, FilterInterface, IndexFilters, IndexFiltersMode, InlineStack, Select, TabProps, TextField } from "@shopify/polaris";
import { FilterPillProps } from "@shopify/polaris/components/Filters";
import { ok, RecordMember, reducer_setValueByPath, SPPF, truthy } from "common";
import * as PrismaExtra from "prisma-client";
import { ColumnBase, DataListColumn, DataListEnumColumn, EditTreeAccessor } from '../utils';
import React, { Component, Dispatch, SetStateAction, useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLayoutEffectWatcher } from "react-utils";
import { MaybeTableViewArray, PrismaWhereQuery, TableView, TableViewFilter, TableViewOptions } from "./table-views";
import { AppliedFilterInterface } from "@shopify/polaris";
import { useReducer } from "react";

export function convertFiltersToQuery(curAppliedFilters: Pick<AppliedFilterProps, "key" | "filter" | "column">[]) {
  return curAppliedFilters.map(e => {
    if (!e.column || !e.filter) return [];
    const keys = e.key.split("/");
    if (!(e.column instanceof DataListColumn)) return [];
    // the typepath array should correspond to the parent of the key at the same index
    const { typename, typepath } = e.column;
    if (!typepath || !typename) return [];
    const keys2 = typepath.flatMap((e, i) => e.fields[keys[i]].isArray ? [keys[i], "some"] : [keys[i]]);
    return reducer_setValueByPath({}, keys2, () => e.filter);
  }).filter(truthy).flat();
}


export function useFiltersWithViews(
  cols: FilterColumn[],
  views: MaybeTableViewArray<readonly any[]>,
  curView: number,
  setView: (newView: number) => void,
) {

  const [curAppliedFilters, setAppliedFilters] = useAppliedFilters([]);

  useLayoutEffect(() => {
    setAppliedFilters({
      action: "rootview",
      item: {
        key: "/",
        label: "View: " + (views[curView]?.title ?? ""),
        filter: undefined,
        onRemove: (key: string) => { }
      }
    });
  }, [views, curView, setAppliedFilters]);

  const filters = useMemo((): FilterProps[] => [
    {
      key: "/",
      label: "View",
      filter: <ViewFilterWrapper {...{ views, curView, setView }} />,
      pinned: true,
      hideClearButton: true,
      disabled: !views.length,
    },
    ...cols.map(mapColumnsToFilters(curAppliedFilters, setAppliedFilters)).filter(truthy)
  ], [cols, views, curView, setView, curAppliedFilters, setAppliedFilters]);

  // console.log("filters", { filters, cols, views, curAppliedFilters });

  const onClearAll = useCallback(() => {
    setAppliedFilters({ action: "clear" });
  }, [setAppliedFilters]);

  const [curMode, setMode] = useState(IndexFiltersMode.Default);

  useLayoutEffectWatcher(() => {
    if (curMode === IndexFiltersMode.Default)
      setAppliedFilters({ action: "clear" });
    else if (curMode === IndexFiltersMode.Filtering)
      setAppliedFilters({ action: "resetInit" });
  }, [curMode, setAppliedFilters], [curView]);


  return { curAppliedFilters, setAppliedFilters, filters, onClearAll, curMode, setMode };

}


export function useColFilters(
  cols: FilterColumn[],
) {

  const [curAppliedFilters, setAppliedFilters] = useAppliedFilters([]);

  // useLayoutEffect(() => {
  //   setAppliedFilters({
  //     action: "rootview",
  //     item: {
  //       key: "/",
  //       label: "View: " + (views[curView]?.title ?? ""),
  //       filter: undefined,
  //       onRemove: (key: string) => { }
  //     }
  //   });
  // }, [views, curView, setAppliedFilters]);

  const filters = useMemo((): FilterProps[] => [
    // {
    //   key: "/",
    //   label: "View",
    //   filter: <ViewFilterWrapper {...{ views, curView, setView }} />,
    //   pinned: true,
    //   hideClearButton: true,
    //   disabled: !views.length,
    // },
    ...cols.map(mapColumnsToFilters(curAppliedFilters, setAppliedFilters)).filter(truthy)
  ], [cols, curAppliedFilters, setAppliedFilters]);

  // console.log("filters", { filters, cols, views, curAppliedFilters });

  const onClearAll = useCallback(() => {
    setAppliedFilters({ action: "clear" });
  }, [setAppliedFilters]);

  const [curMode, setMode] = useState(IndexFiltersMode.Default);

  // useLayoutEffectWatcher(() => {
  //   if (curMode === IndexFiltersMode.Default)
  //     setAppliedFilters({ action: "clear" });
  //   else if (curMode === IndexFiltersMode.Filtering)
  //     setAppliedFilters({ action: "resetInit" });
  // }, [curMode, setAppliedFilters], [curView]);


  return { curAppliedFilters, setAppliedFilters, filters, onClearAll, curMode, setMode };

}


export interface AppliedFilterProps extends AppliedFilterInterface {
  column?: FilterColumn;
  filter?: TableViewFilter | null;
  state?: unknown;
  clientCheck?(row: any, column: FilterColumn): boolean;
}
export interface SetAppliedFiltersProps {
  action: "add" | "remove" | "update" | "clear" | "rootview" | 'resetInit';
  item?: AppliedFilterProps;
}
export type SetAppliedFilters = React.Dispatch<SetAppliedFiltersProps>;

export function useAppliedFilters(initAppliedFilters?: AppliedFilterProps[]) {

  const [curAppliedFilters, setAppliedFilters] = useReducer(([root, ...rest]: AppliedFilterProps[], { action, item }: SetAppliedFiltersProps) => {
    // console.log("useAppliedFilters", action, item, root, rest);
    if (action === "add") {
      ok(item);
      return [root, ...rest, item];
    }
    if (action === "remove") {
      ok(item);
      const index = rest.indexOf(item);
      rest.splice(index, 1);
      return [root, ...rest];
    }
    if (action === "update") {
      return [root, ...rest];
    }
    if (action === "clear") {
      return [root, ...initAppliedFilters ?? []];
    }
    if (action === "resetInit") {
      const lookup = new Map<string, AppliedFilterProps>();
      initAppliedFilters?.forEach(e => lookup.set(e.key, e));
      return [root, ...initAppliedFilters ?? [], ...rest.filter(e => !lookup.has(e.key))];
    }
    if (action === "rootview") {
      ok(item);
      return [item, ...rest];
    }
    throw new Error("invalid action");
  }, null, () => [
    { key: "", label: "", onRemove: () => { } } as AppliedFilterProps,
    ...initAppliedFilters ?? []
  ]);

  return [curAppliedFilters, setAppliedFilters] as const;
}


function ViewFilterWrapper({ views, curView, setView }: {
  views: MaybeTableViewArray<readonly any[]>;
  curView: number;
  setView: (newView: number) => void
}) {
  return <Select
    label="View:" labelInline
    options={views.map((e, i) => ({ label: e.title, value: e.key }))}
    value={views[curView]?.key ?? ""}
    onChange={(value) => {
      const index = views.findIndex(e => e.key === value);
      if (index > -1) setView(index);
    }} />;
}
export interface FilterColumn {
  key: string;
  title: string;
  filterType: string;
  required: boolean;
  get: (row: any) => any;
  enumOptions?: {
    value: string;
    label: string;
  }[];

}
export interface FilterWrapperProps {
  column: FilterColumn;
  useFilter: FilterClass<any>
  curAppliedFilters: AppliedFilterProps[];
  setAppliedFilters: SetAppliedFilters;
  // onClose: () => void;
}

export interface FilterType<T> {
  state: T;
  label: string;
  markup: JSX.Element;
  prismaFilter: TableViewFilter | null | undefined;
  check: (row: any, column: FilterColumn) => boolean;
}
export interface FilterClass<T> {
  new(column: FilterColumn, init: T | null | undefined): FilterType<T>;
}


function FilterWrapper({ column, useFilter, curAppliedFilters, setAppliedFilters }: FilterWrapperProps) {

  const filterInit = curAppliedFilters.find(e => e.key === column.key)?.state;

  const filter = new useFilter(column, filterInit);

  const onClickClear = () => {
    const item = curAppliedFilters.find(e => e.key === column.key);
    if (item) setAppliedFilters({ action: "remove", item });
  }

  const onClickApply = () => {
    if (!filter) return;
    if (column.required && filter === null) {
      const item = curAppliedFilters.find(e => e.key === column.key);
      if (item) setAppliedFilters({ action: "remove", item });
    } else if (!filterInit) {
      const item: AppliedFilterProps = {
        key: column.key,
        label: filter.label,
        filter: filter.prismaFilter,
        state: filter.state,
        clientCheck: filter.check,
        column,
        onRemove: (key) => { setAppliedFilters({ action: "remove", item }); }
      };
      setAppliedFilters({ action: "add", item });
    } else {
      const item = curAppliedFilters.find(e => e.key === column.key);
      if (!item) return;
      item.filter = filter.prismaFilter;
      item.label = filter.label;
      item.state = filter.state;
      item.clientCheck = filter.check;
      setAppliedFilters({ action: "update", item });
    }
  };

  return (
    <BlockStack gap="300">
      {filter.markup}
      <InlineStack align="space-between">
        <Button variant="plain" onClick={onClickClear}>Clear</Button>
        <Button variant="plain" onClick={onClickApply}>Apply</Button>
      </InlineStack>
    </BlockStack>
  );
}


export type FilterProps = FilterInterface & Omit<FilterPillProps, "initialActive" |
  "label" |
  "filterKey" |
  "selected" |
  "onRemove" |
  "disabled" |
  "closeOnChildOverlayClick"
>;

export function mapColumnsToFilters(curAppliedFilters: AppliedFilterProps[], setAppliedFilters: SetAppliedFilters) {
  return (column: FilterColumn) => {

    const item: FilterProps = {
      key: column.key,
      label: column.title,
      hideClearButton: true,
      onAction: () => {
        console.log("on add filter action");
      },
      onClick: () => {
        console.log("on filter pill toggle popover");
      },
      filter: makeFilter(column, curAppliedFilters, setAppliedFilters),
    };
    return item.filter ? item : null;
  };


}

export function makeFilter(column: FilterColumn, curAppliedFilters: AppliedFilterProps[], setAppliedFilters: SetAppliedFilters): React.JSX.Element | null {
  if (!(column instanceof DataListColumn))
    return null;
  if (column.filterType === "none") return null;
  // filter operators are in the static property `filters` of the useFilter classes
  return <FilterWrapper
    column={column}
    curAppliedFilters={curAppliedFilters}
    setAppliedFilters={setAppliedFilters}
    useFilter={{
      text: useFilterItemText,
      numeric: useFilterItemNumeric,
      currency: useFilterItemNumeric,
      boolean: useFilterItemBoolean,
      enum: useFilterItemEnum,
      date: useFilterItemDate,
    }[column.filterType]} />;
}
interface SelectOption {
  label: string;
  value: string;
  array?: boolean;

}

interface useFilterInput<T extends TableViewFilter> {
  column: { title: string, key: string, required: boolean, text: (e: any) => string };
  filterInit: T | null | undefined;
  setAppliedFilters: SetAppliedFilters;
}
interface useFilterOutput<T extends TableViewFilter> {
  markup: ReturnType<typeof React.createElement>;
  filter: T | null | undefined;
  label: string;
}

export class useFilterItemDate implements FilterType<useFilterItemDate["state"]> {
  static filters: { [K in keyof PrismaExtra.Prisma.DateTimeFilter]: string; } = {
    equals: "is equal to",
    gt: "is after",
    gte: "is after or on",
    lt: "is before",
    lte: "is before or on",
  };
  state: {
    not: boolean;
    filter: keyof typeof useFilterItemDate.filters;
    value: Date | null;
  };
  label: string;
  markup;
  check;
  prismaFilter;
  constructor(column: FilterColumn, init: useFilterItemDate["state"] | undefined) {
    const { filters } = useFilterItemDate;
    const [not, setNot] = useState(init?.not ?? false);
    const [filter, setFilterType] = useState(init?.filter ?? "equals");
    const [value, setValue] = useState<Date | null>(init?.value ?? new Date());
    const setNull = useCallback((checked: boolean) => {
      if (checked) {
        setFilterType("equals");
        setValue(null);
        setMonth({ month: null, year: null });
      } else {
        const date = new Date();
        setValue(date);
        setMonth({ month: date.getMonth(), year: date.getFullYear() });
      }
    }, []);
    const setFilter2 = useCallback((v: string) => {
      (setFilterType as Dispatch<SetStateAction<string>>)(v);
      if (v !== "equals" && value === null) setNull(false);
    }, [value, setNull]);
    const [{ month, year }, setMonth] = useState({ month: value?.getMonth() ?? null, year: value?.getFullYear() ?? null });
    const handleMonthChange = useCallback((month: number, year: number) => setMonth({ month, year }), []);
    const handleDateSelect = useCallback((range: { start: Date, end: Date }) => {
      setValue(range.end);
      setMonth({ month: range.end.getMonth(), year: range.end.getFullYear() });
    }, []);

    this.state = { not, filter, value };
    this.label = `${not ? "Except if " : ""}${column.title} ${filters[filter]} ${value ? value.toDateString() : "null"}`;
    this.markup = (
      <BlockStack gap="200">
        <Select
          label={column.title}
          labelInline
          options={Object.entries(filters).map(([value, label = ""]) => ({ value, label } as const))}
          value={filter}
          onChange={setFilter2}
        />
        <Checkbox label="Is not set" checked={value === null} onChange={setNull} />
        {value !== null && year !== null && month !== null && <DatePicker
          month={month}
          year={year}
          selected={value}
          onChange={handleDateSelect}
          onMonthChange={handleMonthChange}
        />}
        <Checkbox label="Not matching" checked={not} onChange={setNot} />
      </BlockStack>
    );
    this.check = useCallback((row: any, column: FilterColumn) => {
      const val = column.get(row);
      const result = (() => {
        if (val === undefined || val === null) return value === null;
        if (value === null) return false;
        switch (filter) {
          case "equals": return val === value;
          case "lt": return val < value;
          case "lte": return val <= value;
          case "gt": return val > value;
          case "gte": return val >= value;
          default: throw new Error(`Unhandled filter key: ${filter}`);
        }
      })();
      return not ? !result : result;
    }, [filter, value, not]);
    this.prismaFilter = useMemo(() => {
      if (!filter) return undefined;
      const inner2 = { [filter]: value };
      return not ? { not: inner2 } : inner2 as TableViewFilter;
    }, [filter, value, not]);
  }

}

export class useFilterItemEnum implements FilterType<useFilterItemEnum["state"]> {
  static filters: { [K in keyof PrismaExtra.Prisma.EnumItemTypeFilter]: string; } = {
    // equals: "is",
    in: "is one of",
    notIn: "is not one of",
  };
  state: {
    filter: keyof typeof useFilterItemEnum.filters;
    value: string[];
    not: boolean;
  };
  label: string;
  markup;
  check;
  prismaFilter;
  constructor(column: FilterColumn, init: useFilterItemEnum["state"] | undefined) {
    const filters = useFilterItemEnum.filters;
    // ok(column instanceof DataListEnumColumn);
    ok(column.enumOptions);
    const [filter, setFilterType] = useState(init?.filter ?? "in");
    const [value, setValue] = useState(init?.value ?? []);
    const [not, setNot] = useState(init?.not ?? false);
    this.state = { filter, value, not };
    this.label = `${not ? "Except if " : ""}${column.title} ${filters[filter]} ${value.join(", ") || "---"}` || "";
    this.markup = (
      <BlockStack gap="200">
        {Object.keys(filters).length > 1 ? <Select
          label={column.title}
          labelInline
          options={Object.entries(filters).map(([value, label = ""]) => ({ value, label } as const))}
          value={filter}
          onChange={setFilterType as Dispatch<SetStateAction<string>>}
        /> : null}
        <ChoiceList
          titleHidden
          allowMultiple
          title={column.title}
          choices={column.enumOptions}
          selected={value}
          onChange={setValue}
        />
        <Checkbox label="Not matching" checked={not} onChange={setNot} />
      </BlockStack>
    );
    this.check = (row: any, column: FilterColumn) => {
      const val = column.get(row);
      if (val === undefined || val === null) return false;
      const result = (() => {
        switch (filter) {
          case "in": return value.includes(val);
          case "notIn": return !value.includes(val);
          default: throw new Error(`Unhandled filter key: ${filter}`);
        }
      })();
      return not ? !result : result;
    };
    this.prismaFilter = useMemo(() => {
      if (!filter) return undefined;
      const inner2 = { [filter]: value };
      return not ? { not: inner2 } : inner2 as TableViewFilter;
    }, [filter, value, not]);
  }
}

export class useFilterItemBoolean implements FilterType<useFilterItemBoolean["state"]> {
  static filters: { [K in keyof PrismaExtra.Prisma.BoolFilter]: string; } = {
    equals: "is",
  };
  state: {
    filter: keyof typeof useFilterItemBoolean.filters;
    value: boolean;
    not: boolean;
  };
  label: string;
  markup;
  check;
  prismaFilter;
  constructor(column: FilterColumn, init: useFilterItemBoolean["state"] | undefined) {
    const filters = useFilterItemBoolean.filters;
    const [filter, setFilterType] = useState(init?.filter ?? "equals");
    const [value, setValue] = useState(init?.value ?? false);
    const [not, setNot] = useState(init?.not ?? false);
    this.state = { filter, value, not };
    this.label = `${not ? "Except if " : ""}${column.title} ${filters[filter]} ${value ?? "---"}` || "";
    this.markup = (
      <BlockStack gap="200">
        {Object.keys(filters).length > 1 ? <Select
          label={column.title}
          labelInline
          options={Object.entries(filters).map(([value, label = ""]) => ({ value, label } as const))}
          value={filter}
          onChange={setFilterType as Dispatch<SetStateAction<string>>}
        /> : null}
        <Checkbox label={column.title} checked={value} onChange={setValue} />
        <Checkbox label="Not matching" checked={not} onChange={setNot} />
      </BlockStack>
    );
    this.check = (row: any, column: FilterColumn) => {
      const val = column.get(row);
      if (val === undefined || val === null) return false;
      const result = (() => {
        switch (filter) {
          case "equals": return val === value;
          default: throw new Error(`Unhandled filter key: ${filter}`);
        }
      })();
      return not ? !result : result;
    };
    this.prismaFilter = useMemo(() => {
      if (!filter) return undefined;
      const inner2 = { [filter]: !!value };
      return not ? { not: inner2 } : inner2 as TableViewFilter;
    }, [filter, value, not]);
  }
}


export class useFilterItemNumeric implements FilterType<useFilterItemNumeric["state"]> {
  static filters: {
    [K in keyof (PrismaExtra.Prisma.FloatFilter & PrismaExtra.Prisma.IntFilter)]: string;
  } = {
      equals: "is equal to",
      gt: "is greater than",
      gte: "is greater than or equal to",
      lt: "is less than",
      lte: "is less than or equal to",
    }
  state: {
    filter: keyof typeof useFilterItemNumeric.filters;
    value: string;
    not: boolean;
  };
  label: string;
  markup: JSX.Element;
  check;
  prismaFilter;
  constructor(column: FilterColumn, init: useFilterItemNumeric["state"] | undefined) {
    const [filter, setFilterType] = useState(init?.filter ?? "equals");
    const [value, setValue] = useState(init?.value ?? "");
    const [not, setNot] = useState(init?.not ?? false);
    this.state = { filter, value, not };
    this.label = `${not ? "Except if " : ""}${column.title} ${useFilterItemNumeric.filters[filter]} ${value ?? "---"}` || "";
    this.markup = (
      <BlockStack gap="200">
        <Select
          label={column.title}
          labelInline
          options={Object.entries(useFilterItemNumeric.filters).map(([value, label = ""]) => ({ value, label } as const))}
          value={filter}
          onChange={setFilterType as Dispatch<SetStateAction<string>>}
        />
        <TextField
          type="number"
          autoComplete="off"
          label=""
          labelHidden
          placeholder="Filter value"
          value={value ?? 0}
          onChange={setValue}
        />
        <Checkbox label="Not matching" checked={not} onChange={setNot} />
      </BlockStack>
    );
    this.check = useCallback((row: any, column: FilterColumn) => {
      const val = column.get(row);
      if (val === undefined || val === null) return false;
      const result = (() => {
        switch (filter) {
          case "equals": return val === parseFloat(value);
          case "lt": return val < parseFloat(value);
          case "lte": return val <= parseFloat(value);
          case "gt": return val > parseFloat(value);
          case "gte": return val >= parseFloat(value);
          default: throw new Error(`Unhandled filter key: ${filter}`);
        }
      })();
      return not ? !result : result;
    }, [filter, value, not]);
    this.prismaFilter = useMemo(() => {
      if (!filter) return undefined;
      if (!value) return null;
      const inner3 = parseFloat(value);
      const inner2 = { [filter]: inner3 };
      return not ? { not: inner2 } : inner2 as TableViewFilter;
    }, [filter, value, not]);
  }
}

export class useFilterItemText implements FilterType<useFilterItemText["state"]> {
  static filters: {
    [K in keyof PrismaExtra.Prisma.StringFilter]?: string;
  } = {
      contains: "contains",
      startsWith: "starts with",
      endsWith: "ends with",
      equals: "is equal to",
      lt: "is less than",
      lte: "is less than or equal to",
      gt: "is greater than",
      gte: "is greater than or equal to",
    }
  state: {
    filter: keyof typeof useFilterItemText.filters;
    value: string;
    caseSen: boolean;
    not: boolean;
  };
  label: string;
  markup;
  check;
  prismaFilter;
  constructor(column: FilterColumn, init: useFilterItemText["state"] | undefined) {

    const [filter, setFilterType] = useState(init?.filter ?? "contains");
    const [value, setValue] = useState(init?.value ?? "");
    const [caseSen, setCaseSensitive] = useState(init?.caseSen ?? false);
    const [not, setNot] = useState(init?.not ?? false);
    this.state = { filter, value, caseSen, not };
    this.label = `${not ? "Except if " : ""}${column.title} ${useFilterItemText.filters[filter]} ${value ?? "---"}` || "";
    this.markup = (
      <BlockStack gap="200">
        <Select
          label={column.title}
          labelInline
          options={Object.entries(useFilterItemText.filters).map(([value, label = ""]) => ({ value, label } as const))}
          value={filter}
          onChange={setFilterType as Dispatch<SetStateAction<string>>}
        />
        <TextField
          autoComplete="off"
          label=""
          labelHidden
          placeholder="Filter value"
          value={value ?? ""}
          onChange={setValue}
        />
        <Checkbox label="Case sensitive" checked={caseSen} onChange={setCaseSensitive} />
        <Checkbox label="Not matching" checked={not} onChange={setNot} />
      </BlockStack>
    );
    this.check = (row: any, column: FilterColumn) => {
      const check = value;
      const mode = caseSen ? "valueOf" : "toLowerCase";
      const val = column.get(row)?.[mode]();
      if (val === undefined || val === null) return false;
      const result = (() => {
        switch (filter) {
          case "contains": return val.includes(check);
          case "startsWith": return val.startsWith(check);
          case "endsWith": return val.endsWith(check);
          case "equals": return val === check;
          case "lt": return val < check;
          case "lte": return val <= check;
          case "gt": return val > check;
          case "gte": return val >= check;
          default: throw new Error(`Unhandled filter key: ${filter}`);
        }
      })();
      return not ? !result : result;
    };
    this.prismaFilter = useMemo(() => {
      if (!filter) return undefined;
      const inner2 = { [filter]: value };
      const inner = not ? { not: inner2 } : inner2;
      return !value ? null : { ...inner, mode: caseSen ? "default" : "insensitive" } satisfies PrismaExtra.Prisma.StringFilter;
    }, [filter, value, caseSen, not]);
  }

}
