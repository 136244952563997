import React, { useLayoutEffect, useMemo } from "react";
import { useCallback, useRef } from "react";

/**
 * Returns a dispatch function which does not change for the lifetime of the component. 
 * The callback is updated syncly using useMemo, so it cannot have a cleanup function.
 * Be careful not to let it outlive the component. 
 * 
 * `return callbackRef.current.apply(this, arguments)` is used to call the callback.
 * 
 * @param callback The callback which gets called when the dispatch function is called.
 */
export function useDispatch<F extends Function>(callback: F): F {
  const callbackRef = useRef(callback);
  useMemo(() => {
    callbackRef.current = callback;
  }, [callback]);
  return useCallback(function (this: unknown) {
    return callbackRef.current.apply(this, arguments)
  }, []) as any;
}
