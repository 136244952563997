import { EventEmitter, Injector } from "@angular/core";
import { ReactInjectable } from "./ReactInjector";
import { Observable } from "rxjs";

/**
 * Represents a confirmation dialog configuration.
 * @group Interface
 */
export interface Confirmation {
  // key?: string;
  // icon?: string;
  /** Title of the confirmation. */
  header?: string;
  /** Body text of the confirmation. */
  message?: string;
  /** Called before acceptEvent if both are defined */
  accept?: Function;
  /** Called before rejectEvent if both are defined */
  reject?: Function;
  /**  */
  error?: (error: any) => void;
  acceptLabel?: string;
  rejectLabel?: string;
  acceptIcon?: string;
  rejectIcon?: string;
  acceptVisible?: boolean;
  rejectVisible?: boolean;
  allowFormClose?: boolean;
  // blockScroll?: boolean;
  // closeOnEscape?: boolean;
  // dismissableMask?: boolean;
  // defaultFocus?: string;
  // acceptButtonStyleClass?: string;
  // rejectButtonStyleClass?: string;
  // target?: EventTarget;
  /** Called after accept if both are defined */
  acceptEvent?: EventEmitter<any>;
  /** Called after reject if both are defined */
  rejectEvent?: EventEmitter<any>;
}
/**
 * Deines valid options for the message.
 * @group Interface
 */
export interface Message {
  severity?: string;
  summary?: string;
  detail?: string;
  id?: any;
  key?: string;
  life?: number;
  sticky?: boolean;
  closable?: boolean;
  data?: any;
  icon?: string;
  contentStyleClass?: string;
  styleClass?: string;
  closeIcon?: string;
}
@ReactInjectable()
export class ConfirmationService {
  constructor(injector: Injector){}
  private requireConfirmationSource: EventEmitter<Confirmation> = new EventEmitter<Confirmation>();
  requireConfirmationObserver: Observable<Confirmation> = this.requireConfirmationSource.asObservable();
  /**
   * Callback to invoke on confirm.
   * @param {Confirmation} confirmation - Represents a confirmation dialog configuration.
   * @group Method
   */
  confirm(confirmation: Confirmation): this {
    this.requireConfirmationSource.emit(confirmation);
    return this;
  }

}

@ReactInjectable()
export class MessageService {
  constructor(injector: Injector){}
  private messageSource: EventEmitter<Message | Message[]> = new EventEmitter<Message | Message[]>();
  private clearSource: EventEmitter<string> = new EventEmitter<string>();
  messageObserver: Observable<Message | Message[]> = this.messageSource.asObservable();
  clearObserver: Observable<string> = this.clearSource.asObservable();
  /**
   * Inserts single message.
   * @param {Message} message - Message to be added.
   * @group Method
   */
  add(message: Message): void {
    this.messageSource.emit(message);
  }
  /**
   * Inserts new messages.
   * @param {Message[]} messages - Messages to be added.
   * @group Method
   */
  addAll(messages: Message[]): void {
    this.messageSource.emit(messages);
  }
  /**
   * Clears the message with the given key.
   * @param {string} key - Key of the message to be cleared.
   * @group Method
   */
  clear(key?: string): void {
    this.clearSource.emit(key);
  }
}
