import React, { useLayoutEffect } from "react";
import { useCallback, useRef } from "react";

/** 
 * Returns a memo-ized function which can be called to trigger the callback. 
 * The callback is updated via useLayoutEffect, so 
 */

export function useDispatch(cb: () => void): React.DispatchWithoutAction;
export function useDispatch<T>(cb: (value: T) => void): React.Dispatch<T>;
export function useDispatch<T>(cb: (value: T) => Promise<void>): (value: T) => Promise<void>;
export function useDispatch(cb: (value: any) => void | Promise<void>) {
  const callback = useRef((value: any) => { });
  useLayoutEffect(() => { callback.current = cb; }, [cb]);
  return useCallback((value: any) => callback.current(value), []) as any;
}
