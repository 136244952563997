import React, { useCallback, useId, useMemo, useState } from "react";
import { Box, Card, InlineStack, Labelled, LabelledProps, Listbox, Spinner, Text, TextField, Tooltip } from "@shopify/polaris";
import { ColumnBase, DataListColumn, QuestionSelectOptions } from '../utils';
import { normalizeOption, SelectDropdown, SelectProps } from "./Select";
import styles from '@shopify/polaris/components/Select/Select.scss';
import { PopoverDropdown, useComboBoxState } from "./TextFieldDropdown";
import { TextFieldReadonly } from "./TextFieldReadonly";
import { MatIcon } from "react-utils";
import { valMarkup } from "../tables/valMarkup";

interface QuestionSelectLabelProps<T extends Record<string, any>> {
  id: string;
  label: string;
  required: boolean;
  disabled: boolean;
  readOnly: boolean;
  autoComplete: string;

  optionDisabled?: string & keyof T;
  optionLabels: ColumnBase[];
  optionIcon?: string & keyof T;
  placeholder?: string;
  loading?: boolean;
  /** if optionValue is set, this will be T[optionValue], otherwise it will be T */
  value: any;

  onClick?: () => void;
}



export function QuestionSelectLabel<T extends Record<string, string>>({
  optionDisabled,
  optionLabels,
  optionIcon,
  value,
  placeholder,
  disabled: disabledProp,
  loading,
  ...props
}: QuestionSelectLabelProps<T>) {

  return (
    <TextFieldReadonly
      disabled={optionDisabled ? !!value?.[optionDisabled] : false || disabledProp}
      fieldMarkup={
        !value ? <>
          {loading ? <Spinner accessibilityLabel="Loading field options" size="small" /> : placeholder}
        </> : <>
          {optionIcon && <div className={styles.Prefix}>{<MatIcon>{value[optionIcon]}</MatIcon>}</div>}
          <InlineStack as="span" wrap={false} gap="200" >{optionLabels.map(label => (
            <Tooltip content={label.title} key={label.title}>{valMarkup(label, value)}</Tooltip>
          ))}</InlineStack>
        </>
      }
      {...props}
    />
  );

}
