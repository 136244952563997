import React, { useCallback, useEffect, useId, useLayoutEffect, useMemo, useReducer, useRef } from "react";
import { FileUploadState, QuestionFileUpload } from "../utils/question-base";
import { FileData, FileUpload as FileUploadComp, FileViewerComp, UploadFilesRedux } from "./FileUpload";
import { CardTitle } from "../../../../../react-utils/CardTitle";
import { BlockStack, Button, Card, Collapsible, Divider } from "@shopify/polaris";
import { ButtonAwait, useAngular, useAsyncEffect, useEventEmitter, useObservable, useObserver } from "react-utils";
import { truthy } from "common";
import { DataService } from "data-service";
const noop = (): any => { };
export function QuestionFileUploadComp({ q }: { q: QuestionFileUpload, }) {
  const { get } = useAngular();
  const data = get(DataService);
  const id = useId();

  const [newFiles, setNewFiles] = useReducer(UploadFilesRedux, q.initState ?? { files: [], names: [], thumbs: [] });

  useLayoutEffect(() => {
    q.onStateChange?.(newFiles);
  }, [q, newFiles]);

  const onClickUpload = useCallback(async () => {
    const { files, names } = newFiles;
    await q.uploadFiles(files, names);
  }, [q, newFiles]);

  const value = useObservable(q.form.valueChanges, q.form.value);
  const thumbs = useObservable(q.thumbs, undefined);

  const savedFiles = useMemo(() => value?.map((e, i) => e.key && e.title && e.size && e.type && ({
    key: e.key,
    name: e.title,
    size: e.size,
    type: e.type,
    thumb: thumbs?.[i] ?? undefined,
    onRemove: async () => {
      await q.deleteFromGallery?.(e);
    }
  }) || null).filter(truthy) ?? [], [q, value, thumbs]);


  const isFileOpen = useRef<boolean>(false);
  const fileOpenChange = useEventEmitter<FileData | null>();
  useObserver(fileOpenChange, (file) => { isFileOpen.current = !!file; });
  const checkFileOpen = useCallback((file: File) => !isFileOpen.current, []);

  return (
    <Card>
      <BlockStack gap="400">
        {q.showGallery && (<>
          <FileViewerComp id="saved" files={savedFiles} fileOpenChange={fileOpenChange} />
          <Divider />
        </>)}
        <Collapsible id={id + "upload-button"} open={!!newFiles.files.length}>
          <CardTitle padding="0" title={q.title ?? "File Upload"}>
            <ButtonAwait variant="primary" onClick={onClickUpload}>Upload</ButtonAwait>
          </CardTitle>
        </Collapsible>
        <FileUploadComp state={newFiles} setState={setNewFiles} fileOpenChange={fileOpenChange} customValidator={checkFileOpen} />
      </BlockStack>
    </Card>
  );
}
