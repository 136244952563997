import { EventEmitter, Injector, NgZone } from '@angular/core';
import { is, Modes, schema, getPreviousRentalStatus, ZoneGuard, TABLE_NAMES, FEATURE_FLAG_RENT_TO_OWN, FEATURE_FLAG_PRICE_OVERRIDE } from "common";
import * as PrismaExtra from "prisma-client";
import { DataService } from "data-service";
import { RentalStatus } from 'prisma-client';
import { asTypedQuestionGroup, FGCR, FGCV, FileUploadState, QuestionGroup, QuestionSelect, QuestionSimple, showPageEditModal, UIService, FormsQuestionService } from '.';
import { GroupDataDialog } from './question-dialog';
import { ReactInjectable, emitGlobalRefresh, globalMessage, useObservable } from 'react-utils';
import { renderGroupControls } from '../components/QuestionForm';
import React from 'react';
import { BlockStack } from '@shopify/polaris/index';
import { customerRentalViewResult } from '../pages/page-customer-edit';

@ReactInjectable()
export class RentalListState {

  private data: DataService;
  private ui: UIService;
  private fq: FormsQuestionService;
  public zone: NgZone;

  private customerRentalStageLookup;
  public RentalStatusEnum;

  cols;
  customerRentalStages;
  onSaveSuccess = new EventEmitter<object>();
  constructor(injector: Injector) {
    this.data = injector.get(DataService);
    this.ui = injector.get(UIService);
    this.fq = injector.get(FormsQuestionService);
    this.zone = injector.get(NgZone);
    this.RentalStatusEnum = schema.enums.RentalStatus.options;
    this.customerRentalStages = Object.keys(this.RentalStatusEnum).sort((a, b) => this.RentalStatusEnum[a].order - this.RentalStatusEnum[b].order);
    this.customerRentalStageLookup = this.customerRentalStages.reduce((n, e) => (n[e] = true, n), {} as any);
    this.cols = {
      RentalStatus: { get: (e: customerRentalViewResult[number]) => e.RentalStatus },
      IsRentToOwn: { get: (e: customerRentalViewResult[number]) => e.IsRentToOwn },
    };
  }

  // onClickEvent(e: ClickEvent) {
  //   // the only reason this is here is to handle showAdd("InvoiceLine") from children
  //   if (e.action === "add" && e.table === "InvoiceLine") {
  //     console.error(e);
  //     this.onClickExtraCharges();
  //   } else {
  //     this.fq.onClickEvent.emit(e);
  //   }
  // }

  stageFilter(stage: string) {
    return (e: any, i: number) => {
      if (stage === "")
        return !this.customerRentalStageLookup[this.cols.RentalStatus.get(e)];

      else
        return this.cols.RentalStatus.get(e) === stage;
    };
  }

  disabled(row: customerRentalViewResult[number]) {
    return this.cols.RentalStatus.get(row) === "Archived";
  }

  navigate(table: TABLE_NAMES, id: string) {
    this.fq.showEdit(table, id);
  }


  createRentalFormGroup = (customerID: string, isRentToOwn: boolean) => {
    const group = this.ui.schema.RentalCreateType("CREATE");
    group.controls.customerID.form.setValue(customerID);
    group.controls.IsRentToOwn.form.setValue(isRentToOwn);

    if (this.data.status.branchType === "DEALER") {
      group.controls.currentBranchID.form.setValue(this.data.status.branchID);
      group.controls.currentBranchID.hidden = true;
    }

    group.form.valueChanges.subscribe((e) => {
      // the proxy doesn't make calls, it just returns an object containing the args
      // but the returned type would be the result of making the call, so we can grab it with typeof
      const val = (() => this.data.proxy.unit.findMany({
        select: {
          id: true,
          Name: true,
          unitType: {
            select: {
              Name: true
            }
          },
          AvailableSince: true,
          Unavailable: true,
          currentRental: {
            select: {
              StartDate: true,
              EndDate: true,
            }
          }
        },
      }));

      const hidden = !e.unitTypeID || !e.currentBranchID;
      group.controls.promotionID.hidden = hidden || isRentToOwn;
      group.controls.StartDate.hidden = hidden;
      group.controls.unitID.hidden = hidden;
      group.controls.rentals.hidden = !e.unitID;
      group.controls.RentToOwnTotal.hidden = !isRentToOwn;
      group.controls.RentToOwnTotal.required = isRentToOwn;
      group.controls.RentToOwnTotal.onlyfor = !isRentToOwn ? [] : undefined;
      const unitID = group.controls.unitID as QuestionSelect<Awaited<ReturnType<typeof val>>[number], "id">;
      console.log(e, unitID);

      if (unitID.selectedOption?.currentRental) {
        if (unitID.selectedOption.currentRental.EndDate && e.StartDate < unitID.selectedOption.currentRental.EndDate) {
          unitID.errortext = "This unit is currently rented. The end date for it is "
            + unitID.selectedOption.currentRental.EndDate
            + " which is overlaps the start date you have selected. "
            + "You can still schedule this, but you can't start the rental without ending the active one first.";
        } else if (!unitID.selectedOption.currentRental.EndDate) {
          unitID.errortext = "This unit is currently rented. The current rental has no end date set. "
            + "You can still schedule this, but you can't start the rental without ending the active one first.";
        } else {
          unitID.errortext = undefined;
        }
      } else {
        unitID.errortext = undefined;
      }

    });
    return group;
  };

  async onClickScheduleUnit(customerID: string, isRentToOwn: boolean) {

    if (typeof customerID !== "string") throw new Error("id not set");
    const dialog = this.fq.createBasicGroupDialog("Rental", "CREATE", "",
      () => this.createRentalFormGroup(customerID, isRentToOwn),
      async function (value) {

        await this.data.server.serverCreateRental(value);

        globalMessage.add({ severity: 'success', summary: "Rental Created", detail: "Rental created successfully!" });

        this.onSaveSuccess.emit({});

        this.subs.unsubscribe();

      });

    await dialog.pageSetup(false);
    dialog.showDelete = false;
    dialog.onSaveSuccess.subscribe(() => { emitGlobalRefresh(); });

  }



  private rentalactions = [
    { label: "Edit Rental", command: (row: any) => { this.onClickEditRental(row); } },
    { label: "Add Charges", command: (row: any) => { this.onClickExtraCharges(row); } },
    // { label: "Manage Photos", command: (row: any) => { this.onClickManagePhotos(row); } },
    { separator: true },
    { label: "Reverse Status", command: (row: any) => { this.onClickReverseStatus(row); } },
    { separator: true },
    { label: "Cancel Rental", command: (row: any) => { this.onClickCancelRental(row); } },
  ];

  getNextStatus(row: any) {
    const cur = this.cols.RentalStatus.get(row) as RentalStatus;
    const IsRentToOwn = this.cols.IsRentToOwn.get(row) as boolean | null;
    if (IsRentToOwn === undefined)
      console.error("IsRentToOwn is undefined");

    switch (cur) {
      case "Reserved": return { next: "Scheduled", label: "Schedule Rental", others: [] };
      case "Scheduled":
        if (IsRentToOwn)
          return { next: "RentToOwn", label: "Start Rent To Own", others: [] };
        else
          return { next: "Rented", label: "Start Rental", others: [] };
      case "Rented": return { next: "Moving_Out", label: "Schedule Move Out", others: [] };
      case "RentToOwn": return { next: "SoldToCustomer", label: "Sold To Customer", others: ["Moving_Out"] };
      case "Moving_Out": return { next: "Completed", label: "Finalize Move Out", others: [] };
      case "Completed": return { next: "Released", label: "Release Unit", others: ["Retained"] };
      case "Retained": return { next: "Released", label: "Release Unit", others: [] };
      case "Released":
      case "Archived":
      case "SoldToCustomer":
        return null;
      default:
        { const t: never = cur; }
        throw new Error("Invalid rental status " + cur);
    }
  }
  private nextstatus: Record<RentalStatus, RentalStatus[]> = {
    Reserved: ["Scheduled"],
    Scheduled: ["Rented", "RentToOwn"],
    Rented: ["Moving_Out"],
    RentToOwn: ["SoldToCustomer", "Moving_Out"],
    Moving_Out: ["Completed"],
    Completed: ["Released"],
    Retained: ["Released"],
    Released: [],
    Archived: [],
    SoldToCustomer: [],
  };
  private nextstatuslabels: Record<RentalStatus, string> = {
    Reserved: "",
    Scheduled: "Schedule Rental",
    Rented: "Start Rental",
    RentToOwn: "Start Rent To Own",
    Moving_Out: "Schedule Move Out",
    Completed: "Finalize Move Out",
    Retained: "Hold Unit",
    Released: "Release Unit",
    Archived: "Archive Rental",
    SoldToCustomer: "Sold To Customer",
  };
  // Schedule Rental
  // Start Rental 
  // Schedule Move Out
  // Finish Move Out
  private createRentalDialog<M extends Modes, G extends QuestionGroup<"Rental", FGCR>>(
    mode: M,
    id: string,
    group: () => G,
    onSave?: (this: GroupDataDialog<"Rental", M, G>, value: Record<string, any>) => Promise<void>
  ) {

    const dialog = this.fq.createGroupDataDialog("Rental", mode, group);
    dialog.id = id;
    if (onSave) dialog.onSaveValue = onSave;
    dialog.onSaveError = () => { };
    return dialog;
  }

  async onClickEditPhotos(row: any) {
    const state: FileUploadState = { files: [], names: [], thumbs: [] };
    console.log(row);
    if (!row.id) throw "row.id not found";
    const group = () => {

      return new QuestionGroup({
        __typename: "Rental",
        controls: {
          Photos: this.fq.QuestionRentalUploadPhotos({
            rentalID: row.id,
            state,
            showGallery: true,
            showThumbs: true,
            onUploadComplete: async () => { await dialog.pageSetup(true); },
          })
        }
      });

    };
    const dialog = this.createRentalDialog("UPDATE", row.id, group, async function (value) {
      const { EndDate, StartDate, promotion } = value;
      await this.data.server.serverEditRental({ id: row.id, promotion, StartDate, EndDate, });
      globalMessage.add({ severity: 'success', summary: "Rental Saved!", detail: `Rental updated!` });
      this.onSaveSuccess.emit({});
      this.pageSetup(true);
    });
    await dialog.pageSetup(false);
    dialog.group?.form.patchValue(row);
    dialog.onSaveSuccess.subscribe(() => { emitGlobalRefresh(); })
  }
  @ZoneGuard()
  async onClickNextStatus(row: any) {
    // this line breaks the build with a TODO that needs to be done

    const cur = this.cols.RentalStatus.get(row);
    const IsRentToOwn = this.cols.IsRentToOwn.get(row);
    switch (cur) {
      case 'Reserved': return this.onClickUpdateStatus(row, ["Scheduled"], "The day the customer wants to move in.");
      case 'Scheduled':
        if (IsRentToOwn)
          return this.onClickUpdateStatus(row, ["RentToOwn"], "The first day the customer had access to the unit.");
        else
          return this.onClickUpdateStatus(row, ["Rented"], "The first day the customer had access to the unit.");
      case 'Rented': return this.onClickUpdateStatus(row, ["Moving_Out"], "The day the customer wants to move out.");
      case 'RentToOwn': return this.onClickUpdateStatus(row, ["SoldToCustomer", "Moving_Out"], "The day the customer wants to move out.");
      case 'Moving_Out': return this.onClickUpdateStatus(row, ["Completed"], "The last day the customer had access to the unit.");
      case 'Completed': return this.onClickUpdateStatus(row, ["Released", "Retained",], "");
      case 'Retained': return this.onClickUpdateStatus(row, ["Released"], "");
    }

  }
  @ZoneGuard()
  async onClickReverseStatus(row: any) {

    const current = this.cols.RentalStatus.get(row);
    const isRentToOwn = !!this.cols.IsRentToOwn.get(row);
    const previous: RentalStatus = getPreviousRentalStatus(current, isRentToOwn);
    let message = "";
    switch (current) {
      case 'Scheduled':
        message = "The Start Date field will be cleared";
        break;
      case 'Moving_Out':
        message = "The End Date field will be cleared.";
        break;
      case 'Released':
        message = "This tries to reclaim the unit and will fail if the unit has already been assigned to another rental.";
        break;

    }

    await this.fq.messageConfirmation("Reverse Status", `Are you sure you want to reverse the status of this rental from ${current} to ${previous}. ${message}`, async () => {
      await this.data.server3("serverReverseRentalStatus")({ rentalID: row.id, RentalStatus: previous, });
      this.onSaveSuccess.emit({});
    });


  }
  @ZoneGuard()
  async onClickCancelRental(row: any) {

    await this.fq.messageConfirmation("Cancel Rental", `Are you sure you want to cancel this rental? This cannot be undone.`, async () => {
      await this.data.server3("serverCancelRental")({ rentalID: row.id, });
      this.onSaveSuccess.emit({});
    });

  }
  /** For types only */
  get groupEditRentalControlKeys(): "RentalStatus" | "promotion" | "StartDate" | "EndDate" | "ExtraCharges" | "UnitRentalCharges" | "IsRentToOwn" | "PriceOverride" | "RentToOwnTotal" {
    throw new Error("For types only");
  }
  private groupEditRental = <C extends RentalListState["groupEditRentalControlKeys"]>({ controls, status }: {
    controls: C[],
    status: PrismaExtra.RentalStatus[] | null,
    // rentalID: string,
    // includePhotos: boolean,
    // photosPageSetup: () => Promise<void>,
    // state?: FileUploadState;
  }) => {

    const group: QuestionGroup<"Rental", Record<RentalListState["groupEditRentalControlKeys"], FGCV>>
      = this.ui.makeCustomType("RentalFormUpdateStatus", "Rental", controls)("UPDATE");

    if (status && is<QuestionSelect<any, any>>(group.controls.RentalStatus, !!group.controls.RentalStatus)) {
      const options = this.ui.schema.RentalStatus.filter(e => status.contains(e.value));
      if (options.length === 0) throw new Error("No options found for this status");
      group.controls.RentalStatus.options = Promise.resolve(options);
      group.controls.RentalStatus.form.setValue(status[0]);
      group.controls.RentalStatus.preventUpdate = false;
    }

    if (group.controls.ExtraCharges) {
      group.controls.ExtraCharges.title = "Add Charges";
      group.controls.ExtraCharges.hidden = false;
      group.controls.ExtraCharges.onlyfor = undefined;
      group.controls.ExtraCharges.preventCreate = false;
      group.controls.ExtraCharges.preventUpdate = false;
      group.controls.ExtraCharges.form.setValue([]);
    }

    if (group.controls.StartDate) {
      group.controls.StartDate.required = true;
    }

    if (group.controls.EndDate) {
      group.controls.EndDate.required = true;
    }

    if (group.controls.IsRentToOwn) {
      group.controls.IsRentToOwn.preventUpdate = this.data.userRole !== "web_admin";
      group.controls.IsRentToOwn.form.valueChanges.subscribe((value) => {
        group.controls.RentToOwnTotal.hidden = !value;
      });
    }

    if (group.controls.RentToOwnTotal) {
      group.controls.RentToOwnTotal.preventUpdate = this.data.userRole !== "web_admin";
    }

    return asTypedQuestionGroup(group, x => [
      x.unit.currentOwner.id.__,
      x.unit.currentBranch.id.__,
    ])
  };
  @ZoneGuard()
  async onClickUpdateStatus(row: any, status: PrismaExtra.RentalStatus[], helptext1: string) {
    console.log(row);
    if (!row.id) throw "row.id not found";
    const state: FileUploadState = { files: [], names: [], thumbs: [] };
    const group = () => {
      const controls: ("RentalStatus" | "promotion" | "StartDate" | "EndDate" | "ExtraCharges" | "UnitRentalCharges")[] = [];
      controls.push("RentalStatus", "promotion");
      // let showGallery: boolean | null = null;
      if (status.contains("Scheduled") || status.contains("Rented") || status.contains("RentToOwn")) controls.push("StartDate");
      if (status.contains("Moving_Out") || status.contains("Completed")) controls.push("EndDate");
      // if (status.contains("Rented") || status.contains("Completed")) controls.push("ExtraCharges");\
      // if (status.contains("Rented") || status.contains("Completed")) showGallery = false;
      controls.push("UnitRentalCharges");
      // console.log(showGallery);
      const group = this.groupEditRental({
        controls,
        status,
      });

      if (status.contains("Rented") || status.contains("RentToOwn") || status.contains("Moving_Out") || status.contains("Completed"))
        group.addControl("note", new QuestionSimple("RawText", {
          calculate: () => "This will send an email to the customer notifying them of the change.",
        }))

      return group;
    };
    const dialog = this.createRentalDialog("UPDATE", row.id, group, async function (value) {
      await this.data.server.serverEditRental({ ...value as any, id: row.id });
      globalMessage.add({ severity: 'success', summary: "Status Updated", detail: `Rental Status updated to ${value.RentalStatus}` });
      this.onSaveSuccess.emit({}); // emits on the dialog
      this.subs.unsubscribe();
    });

    await dialog.pageSetup(false);
    dialog.group?.form.patchValue({ promotion: row.promotion, RentalStatus: status[0] } as any);
    dialog.onSaveSuccess.subscribe(() => { this.onSaveSuccess.emit({}); })
  }

  @ZoneGuard()
  async onClickExtraCharges(row: any) {

    console.log(row);
    if (!row.id) throw "row.id not found";

    const group = () => this.ui.schema.RentalCharge("UPDATE");
    const dialog = this.fq.createBasicGroupDialog("RentalCharge", "UPDATE", row.id, group, async function (value) {
      const ExtraCharges = [value];
      await this.data.server.serverEditRental({ ExtraCharges, id: row.id });
      globalMessage.add({ severity: 'success', summary: "Charges Added!", detail: `Charges added to customer` });
      this.onSaveSuccess.emit({});
      this.subs.unsubscribe();
    });

    await dialog.pageSetup(false);
    dialog.onSaveSuccess.subscribe(() => { this.onSaveSuccess.emit({}); })
    return dialog;
  }

  checkIsOwnUnit = (row: customerRentalViewResult[number]) => {
    const data = this.data;
    console.log(row);
    if (!row) return false;
    if (!row.unit.currentOwner) return false;
    const branch = row.unit.currentBranch.id;
    const owner = row.unit.currentOwner.id;
    return data.userRole === "web_admin"
      || data.status.Branches.some(({ branch: e }) => e.id === branch)
      && data.status.Owners.some(({ owner: e }) => e.id === owner);
  }
  async onClickEditRental(row: customerRentalViewResult[number]) {

    const status = this.cols.RentalStatus.get(row) as RentalStatus;
    const isOwnUnit = this.checkIsOwnUnit(row);
    const canChangeStatus = row.RentalStatus === "Reserved" || row.RentalStatus === "Scheduled";

    showPageEditModal({
      table: "Rental",
      id: row.id,
      group: () => {

        const controls = [
          "promotion" as const,
          ...(status === "Scheduled") ? ["StartDate"] as const : [],
          ...(status === "Moving_Out" || status === "Completed") ? ["EndDate"] as const : [],
          ...FEATURE_FLAG_RENT_TO_OWN ? ["IsRentToOwn", "RentToOwnTotal"] as const : [],
          ...FEATURE_FLAG_PRICE_OVERRIDE ? ["PriceOverride"] as const : [],
        ]


        const group = this.groupEditRental({
          controls,
          status: null,
        });



        const isOwnUnit = this.checkIsOwnUnit(row);

        const canChangeStatus = row.RentalStatus === "Reserved" || row.RentalStatus === "Scheduled";

        if (!isOwnUnit) {
          group.controls.PriceOverride.preventUpdate = true;
          group.controls.IsRentToOwn.preventUpdate = true;
          group.controls.RentToOwnTotal.preventUpdate = true;
        } else if (!canChangeStatus) {
          group.controls.IsRentToOwn.preventUpdate = true;
        }
        if (group.controls.IsRentToOwn && group.controls.RentToOwnTotal)
          group.subs.add(group.controls.IsRentToOwn.form.valueChanges.subscribe((value) => {
            group.controls.RentToOwnTotal.hidden = !value;
            group.controls.promotion.hidden = !!value;
          }));

        return group;
      },
      useTitle: (page) => {
        return "Edit Rental";
      },
      useWhenLoaded: (page, onClose) => {

        if (!isOwnUnit) {
          page.group.controls.PriceOverride.preventUpdate = true;
          page.group.controls.IsRentToOwn.preventUpdate = true;
          page.group.controls.RentToOwnTotal.preventUpdate = true;
        } else if (!canChangeStatus) {
          page.group.controls.IsRentToOwn.preventUpdate = true;
        }
        useObservable(page.group.form.valueChanges);
        return React.createElement(BlockStack, { gap: "400" }, renderGroupControls(page.group, "UPDATE"));
      },
      onSaveValue: async (page, value) => {
        const { EndDate, StartDate, promotion, PriceOverride, IsRentToOwn, RentToOwnTotal } = value;
        const isOwnUnit = this.checkIsOwnUnit(row);

        await this.data.server.serverEditRental({
          id: row.id, promotion, StartDate, EndDate,
          ...isOwnUnit ? { PriceOverride, IsRentToOwn, RentToOwnTotal } : {},
        });
        globalMessage.add({ severity: 'success', summary: "Rental Saved!", detail: `Rental updated!` });
        page.onSaveSuccess.emit({});
        page.pageSetup(true);
      }

    });
    //   const state: FileUploadState = { files: [], names: [], thumbs: [] };
    //   console.log(row);
    //   if (!row.id) throw "row.id not found";
    //   const status: RentalStatus = this.cols.RentalStatus.get(row);
    //   const group = () => {
    //     const controls: ("promotion" | "StartDate" | "EndDate" | "IsRentToOwn" | "PriceOverride" | "RentToOwnTotal")[] = [];
    //     controls.push("promotion");

    //     if (status === "Scheduled")
    //       controls.push("StartDate");

    //     if (status === "Moving_Out" || status === "Completed")
    //       controls.push("EndDate");

    //     if (FEATURE_FLAG_RENT_TO_OWN) {
    //       controls.push("IsRentToOwn", "RentToOwnTotal");
    //     }
    //     if (FEATURE_FLAG_PRICE_OVERRIDE) {
    //       controls.push("PriceOverride");
    //     }


    //     return this.groupEditRental({
    //       controls,
    //       status: null,
    //     });
    //   };
    // const dialog = this.createRentalDialog("UPDATE", row.id, group, async function (value) {
    //   const { EndDate, StartDate, promotion, PriceOverride } = value;
    //   await this.data.server.serverEditRental({ id: row.id, promotion, StartDate, EndDate, PriceOverride });
    //   globalMessage.add({ severity: 'success', summary: "Rental Saved!", detail: `Rental updated!` });
    //   this.onSaveSuccess.emit({});
    //   this.pageSetup(true);
    // });
    //   await dialog.pageSetup(false);
    //   dialog.group?.form.patchValue(row);
    //   dialog.onSaveSuccess.subscribe(() => { this.onSaveSuccess.emit({}); })

  }

}
