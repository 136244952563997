import { Card, Checkbox, Collapsible, Spinner, Text, TextField } from "@shopify/polaris";
import { ConfirmType, LOADING_FIELD_SCROLLER, MemberKeys } from "common";
import { DataService } from "data-service";
import { ControlType, QuestionBase, QuestionTinyMCE } from '../utils';
import React, { useCallback, useEffect, useId, useLayoutEffect, useMemo, useRef, useState, useTransition } from "react";
import { DatePickerComboBox } from "./DatePicker";
import { QuestionForm } from "./QuestionForm";
import { QuestionAutoComplete } from "./QuestionAutoComplete";
import { EMPTY } from "rxjs";
import { useAngular, useObservable } from "react-utils";
import { QuestionSelectComp } from "./QuestionSelect";
import { QuestionTableComp } from "./QuestionTableComp";
import { QuestionSelectLabel } from "./QuestionSelectLabel";
import { getAsYouType } from "awesome-phonenumber";
import { TextFieldReadonly } from "./TextFieldReadonly";
import { formatPhoneNumber, grabTextFieldInputRef } from "./TextFieldHelpers";
import { QuestionInputNumberComp } from "./QuestionInputNumber";
import { QuestionTinyMCEComp } from "./TinyMCE";
import { QuestionFileUploadComp } from "./QuestionFileUpload";

export const emptyArray: any[] = [];

export function QuestionItem<Q extends QuestionBase<any, ControlType>>({
  q, mode, _fieldClass, animateHidden = true
}: {
  q: Q;
  mode: MemberKeys<ConfirmType>;
  _fieldClass: string;
  animateHidden: boolean;

}) {

  const [hidden, setHidden] = useState(q.hidden);
  const [done, setDone] = useState(false);
  const [pending, startTransition] = useTransition();

  useEffect(() => { startTransition(() => { setDone(false); setHidden(q.hidden); }); }, [q.hidden, startTransition]);

  if (!animateHidden)
    return <>{hidden ? null : <QuestionItemBody q={q} mode={mode} _fieldClass={_fieldClass} />}</>;

  return <Collapsible
    id={q.key + q.controlType}
    key={q.key + q.controlType}
    open={!hidden}
    transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
    onAnimationEnd={() => { setDone(true); }}
    expandOnPrint
  >{hidden && done ? null : <QuestionItemBody q={q} mode={mode} _fieldClass={_fieldClass} />}</Collapsible>

}

function QuestionItemBody({ q, mode, _fieldClass }: { q: QuestionBase<any, any>; mode: MemberKeys<ConfirmType>; _fieldClass: string; }) {

  const { get } = useAngular();
  const data = get(DataService);

  const isReadonly = q.readonly || q.preventUpdate && mode === "UPDATE" || q.preventCreate && mode === "CREATE" || false;

  const value = useObservable(q.form.valueChanges, q.form.value);
  const onChange = useCallback((v: any) => {
    // autocomplete has a custom onChange that sets the value directly, so make sure to update that as well
    if (!isReadonly) { q.form.setValue(v); q.form.markAsDirty(); }
  }, [q.form, isReadonly]);

  const viewvalue = q.calculate ? q.calculate() : value;
  const qtags = q.is("TinyMCE") ? q.tags?.join(" ") : '';
  const fieldClass = `${_fieldClass} ${q.fieldClass} ${q.flexmain} type-${q.controlType}`;
  const hostClass = `${q.hostClass} ${isReadonly ? "pointer-events-none" : ""} ${q && !q.hidden && !q.is('Hidden') ? 'mb-3' : ''}`;
  const appendTo = 'body';
  const showcalendar = false;
  const fieldspace = "mb-2";
  const blankArray = [];
  const emptyArray = [];

  // console.log("QuestionItemBody", q, q.form.value);

  const label = (q.title || q.key) + " " + (q.required ? "*" : "");

  const commonProps = {
    id: q.key + useId(),
    label,
    required: q.required,
    disabled: q.disabled,
    readOnly: isReadonly,
    readonly: isReadonly,
    autoComplete: q.allowAutocomplete ? '' : 'off',
    helpText: q.helptext,
    error: q.errortext,
    labelHidden: !q.title,
  }

  if (q.is("Hidden"))
    return null;

  if (q.is("RawText"))
    return <Text as="p">{viewvalue}</Text>

  if (q.is("Render"))
    return q.render();


  if (q.is("InputLabel")) {
    return <TextFieldReadonly
      fieldMarkup={
        viewvalue === LOADING_FIELD_SCROLLER
          ? <Spinner size="small" />
          : <Text as="span">{viewvalue}</Text>
      }
      {...commonProps}
    />;
  }


  if (q.is("InputText")) {
    if (q.inputType === "tel") {
      const inputRef = useRef<HTMLInputElement | null>(null);
      const type = useMemo(() => getAsYouType("US"), []);

      const onChange2 = (newval: string) => {
        newval = formatPhoneNumber(value ?? "", newval, type, inputRef);
        console.log(value, newval);
        onChange(newval);
      }

      return <TextField
        type="tel"
        inputMode="tel"
        value={value}
        onChange={onChange2}
        renderInputField={grabTextFieldInputRef(inputRef)}
        {...commonProps}
      />;
    } else {
      return <TextField
        type={q.inputType || "text"}
        value={value}
        onChange={onChange}
        {...commonProps}

      />;
    }
  }

  if (q.is("InputNumber")) {
    const setErrors = useCallback((errors: any, opts?: any) => { q.form.setErrors(errors, opts); }, [q.form]);
    return <QuestionInputNumberComp
      inputMode={q.inputMode}
      prefix={q.prefix}
      suffix={q.suffix}
      mode={mode}
      value={value}
      onChange={onChange}
      setErrors={setErrors}
      {...commonProps}
    />;
  }

  if (q.is("InputMask"))
    return <TextField
      type="text"
      value={value}
      pattern={q.mask.replace(/9/g, "\\d").replace(/A/g, "[A-Za-z]")}
      onChange={onChange}
      {...commonProps}
    />

  if (q.is("Textarea"))
    return <TextField
      type="text"
      multiline
      value={value}
      onChange={onChange}
      {...commonProps}
    />;
  if (q.is("CheckBox")) {
    // const onChange2 = useCallback((newChecked: boolean) => {
    //   onChange(newChecked);
    // }, [onChange]);
    return <Checkbox
      checked={value}
      onChange={onChange}
      {...commonProps}
    />
  }

  if (q.is("SubGroup"))
    return (
      <Card>
        <QuestionForm
          title={q.title}
          group={q.group}
          mode={mode}
        />
      </Card>
    );

  if (q.is("Table"))
    return <QuestionTableComp q={q} mode={mode} />;


  if (q.is("AutoComplete"))
    return <QuestionAutoComplete

      onChange={useCallback((v: any) => {
        if (!isReadonly) { q.form.setValue(q.mapOut(v)); q.form.markAsDirty(); }
      }, [q, isReadonly])}

      onSearch={useCallback(async (query: string) => {
        return await q.search(query) || [];
      }, [q])}

      value={useMemo(() => {
        return q.mapIn(value);
      }, [value, q])}
      searchOnFocus
      optionLabel={q.optionLabel}
      optionValue={q.optionValue}
      minLength={3}
      delay={250}
      {...commonProps}
    />;


  if (q.is("Calendar"))
    return <DatePickerComboBox
      pattern={q.format}
      valueType="string"
      value={value}
      onChange={onChange}
      {...commonProps}
    />;

  if (q.is("Select")) {
    useObservable(q.options);
    return <QuestionSelectComp
      value={value}
      onChange={onChange}
      {...commonProps}
      {...{
        options: q.optionsInner,
        optionValue: q.onRelation ? undefined : q.optionValue,
        optionDisabled: q.optionDisabled,
        optionLabels: q.optionLabels,
        placeholder: q.placeholder,
        onFocus: () => q.updateList(),
        loading: q.options === EMPTY,
        allowClear: !q.required && !q.preventDelete,
        display: q.display,
      }}
    />;

  }


  if (q.is("SelectLabel")) {
    // console.log(q, commonProps, value);
    return <QuestionSelectLabel
      value={q.value ?? q.form.parent?.value}
      optionLabels={q.optionLabels}
      optionIcon={q.optionIcon}
      placeholder={q.placeholder}
      onClick={q.click}
      {...commonProps}
    />
  }

  if (q.is("TinyMCE")) {
    return <QuestionTinyMCEComp q={q} {...commonProps} />;
  }

  if (q.is("FileUpload")) {
    return <QuestionFileUploadComp q={q} />;
  }

  console.log("Unhandled question - ", q.key, q.controlType, q);

  return data.status.isProd ? null : <div>Unhandled question - {q.key} - {q.controlType}</div>;
}

