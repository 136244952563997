import { Prisma, PrismaClient } from "prisma-client";
import { okNull } from "./utils";
import { findValueInObject } from "./datamath";

export class ProxyPromise {
  static getErrorStack(t: ProxyPromise) { return t.#error.stack; }
  action: keyof Prisma.TypeMap["model"]["Customer"]["operations"]; // PrismaReq["action"]
  table: Prisma.ModelName; // PrismaReq["table"];
  arg: any;
  #error = new Error("An error occured for this request");
  constructor({ action, table, arg }: Omit<ProxyPromise, "#error">) {
    okNull(this.action = action);
    okNull(this.table = table);
    okNull(this.arg = arg);
    Object.defineProperty(this, "toJSON", {
      configurable: true,
      enumerable: true,
      value: () => ({
        action: this.action,
        table: this.table,
        arg: this.arg,
        nulls: this.arg.data && findValueInObject(this.arg.data, e => e === Prisma.DbNull)
      })
    })
  }
}


// type Uncap<T extends string> = Uncapitalize<T>;
// export type PrismaReq = {
//   [K in Prisma.ModelName]: {
//     [A in keyof PrismaClient[Uncap<K>]]:
//     PrismaClient[Uncap<K>][A] extends (arg0: infer X0) => any ? { table: K, action: A, arg: X0 } : never;
//   }[keyof PrismaClient[Uncap<K>]]
// }[Prisma.ModelName];

function capitalize<T extends string>(table: T): Capitalize<T> {
  return table.slice(0, 1).toUpperCase() + table.slice(1) as any;
}

export const proxy: PrismaClient = (() => new Proxy<any>({}, {
  get(target: any, table: any, receiver) {
    return target[table] = target[table] || new Proxy<any>({}, {
      get(target: any, action: any, receiver) {
        return target[action] = target[action] || ((arg: any) => {
          return new ProxyPromise({ action, table: capitalize(table) as any, arg });
        })
      },
    })
  },
}))();