import { DependencyList } from 'react';
import { useEffect, useState, useMemo, useRef } from 'react';


export interface UseAsyncEffectOpts<T, RES, ERR> {
  /** The this value of the callback functions is computed right before calling mountCallback.  */
  thisFactory?: () => T;
  /** The normal effect callback which is called asyncly. If the component unmounts before this is called, nothing further happens. */
  mount: (this: T) => Promise<RES>;
  /** The unmount callback, which is called when the component unmounts. If mountCallback hasn't finished yet it, this will be called after it finishes. */
  unmount?: (this: T) => Promise<any>;
  /** A cancel callback that gets called if the component unmounts while the mountCallback is still running */
  cancel?: (this: T) => Promise<any>;
  /**
   * A catcher for all the callbacks. Whatever this returns will be set on the error property of `UseAsyncEffectResult`.
   */
  catcher?: (this: T | undefined, point: "factory" | "mount" | "unmount" | "cancel", error: unknown) => ERR;
  /** Normal dependancies array passed to useEffect. It is required to prevent the effect from remounting on every render. */
  deps: DependencyList;
}

export interface UseAsyncEffectClass<RES, ERR> {
  /** The normal effect callback which is called asyncly. If the component unmounts before this is called, nothing further happens. */
  mount: () => Promise<RES>;
  /** The unmount callback, which is called when the component unmounts. If mountCallback hasn't finished yet it, this will be called after it finishes. */
  unmount: () => Promise<void>;
  /** A cancel callback that gets called if the component unmounts while the mountCallback is still running */
  cancel: () => Promise<void>;
  // /**
  //  * A catcher for all the callbacks. Whatever this returns will be set on the error property of `UseAsyncEffectResult`.
  //  */
  // catcher?: (point: "factory" | "mount" | "unmount" | "cancel", error: unknown) => ERR,
}

export abstract class useAsyncEffectBase<RES, ERR> {
  loading: boolean;
  error: unknown | undefined;
  result: RES | undefined;
  abstract mount(): Promise<RES>;
  abstract unmount(): Promise<void>;
  abstract cancel(): Promise<void>;
  catcher(point: "mount" | "unmount" | "cancel", error: unknown): ERR {
    console.log(point, error);
    return error as ERR;
  }
  constructor(private deps: any[]) {
    const { error, result, loading } = useAsyncEffect(
      this.mount?.bind(this),
      this.unmount?.bind(this),
      this.cancel?.bind(this),
      this.deps
    );
    this.loading = loading;
    this.result = result;
    this.error = error;
  }
}

// interface OptsConstructor<T extends UseAsyncEffectClass<RES, ERR>, ARGS extends readonly any[], RES, ERR> 
export abstract class useAsyncEffectBase1<RES, ERR> {
  loading;
  error: ERR | undefined;
  result: RES | undefined;
  abstract mount(): Promise<RES>;
  abstract unmount(): Promise<void>;
  abstract cancel(): Promise<void>;
  catcher(point: "mount" | "unmount" | "cancel", error: unknown): ERR {
    console.log(point, error);
    return error as ERR;
  }
  constructor(deps: any[]) {
    // type T = InstanceType<typeof OptsClass>;
    const isMounted = useRef(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<ERR>();
    const [result, setResult] = useState<RES>();
    // const selfref = useRef(this);
    this.loading = loading;
    if (error) this.error = error;
    if (result) this.result = result;

    useEffect(() => {
      isMounted.current = true;
      return () => {
        isMounted.current = false;
      };
    }, []);

    useEffect(() => {

      const catcher = (point: "mount" | "unmount" | "cancel", error: unknown) =>
        setError(this.catcher ? this.catcher(point, error) : error as ERR);

      let ignore = false;
      let mountStarted = false;
      let mountSucceeded = false;

      (async () => {
        await Promise.resolve(); // wait for the initial cleanup in Strict mode - avoids double mutation

        if (!isMounted.current || ignore) {
          return;
        }

        setLoading(true);

        try {
          mountStarted = true;
          const result = await this.mount();
          mountSucceeded = true;
          if (isMounted.current && !ignore) {
            setError(undefined);
            setResult(result);
            setLoading(false);
          } else {
            // Component was unmounted before the mount callback returned, cancel it
            this.unmount()
          }
        } catch (error: unknown) {
          if (!isMounted.current) return;
          catcher("mount", error);
          setLoading(false);
        }
      })();

      return () => {
        ignore = true;
        if (mountSucceeded) {
          this.unmount()
            .then(() => {
              if (!isMounted.current) return;
              setResult(undefined);
            })
            .catch((error: unknown) => {
              if (!isMounted.current) return;
              catcher("unmount", error);
            });
        } else if (mountStarted) {
          this.cancel()
            .catch((error: unknown) => {
              if (!isMounted.current) return;
              catcher("cancel", error);
            });
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    // return useMemo(() => this, [result, error, loading]);
  }
}

const catcher: (point: "factory" | "mount" | "unmount" | "cancel", error: unknown) => unknown = (point, error) => { console.log(point, error); };

/**
 * Hook to run an async effect on mount and another on unmount. 
 * 
 * Note that if the calling render throws, the mount will not run. 
 * 
 * Loading initializes true.
 * 
 * From https://marmelab.com/blog/2023/01/11/use-async-effect-react.html
 */
export function useAsyncEffect<RES, ARGS extends readonly any[]>(
  this: void,
  /** The normal effect callback which is called asyncly. If the component unmounts before this is called, nothing further happens. */
  mount: () => Promise<RES>,
  /** The unmount callback is called immediately after mount if the component unmounts during mount, but only if mount succeeded. */
  unmount: () => Promise<void> = async () => { },
  /** The cancel callback is called if the component unmounts while mount is still running. */
  cancel: () => Promise<void> = async () => { },
  /** 
   * The dependancies array passed to useEffect which triggers mount and unmount. 
   * The default is an empty array 
   */
  deps: ARGS = [] as any as ARGS,
): UseAsyncEffectResult<RES, unknown> {

  // const stacker = new Error("useAsyncEffect render stack");
  // track whether the using component instance is still active. 
  const componentActive = useRef(false);
  const [loading, setLoading] = useState(true);
  const [promise, setPromise] = useState(new PromiseSubject<RES>());
  const [error, setError] = useState<unknown>();
  const [result, setResult] = useState<RES>();

  useEffect(() => {
    // this keeps track of whether the component using this effect is still mounted. 
    // the useRef gets discarded when the component gets reset, same as an empty deps array in useEffect
    componentActive.current = true;
    return () => { componentActive.current = false; };
  }, []);

  useEffect(() => {
    // console.log(activeState.mount === mount);
    let depsChanged = false;
    let mountStarted = false;
    let mountFinished = false;
    let mountSucceeded = false;
    let mountFailed = false;

    (async () => {
      await Promise.resolve(); // wait for the initial cleanup in Strict mode - avoids double mutation
      // console.log("useAsyncEffect", componentActive.current, depsChanged, deps)
      if (!componentActive.current || depsChanged) { return; }

      setLoading(true);

      try {
        mountStarted = true;
        const result = await mount();
        promise.resolve(result);
        mountSucceeded = true;
        if (componentActive.current && !depsChanged) {
          setError(undefined);
          setResult(result);
          setLoading(false);
        } else {
          // Component was unmounted before the mount callback finished, cancel it
          unmount()
        }
      } catch (error: unknown) {
        if (!componentActive.current) return;
        mountFailed = true;
        catcher("mount", error);
        setLoading(false);
        setError(error);
        promise.reject(error);
      } finally {
        mountFinished = true;
      }
    })();

    return () => {
      depsChanged = true;
      (async () => {
        if (mountSucceeded) {
          try {
            await unmount();
            if (!componentActive.current) return;
            setResult(undefined);
          } catch (error) {
            if (!componentActive.current) return;
            catcher("unmount", error);
          }
        } else if (mountStarted && !mountFailed) {
          try {
            await cancel();
          } catch (error) {
            if (!componentActive.current) return;
            catcher("cancel", error);
          }
        }
      })();
      setPromise(new PromiseSubject<RES>());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  // return useMemo(() => ({ result, error, loading }), [result, error, loading,]);
  return { error, loading, result, promise }
};

interface UseAsyncEffectResult<R, E> {
  /** If the effect fails to run, this will not be changed */
  result: R | undefined;
  /** If the effect runs successfully, this will be set to null. */
  error: E | undefined;

  loading: boolean;

  promise: PromiseSubject<R>;
}


class PromiseSubject<T> extends Promise<T> {
  #resolve: (value: T | PromiseLike<T>) => void;
  #reject: (reason?: any) => void;
  #done: boolean = false;
  public get done(): boolean { return this.#done; }
  constructor(executor?: PromiseSubject<T>);
  constructor(executor?: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void);
  constructor(executor?: ((resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void) | PromiseSubject<T>) {
    let _resolve: any, _reject: any;
    super((resolve, reject) => {
      _resolve = resolve;
      _reject = reject;
      if (typeof executor === "function") executor(resolve, reject);
    });
    this.resolve = _resolve;
    this.reject = _reject;
    if (executor instanceof Promise) executor.then(e => this.resolve(e));
  }
  resolve(a: T) {
    if (this.#done) return;
    this.#done = true;
    this.#resolve(a);
  }
  reject(a: any) {
    if (this.#done) return;
    this.#done = true;
    this.#reject(a);
  }
}

