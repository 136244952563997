import { EventEmitter } from '@angular/core';
import {
  DropZone,
  Text,
  BlockStack,
  ResourceList,
  ResourceItem,
  InlineStack,
  Box, Button,
  ButtonGroup, ModalProps
} from '@shopify/polaris';
import { ArrowLeftIcon, DeleteIcon } from '@shopify/polaris-icons';
import { formatFileSize } from 'common';
import React, { useState, useCallback, useLayoutEffect, useMemo, Dispatch, PropsWithChildren } from 'react';
import { ButtonAwait } from 'react-utils';

import { ModalWrapper } from 'react-utils';
import { FileUploadState } from '../utils/question-base';
import { counter } from '../frame/modal';

export type UploadFilesAction = Parameters<typeof UploadFilesRedux>[1];
export const UploadFilesRedux = (
  state: FileUploadState,
  action: { type: 'add', files: File[] }
    | { type: 'remove', file: File }
    | { type: "edit", file: File, value: string }
) => {

  // const { files, names, thumbs } = state;
  switch (action.type) {
    case 'add': {

      const files = [...state.files, ...action.files];
      const names = [...state.names, ...action.files.map(() => "")];
      const thumbs = [...state.thumbs, ...action.files.map((file) => window.URL.createObjectURL(file))];
      return { files, names, thumbs };
    }
    case 'remove': {
      const index = state.files.indexOf(action.file);

      const files = state.files.filter((f, j) => index !== j);
      const names = state.names.filter((f, j) => index !== j);
      const thumbs = state.thumbs.filter((f, j) => index !== j);

      return { files, names, thumbs };
    }
    case 'edit': {
      const index = state.files.indexOf(action.file);
      const names = state.names.map((f, j) => { return index === j ? action.value : f; });
      const { files, thumbs } = state;
      return { files, names, thumbs };
    }
    default: {
      //@ts-expect-error since either action or action.type should be never
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
export interface FileData { key: string, name: string, size: number, type: string, thumb?: string; onRemove?: () => Promise<void> }
export function FileUpload({
  state, setState, customValidator, fileOpenChange
}: {
  state: FileUploadState
  setState: Dispatch<UploadFilesAction>
  customValidator?(this: never, file: File): boolean;
  fileOpenChange?: EventEmitter<FileData | null>;
}) {
  // #FileUploadPrimeNG
  // (onSelect)="eventHandler('onSelect', $event, FileUploadPrimeNG)"
  // (onClear)="eventHandler('onClear', $event, FileUploadPrimeNG)"
  // (onRemove)="eventHandler('onRemove', $event, FileUploadPrimeNG)"
  // (uploadHandler)="uploadHandler($event, FileUploadPrimeNG)"
  // [multiple]="multiple"
  // [customUpload]="true"
  // [accept]="accept"
  // [disabled]="uploading"
  // cancelLabel="Clear"


  const handleDrop = useCallback((_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    // console.log(dropFiles, _acceptedFiles, _rejectedFiles);
    if (acceptedFiles.length) setState({ type: "add", files: acceptedFiles });
  }, [setState]);

  // we have to settle for generating these whenever files are added, which is ok
  // const removeCB = useMemo(() => state.files.map((file) => (event?: SyntheticEvent) => {
  //   event?.preventDefault();
  //   event?.stopPropagation();
  //   setState({ type: "remove", file: file });
  // }), [state.files, setState]);
  // const editCB = useMemo(() => state.files.map((file) => (value: string) => {
  //   setState({ type: "edit", file, value });
  // }), [state.files]);


  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  const newFiles = useMemo(() => state.files.map((e, i) => ({
    key: "" + i,
    name: e.name,
    size: e.size,
    type: e.type,
    thumb: state.thumbs[i],
    onRemove: async () => { setState({ type: "remove", file: e }); }
  })), [state, setState]);

  return (<>
    <div style={{ margin: "1px" }}>
      <BlockStack>
        <FileViewerComp fileOpenChange={fileOpenChange} id="new" files={newFiles} />
        <DropZone
          onDrop={handleDrop}
          dropOnPage
          customValidator={customValidator}
        >
          <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />
        </DropZone>
      </BlockStack>
    </div>

  </>)
}

export function FileViewerComp({ id, files, fileOpenChange }: {
  id: string;
  files: FileData[];
  fileOpenChange?: EventEmitter<FileData | null>;
}) {

  const [viewFile, setViewFile] = useState<FileData | null>(null);
  const viewCB = useMemo(() => files.map((file) => () => {
    setViewFile(file);
  }), [files]);

  useLayoutEffect(() => { fileOpenChange?.emit(viewFile); }, [viewFile, fileOpenChange]);

  const renderItem = useCallback((file: FileData, id: string, i: number) => (
    <ResourceItem
      id={`file-${id}`}
      media={file.thumb ? <img
        src={file.thumb}
        alt={file.name}
        style={{ objectFit: 'contain', height: '5rem', width: '5rem' }}
      /> : undefined}
      name={file.name}
      onClick={viewCB[i]}
      accessibilityLabel={`Select to view larger image of ${file.name}`}
    >
      <div style={{ flex: "auto" }}>
        <InlineStack align="space-between">
          <BlockStack>
            <Text variant="headingMd" as="span">{file.name}</Text>
            <Text variant="bodyMd" as="span">{formatFileSize(file.size)}</Text>
          </BlockStack>
          <BlockStack>
            {/* <Button icon={XIcon} onClick={removeCB[i]}></Button> */}
          </BlockStack>
        </InlineStack>
      </div>
    </ResourceItem>
  ), [viewCB]);

  return (<>
    <ResourceList
      items={files}
      idForItem={(file, i) => id + "-" + file.key}
      renderItem={renderItem}
    />
    <FileViewer
      open={!!viewFile}
      onClose={() => { setViewFile(null); }}
      onClickDelete={async () => { await viewFile?.onRemove?.(); setViewFile(null); }}
      onClickClose={() => { setViewFile(null); }}
      title={viewFile ? (
        <InlineStack gap="300">
          <Button icon={ArrowLeftIcon} onClick={() => { setViewFile(null) }} />
          <Text as="h2" variant='headingMd'>{viewFile.name}</Text>
        </InlineStack>
      ) : null}
      modalSize="large"
    >
      {viewFile && <div style={{ margin: "1px", padding: "var(--p-space-300)" }}>
        <img
          style={{ objectFit: 'contain', height: '100%', width: '100%', }}
          src={viewFile.thumb}
          alt={viewFile.name} />
      </div>}
    </FileViewer>
  </>
  )
}

function FileViewer({
  open,
  onClose,
  onClickDelete,
  onClickClose,
  title,
  modalSize,
  children
}: PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  onClickDelete?: () => Promise<void>;
  onClickClose: () => void;
  title: ModalProps['title'];
  modalSize: ModalProps['size'];
}>) {
  // const closed = useSubscriptionClosed(dialog.subs);
  // const loading = useObservable(dialog.loadingChange, dialog.loading);
  // const status = useObservable(dialog.group?.form.statusChanges || NEVER, dialog.group?.form.status);
  const [okLoading, setOkLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [index, setIndex] = useState(0);

  useLayoutEffect(() => { if (open) setIndex(++counter.current); }, [open]);

  const closeAction = {
    content: "Close",
    onAction: async () => {
      onClickClose();
    }
  };

  return (
    <ModalWrapper
      modalindex={index}
      open={open}
      onClose={onClose}
      title={title}
      limitHeight
      size={modalSize}
      footer={
        <ButtonGroup>
          <ButtonAwait icon={DeleteIcon} variant="secondary" tone="critical" onClick={onClickDelete}>Delete</ButtonAwait>
        </ButtonGroup>
      }
      primaryAction={closeAction}
    >
      <Box padding="300">
        {children}
      </Box>
    </ModalWrapper >
  );
}