//https://transform.tools/graphql-to-typescript
import "./globals";
import pkg from 'pluralize';
const { plural } = pkg;
import * as ops from "./graphql-operations";
import { DirectiveSimple, MemberKeys, Attributes, DirectiveInput } from "./cubes-attributes";
export type { MemberKeys }

export function toKeysTyped<T extends Record<string, any>>(
  obj: T,
  vals: true
): T[keyof T][];
export function toKeysTyped<T extends Record<string, any>>(
  obj: T,
  vals: false
): [keyof T, T[keyof T]][];
export function toKeysTyped<T extends Record<string, any>>(
  obj: T,
  vals: boolean
): any[] {
  return Object.keys(obj).map(!vals ? (e) => [e, obj[e]] : (e) => obj[e]);
}
export interface AmazonDefined {
  __builtin: boolean;
}
function ok(a: any, message?: string): asserts a { if ((a ?? null) === null) throw new Error(message || "Assertion Failed"); }

export function truthy<T>(
  obj: T
): obj is Exclude<T, false | null | undefined | 0 | '' | void> {
  return !!obj;
}


export type OmitOnly<T, K extends keyof T> = { [P in Exclude<keyof T, K>]: T[P]; }

export class SyncCheck {
  #done: boolean = false;
  #error = new Error("Sync check failed");
  constructor() {
    setTimeout(() => { if (this.#done === false) throw this.#error; }, 0);
  }
  done() {
    this.#done = true;
  }
}

export interface HasFieldLine {
  // __printFieldLine(key: string): string;
}
export interface RegisterState {
  /** 
   * The member calling register. Always the direct parent calling __register, 
   * except for types themselves.
   * - For directives: The type or field declared on.
   * - For members and directive children: The direct parent member or directive.
   * */

  caller: AnyMember<any, any> | Directive<any> | null;
  /** 
   * All directives pass their host through. 
   * Type members pass the host through to all child members.
   * All members set their caller as the host of directives. 
   * - For directives, this is the Host type (H type param)
   */

  host: AnyMember<any, any> | Directive<any> | null;
  role: "typeDir" | "fieldDir" | "member" | "root";
  registry?: Registry;
  level: number;
  key: string;
  lates: ((() => boolean) | undefined)[];
  lists: GQLListDef[];
}
export interface Register {
  // __validate: () => void;
  __hookRegister: (state: RegisterState) => void;
  __register(state: RegisterState): void;
}
export type DeclarationTypes = "" | "table" | "record" | "enum" | "directive" | "scalar";

export const TypeDirs = (dirs: Directive<"OBJECT">[]): ClassDecorator => {
  return (target) => {
    if (!target.prototype[AnyMemberSymbol])
      throw new Error("TypeDirs added to a type that does not inherit AnyMember");
  }
}

export type PathString<F2> = MemberKeys<F2> | `../${string}` | `${MemberKeys<F2>}/${string}`;

type MemberType<T> =
  T extends EnumType ? [MemberKeys<T>, EnumMember] :
  T extends RecordType ? [MemberKeys<T>, RecordMember<any>] :
  [MemberKeys<T>, T];


type DirectiveFilter<T extends DirectiveOptions> = T extends any ? Directive<T> : never;
export const AnyMemberSymbol = Symbol("AnyMember")
export abstract class Anything {
  __is(type: "enum"): this is EnumType;
  /** this will still be a RecordType, but the value it checks against does not inherit from "record" */
  __is(type: "table"): this is RecordType;
  __is(type: "record"): this is RecordType;
  __is(type: "scalar"): this is ScalarType<any>;
  __is(type: "directive"): this is Directive<any>;
  __is(type: "AnyMember"): this is AnyMember<any, any>;
  __is(type: "RecordMember"): this is RecordMember<any>;
  __is(type: DeclarationTypes | "AnyMember" | "RecordMember") {
    if (type === "AnyMember") return this instanceof AnyMember;
    if (type === "RecordMember") return this instanceof RecordMember;
    return this.__type === type;
  }
  abstract __type: DeclarationTypes;
  get __name() { return this.constructor.name; }
  get __plural() { return plural(this.constructor.name); }
  // $assign<T extends this>(obj: Partial<T>) {
  //   Object.assign(this, obj);
  //   return this;
  // }
  // default requires a lot more thought
  // abstract __default?: D;
  abstract __value?: unknown;

  constructor() { }
  #err = new Error();
  get __stack() { return this.#err.stack?.split("\n").slice(1).join("\n"); }

}

export abstract class AnyMember<DT extends DirectiveOptions, DF extends DirectiveOptions>
  extends Anything implements HasFieldLine {

  static instanceIndex = 1;
  __index = AnyMember.instanceIndex++;

  __skipSchema: boolean = false;

  /** @deprecated - was marked private */
  __typepath(e: string): AnyMember<any, any>[] {
    return e.split("/").reduce((n, f, j, p) => {
      let last: any = n.last();
      if (!last)
        throw new Error("last not found");
      if (last instanceof Wrapping)
        last = last.__wrap;
      let field: AnyMember<any, any> = last[f];

      if (!(field instanceof AnyMember))
        throw new Error(`List item ${e} is not AnyMember at index ${j}\n${this.__stack}\n`);
      else if (field.__is("scalar")) {
        if (j < p.length - 1)
          throw (`List item ${e} tried to descend into Scalar type ${field.__name} after index ${j}\n${this.__stack}\n`);
      } else if (field.__is("record") || field.__is("table")) {
        n.push(field);
      } else if (field.__is("enum")) {
        if (j < p.length - 1)
          throw (`List item ${e} tried to descend into Enum type ${field.__name} after index ${j}\n${this.__stack}\n`);
      } else if (field.__is("directive")) {
        throw new Error("A directive is not a valid field. Should not happen." + `\n${this.__stack}`);
      }
      return n;
    }, [this] as AnyMember<any, any>[]);
  }
  __typepaths(list: string[]): AnyMember<any, any>[][] {
    return list.map(e => this.__typepath(e));
  }

  __valueSet = false;
  __value?: any;

  static fieldLengths: any = {};
  static typeLengths: any = {};

  readonly __fieldAttrs: any = {};
  __fieldAttributes(): Attributes<DF> {
    return this.__fieldAttrs;
  }
  __fieldDirectivesAdd<T extends (DirectiveInput<DF> | undefined)[]>(...fielddirs: T) {

    fielddirs.forEach((e: any) => {
      if (!e) return;
      const k = e.constructor.name;
      (this.__fieldAttrs[k] = this.__fieldAttrs[k] || []).push(e.attr);
    });

    if (this.__fieldAttrs.attributes) extractAttributes(this.__fieldAttrs);

    Object.entries(this.__fieldAttrs).forEach(([k, v]) => {
      AnyMember.fieldLengths[k] = Math.max(AnyMember.fieldLengths[k] || 0, v.length);
    });

  }


  readonly __typeAttrs: any = {};
  __typeAttributes(): Attributes<DT> {
    return this.__typeAttrs;
  }
  __typeDirectivesAdd<T extends DirectiveInput<DT>[]>(...typedirs: T): false {

    typedirs.forEach((e: any) => {
      const k = e.constructor.name;
      if (k === "Object") throw new Error("k === Object");

      (this.__typeAttrs[k] = this.__typeAttrs[k] || []).push(e.attr);
    });

    if (this.__typeAttrs.attributes) extractAttributes(this.__typeAttrs);

    Object.entries(this.__typeAttrs).forEach(([k, v]) => {
      AnyMember.typeLengths[k] = Math.max(AnyMember.typeLengths[k] || 0, v.length);
    });

    return false;

  }


  abstract __required: boolean;

  __hookRegister: Register["__hookRegister"][] = [];
  __register: Register["__register"] = function (this: AnyMember<any, any>, { registry, key, caller, host, role, level, lists, lates }) {
    if (this.__name === "Lazy") debugger;

    this.__hookRegister.forEach(e => { e({ registry, key, caller, host, role, level, lists, lates }); })
    if (this.__type && registry) registry[this.__type].push(this as any);
    if (role === "root") {
      this.__listMembers().forEach(e => {
        if (e[1] instanceof RecordType && e[1].__proxy === false)
          throw new Error(`RecordType ${e[0]} on ${this.__name} must be defined using Member\n${this.__stack}\n`);
        return e[1].__register({ registry, key: e[0], role: "member", caller: this, host, level: level + 1, lists, lates })
      });
      // this.__typeDirectivesGet().forEach(e => e.__register({ registry, key, role: "typeDir", caller: this, host: caller, level: level + 1, lists, lates }));
    }
    if (role === "member") {
      // this.__fieldDirectivesGet().forEach(e => e.__register({ registry, key, role: "fieldDir", caller: this, host: caller, level: level + 1, lists, lates }));
    }
  }

  __member(k: string): RecordMember<"FIELD_DEFINITION"> {
    const res = (this as any)[k];
    if (res instanceof RecordMember) return res;
    throw new Error("field not found");
  }

  __wrapped(): this is Wrapping<RecordMember<any>> {
    return this instanceof Wrapping;
  }

  __listMembers = <T extends this = this & Record<string, any>>(): MemberType<T>[] => toKeysTyped(this as any, false)
    .filter((e, i, a): e is MemberType<T> => e[1] instanceof AnyMember)
    .sort((a, b) => a[1].__index - b[1].__index)

  __listOptions = <T extends this = this & Record<string, any>>() => toKeysTyped(this as any, false)
    .filter((e): e is [string, any] => !(e[1] instanceof AnyMember) && typeof e[0] === "string" && !e[0].startsWith("__"))
    .sort((a, b) => a[1].__index - b[1].__index)
}




export abstract class RecordMember<DT extends DirectiveOptions> extends AnyMember<DT, "FIELD_DEFINITION"> implements HasFieldLine {

  /** used by StringPathProxy */
  declare __: string;

  __init__?: void;

  constructor(public __required: boolean = false, ...__fieldDirectives: (DirectiveInput<"FIELD_DEFINITION"> | undefined)[]) {
    super()

    this.__fieldDirectivesAdd(...__fieldDirectives);
  }

}

export abstract class Wrapping<T extends RecordMember<any>> extends RecordMember<"FIELD_DEFINITION"> {
  __wrap!: T;

  __printType() { return `` }

}
export class IsArray<T extends RecordMember<any>> extends Wrapping<T> {
  static register = true;
  override get __name(): T["__name"] {
    return this.__wrap.__name;
  }
  override get __plural(): T["__plural"] {
    return this.__wrap.__plural;
  }
  override get __type(): T["__type"] {
    return this.__wrap.__type;
  };


  constructor(__wrap: T) {
    super();
    this.__required = false;
    this.__wrap = __wrap;
    this.__hookRegister.push(state => this.__wrap.__hookRegister.forEach(e => e(state)));
    this.__register = (state) => this.__wrap.__register(state);
    // Object.defineProperty(this, "__wrap", {
    //     configurable: true,
    //     enumerable: true,
    //     get: () => this.__lazywrap(),
    //     set: () => { }
    // })
    if (this.__wrap instanceof EnumMember) throw new Error("Enum Values do not use IsArray");
  }

  override __fieldAttributes: T["__fieldAttributes"] = () => {
    return this.__wrap.__fieldAttributes();
  }

}


export abstract class RecordType extends RecordMember<"OBJECT"> {
  __field(k: string) {
    const res = (this as any)[k];
    if (res instanceof RecordMember) return res;
    throw new Error("field not found");
  }
  // override readonly __default = __valueFactory(this, "__default");
  // override readonly __value = __valueFactory(this, "__value");
  __proxy: boolean = false;
  __type: Extract<DeclarationTypes, "record" | "table"> = "record";

}


const __valueFactory = (self: any, key: "__value"): any => {

  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Reflect/Comparing_Reflect_and_Object_methods
  //https://stackoverflow.com/questions/40352613/why-does-object-keys-and-object-getownpropertynames-produce-different-output
  return new Proxy<any>({}, {
    get(target, p) {
      let t = self[p];
      if (t instanceof RecordMember)
        t[`${key}Set`] ? t[key] : undefined;
    },
    set(target, p, val, receiver) {
      let t = self[p];
      ops.checkValue(t, val, "<root>");
      if (t instanceof RecordType)
        Object.assign(t.__value, val);
      else
        t.__value = val;
      return true;
    },
    getOwnPropertyDescriptor(target, p) {
      let t = self[p];
      return {
        enumerable: t instanceof RecordMember && t[`${key}Set`],
        configurable: true,
      };
    },
    getPrototypeOf() {
      return null;
    },
    ownKeys(target) {
      if (self instanceof AnyMember || self instanceof Directive)
        return self.__listMembers().map(e => e[0]);
      else
        return Object.keys(self);
    },

  });
}

export class GQLMember<T extends RecordType> extends RecordType {
  static register = true;
  __builtin: boolean = true;

  #name;

  override get __name(): T["__name"] {
    return this.#name;
  }

  override get __plural(): T["__plural"] {
    return plural(this.#name);
  }

  // override __value = __valueFactory(this, "__value");

  constructor(public __ctor: { new(): T }, required: boolean = false, ...__fieldDirectives: (DirectiveInput<"FIELD_DEFINITION"> | undefined)[]) {
    super(required, ...__fieldDirectives);
    this.#name = __ctor.name;
  }

}




export class Lazy<T extends RecordType> extends Wrapping<T> {
  static register = true;
  __type: DeclarationTypes = "record";

  override get __name() { return this.__base.name; }
  override get __plural() { return plural(this.__base.name); }

  constructor(public __base: { new(): T }, __required: boolean = false, ...__fieldDirectives: DirectiveInput<"FIELD_DEFINITION">[]) {
    super(__required, ...__fieldDirectives);
  }

}

export abstract class EnumType extends RecordMember<"ENUM"> {
  __type: Extract<DeclarationTypes, "enum"> = "enum";

}
type t = ClassDecorator
export class EnumMember extends AnyMember<never, "ENUM_VALUE"> implements HasFieldLine {
  static register = true;


  __type: Extract<DeclarationTypes, ""> = "";

  __required = false;
  constructor(...__field: DirectiveInput<"ENUM_VALUE">[]) {
    super()
    this.__fieldDirectivesAdd(...__field);
  }



}

export const __name_symbol: unique symbol = Symbol("ScalarType__name_symbol");
export abstract class ScalarType<T> extends RecordMember<any> {
  __type: Extract<DeclarationTypes, "scalar"> = "scalar";
  declare __value: T;


  __printType(): string { throw new Error("Method not implemented"); }

}



type R<T> = T[];
export class Registry {
  // parent: RecordMember;

  directive: R<Directive<any>> = [];
  scalar: R<ScalarType<any>> = [];
  record: R<RecordType> = [];
  table = this.record;
  enum: R<EnumType> = [];
  lists: GQLListDef[] = [];
  lates: ((() => boolean))[] = [];
  schema: Record<string, string> = {};

  constructor(public parent: RecordMember<any> | Directive<any>) {

  }

}
export interface GQLListDef<K extends string = string> {
  query: () => string;
  paths: string[];
  tableName: string;
  queryName: K;
  instance: Directive<any>;
}
type R2<T> = Record<string, T>;

export class GraphRoot<
  ST extends R2<ScalarType<any>> = R2<ScalarType<any>>,
  ET extends R2<EnumType> = R2<EnumType>,
  RT extends R2<RecordType> = R2<RecordType>,
  DT extends R2<Directive<any>> = R2<Directive<any>>,
> {

  public registry: Map<{ new(): RecordMember<any> | Directive<any> }, RecordMember<any> | Directive<any>> = new Map();

  lates: ((() => boolean) | undefined)[] = [];
  lists: GQLListDef[] = [];



  constructor(
    public scals: ST,
    public enums: ET,
    public types: RT,
    public directives: DT,
  ) {
    Object.values(this.scals).forEach((item: any) => this.registry.set(item.constructor, item));
    Object.values(this.enums).forEach((item: any) => this.registry.set(item.constructor, item));
    Object.values(this.types).forEach((item: any) => this.registry.set(item.constructor, item));
  }

  build() {

    const lazy: Lazy<any>[] = [];
    [...this.registry.values()].map(item => {
      item.__listMembers().forEach(e => { if (e[1] instanceof Lazy) { lazy.push(e[1]); console.log("lazy", item.__name, e[0]) } });
      item.__register({ caller: null, host: null, role: "root", level: 1, key: "", lists: this.lists, lates: this.lates });
    });

    lazy.forEach(e => { e.__wrap = this.scals[e.__name] || this.enums[e.__name] || this.types[e.__name]; });

    while (this.lates.length) {
      let somethinghappened = false;
      for (let i = 0; i < this.lates.length; i++) {
        let late = this.lates[i];
        if (late && late()) {
          this.lates[i] = undefined;
          somethinghappened = true;
        }
      }
      if (!somethinghappened) {
        console.log(this.lates);
        throw new Error("nothing happened");
      }
      this.lates = this.lates.filter(truthy);
    }

  }



  printSchema() {

    let res: Record<string, string> = {};
    // Object.values(this.enums).map(e => !res[e.__name] && (res[e.__name] = e.__printType()))
    // Object.values(this.types).map(e => !res[e.__name] && (res[e.__name] = e.__printType()))
    return Object.keys(res).map(e => res[e]).join('\n');
  }



  printDirectives() {
    // let types = this.getDeclarations();
    let types: Record<string, string> = {};
    // Object.values(this.enums).map(e => !types[e.__name] && (types[e.__name] = e.__printType()))
    // Object.values(this.types).map(e => !types[e.__name] && (types[e.__name] = e.__printType()))
    let used: any = {};
    let directives = (Object.values(this.directives).reduce((n, e) => {
      let res: any = {};
      if (res[e.__name]) return n;
      // (res[e.__name] = e.__printType());
      e.__used.forEach(f => used[f] = types[f]);
      n[e.__name] = Object.values(res).join('\n');
      // debugger;

      return n;
    }, {} as Record<string, string>));
    let first = Object.keys(directives)[0];
    directives[first] = [directives[first], ...Object.values(used)].join('\n');
    return directives;
  }
}






type DirectiveHost = { __host: { [P in DirectiveOptions]?: boolean } }
export interface FIELD_DEFINITION extends DirectiveHost { __host: { FIELD_DEFINITION: boolean } }
export interface ENUM_VALUE extends DirectiveHost { __host: { ENUM_VALUE: boolean } }
export interface ENUM extends DirectiveHost { __host: { ENUM: boolean } }
export interface OBJECT extends DirectiveHost { __host: { OBJECT: boolean } }

export type DirectiveOptions =
  | "SCHEMA"
  | "SCALAR"
  | "OBJECT"
  | "FIELD_DEFINITION"
  | "ARGUMENT_DEFINITION"
  | "INTERFACE"
  | "UNION"
  | "ENUM"
  | "ENUM_VALUE"
  | "INPUT_OBJECT"
  | "INPUT_FIELD_DEFINITION"
// type MyEnumType = {
//   [K in keyof typeof MyEnum]: number;
// };
// export type MemberKeys<T> =
//   // T extends GQLMember<infer X> ? MemberKeys<X> :
//   // T extends `${infer X}` ? X :
//   { [K in string & keyof T]: K extends `$${string}` ? never : K extends `__${string}` ? never : K; }[string & keyof T];

// export interface MemberKeysPathFunc<T> {
//   <K extends T extends GQLMember<infer X> ? MemberKeys<X> : MemberKeys<T> & keyof T>(k: K): MemberKeysPathFunc<T[K]>;
//   path: MemberKeysPath<T>;
// }
/** extracts extra attributes from the attributes directive and adds them to the Attributes parent object */
function extractAttributes(attrs: any) {
  // get the attributes directive entries
  (attrs.attributes as any[]).forEach(e => {
    if (!e.attrs) return;
    const attrs2: any = JSON.parse(e.attrs);
    Object.keys(attrs2).forEach((k) => {
      attrs[k] = attrs[k] || [];
      attrs[k].push(...attrs2[k]);
    });
  });
  delete attrs.attributes;
}

// export function MKP<T>(path: string[] = []): MemberKeysPathFunc<T> {
//   const iter: any = (k: MemberKeys<T>) => MKP([...path, k]);
//   iter.path = path.join("/");
//   return iter;
// }
// export type MemberKeysPath<T> = MemberKeys<T> | `../${string}` | `./${MemberKeys<T>}/${string}`;


export type ValueTreeDirective<T> =
  T extends Directive<any> ? T["__value"] :
  { [K in MemberKeys<T> & keyof T]?: ValueTreeInner<T[K]> & {} };

export type ValueTree<T> =
  T extends { attr: infer X, __host?: any } ? X :
  T extends DirectiveSimple ? T :
  T extends GQLMember<infer T> ? ValueTree<T> :
  { [K in MemberKeys<T> & keyof T]?: ValueTreeInner<T[K]> & {} };

export type ValueTreeInner<T> =
  T extends IsArray<infer X> ? Array<ValueTreeInner<X>> :
  T extends ScalarType<infer X> ? X :
  T extends EnumType ? MemberKeys<T> :
  T extends RecordType ? ValueTree<T> :
  never;

// export type QueryTree<T> = { [K in MemberKeys<T>]?: QueryTreeInner<T[K]> & {} | true }
export type QueryTree<T, R> =
  // T extends Lazy<infer X> ? QueryTree<X> :
  T extends IsArray<infer X> ? QueryTree<X, R> :
  T extends ScalarType<any> ? R :
  T extends EnumType ? R :
  T extends GQLMember<infer T> ? { [K in MemberKeys<T> & keyof T]?: QueryTree<T[K], R> & {} } :
  never;



export function toQueryTree<T extends RecordMember<any>, R>(obj: T, val: R): QueryTree<T, R> {
  if (obj instanceof IsArray) {
    return toQueryTree(obj.__wrap, val) as QueryTree<RecordMember<any>, R>;
  } else if (obj instanceof ScalarType) {
    return val as QueryTree<typeof obj, R>;
  } else if (obj instanceof EnumType) {
    return val as QueryTree<typeof obj, R>;
  } else if (obj instanceof RecordType) {
    return obj.__listMembers().reduce((n, e: any) => n[e] = toQueryTree(obj[e as keyof typeof obj], val), {} as any);
  } else {
    throw new Error("not handled");
  }
}

export abstract class Directive<T extends DirectiveOptions> extends Anything {

  __values!: never;
  __value: any = {};
  __listMembers = () => toKeysTyped(this as any, false).filter((e): e is [string, RecordMember<any>] => e[1] instanceof RecordMember)
  __root: Registry = new Registry(this);
  __used: string[] = [];
  __register({ caller, host, role, level, key, lists, lates, registry }: RegisterState) {

    if (this.__type && registry) registry[this.__type].push(this as any);

    this.__listMembers().forEach(e => e[1].__register({
      role: "member", caller: this, host, level: level + 1, key: e[0], lists, lates, registry: this.__root
    }));

    this.__used = [
      ...Object.keys(this.__root.record.reduce((n, e) => (n[e.__name] = true, n), {} as any)),
      ...Object.keys(this.__root.enum.reduce((n, e) => (n[e.__name] = true, n), {} as any)),
    ];

  }


  __type: Extract<DeclarationTypes, "directive"> = "directive";


  abstract __host: { [P in T]: true; } & { [P in Exclude<DirectiveOptions, T>]?: true }

  __(val: ValueTree<this>) {

    this.__value = val;

    return { [this.__name]: [this.__value] } as Partial<Record<string, [ValueTree<this>]>>
  }




}
