import { NavigateFunction, NavigateOptions, Path, To } from "react-router";
import { EventEmitter, useObservable } from "react-utils";

export function useNavigate(): NavigateFunction {
  return navigate;
}

export const locationChange = new EventEmitter<string>();

function navigate(to: To, options?: NavigateOptions): void;
function navigate(delta: number): void;
function navigate(to: any, options: any = {}) {
  const opts = options as NavigateOptions;
  opts.replace = opts.replace ?? false;

  console.log(to);
  if (typeof to === "number") {
    history.go(to);
    locationChange.emit(location.href);
    return;
  }
  const url = new URL(location.href);

  if (typeof to === "string") {
    if (to.startsWith("/")) to = to.slice(1);
    url.pathname = to;

  } else {
    const parts = (to as Path);
    url.pathname = parts.pathname;
    url.hash = parts.hash;
    url.search = parts.search;
  }
  if (opts.replace) {
    history.replaceState(null, "", url.href);
  } else {
    history.pushState(null, "", url.href);
  }
  locationChange.emit(location.href);
}
