import { IndexTable, IndexTableHeading, IndexTablePaginationProps, IndexTableSortDirection } from "@shopify/polaris/components/IndexTable";
import { Box, InlineStack, Link, Spinner } from "@shopify/polaris/index";
import { NonEmptyArray } from "@shopify/polaris/types";
import { Range, SelectionType } from "@shopify/polaris/utilities/index-provider";
import { ok, TABLE_NAMES } from "common";
import { useCallback, useLayoutEffect, useMemo, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-utils";
import { ColumnBase, DataListIdFieldColumn } from "../utils";
import { AppliedFilterProps } from "./useFilters";
import { valMarkup } from "./valMarkup";
import { makeTableRowRedux, TableRowAction } from "./TableRowRedux";
import { CustomColumnState } from "./CustomColumnState";
import { TableView, TableViewOptions } from "./table-views";


export interface TableProps {
  emptyState?: React.ReactNode;
  firstSort?: string;
  curAppliedFilters?: AppliedFilterProps[];
  loading?: boolean;
  cols: readonly ColumnBase[],
  rows: any[],
  idcol: ColumnBase,
  pagination?: IndexTablePaginationProps | true;
}


export interface SelectTableProps extends TableProps {
  onClickRow?: (row: string) => void;
  resourceName?: { singular: string, plural: string };
  query?: string;
  selectMultiple?: boolean;
  selectionChange?: (rows: Set<string>) => void;
  // useRowsMarkup?: (rows: any[], cols: readonly ColumnBase[], idcol: ColumnBase) => React.ReactNode;
  rowsMapper?: (rows: any[], cols: readonly ColumnBase[], idcol: ColumnBase) => any[];
}


function SetProxy<T>(value: Set<T>, refresh: (target: Set<T>) => void) {
  return new Proxy(value, {
    get(target, p: keyof Set<T>, receiver) {
      switch (p) {
        case "add":
        case "delete":
          return (arg: T) => {
            target[p](arg);
            refresh(target);
          }
        case "clear":
          return () => {
            target.clear();
            refresh(target);
          }
        case "entries":
        case "keys":
        case "values":
          return () => target[p]();
        case "forEach":
          return ((...args) => target[p](...args)) as typeof target.forEach;
        case "has":
          return ((...args) => target[p](...args)) as typeof target.has;
        case Symbol.iterator:
        case Symbol.toStringTag:
        case "size":
          return target[p];
        default: {
          const t: never = p;
        }
      }
    },
  });
}


function useProxy<T>(init: () => Set<T>) {
  const setter = useDispatch((target: Set<T>) => { setValue(target); });
  const reducer = useCallback((state: Set<T>, action: Set<T>) => SetProxy(action, setter), [setter]);
  const [value, setValue] = useReducer(reducer, true, () => SetProxy(init(), setter));
  // console.log("useProxy", { value });s
  return value;
}

/**
 * showRowCheckbox changes the hook count
 */
export function SelectTable({
  cols,
  rows,
  idcol,
  emptyState,
  firstSort,
  curAppliedFilters,
  resourceName,
  onClickRow: onClickRow,
  selectionChange,
  loading,
  query,
  pagination,
  selectMultiple,
  rowsMapper,
}: SelectTableProps): JSX.Element {
  // const hiddenIndex = JSON.stringify(rows.map(row => !matchAppliedFilters(row, curAppliedFilters)));
  // this can't be memoed because the array doesn't change, only the rows do
  const { sortedRows: notHiddenRows, ...sortOpts } = useSortOpts(rows, cols, curAppliedFilters, firstSort);
  // this could be memo-ed but the length of cols could change
  const notHiddenCols: ColumnBase[] = cols.filter(e => !e.hidden);



  const queryRows = new Set(notHiddenRows.filter(row => {
    if (!query) return true;
    if (!notHiddenCols.some(col => col.queryColumn)) return true;
    if (notHiddenCols.some(col => col.queryColumn && col.text(col.get(row))?.toString().toLowerCase().includes(query.toLowerCase()))) return true;
    return false;
  }).map(e => idcol.get(e)));

  // console.timeEnd("SelectTable sort and filter");

  const headings = notHiddenCols.map(e => ({
    title: e.title,
    alignment: "start",
    hidden: e.hidden,
    id: e.key,
  } satisfies IndexTableHeading)) as NonEmptyArray<IndexTableHeading>;

  ok(headings.length > 0, "headings.length is 0");

  const selection = useProxy(() => new Set<string>());

  const onSelectRowInner = useCallback((key: string, value: boolean | null) => {
    if (selectMultiple) {
      selection.has(key) ? (value !== true && selection.delete(key)) : (value !== false && selection.add(key));
    } else {
      selection.clear(); selection.add(key);
    }
    selectionChange?.(selection);
  }, [selectMultiple, selection, selectionChange]);

  const onSelectionChange = !selectMultiple ? undefined :
    useCallback((
      selectionType: SelectionType,
      toggleType: boolean,
      selection?: string | Range,
      position?: number,
    ) => {
      console.log("onSelectionChange", { selectionType, toggleType, selection, position });
      if (typeof selection === "string") onSelectRowInner(selection, toggleType);
    }, [onSelectRowInner]);

  const [offset, setOffset] = useState(0);

  const rows1 = (rowsMapper ? rowsMapper(notHiddenRows, notHiddenCols, idcol) : notHiddenRows);
  const rows2 = (query ? rows1.filter(row => queryRows.has(idcol.get(row))) : rows1);

  const page = pagination === true ? {
    hasNext: offset + 50 < rows2.length,
    hasPrevious: offset > 0,
    onNext: () => setOffset(offset => offset + 50),
    onPrevious: () => setOffset(offset => offset - 50),
    label: `${offset + 1}-${Math.min(offset + 50, rows2.length)} of ${rows2.length}`,
  } as IndexTablePaginationProps : pagination;

  const rows3 = pagination === true ? rows2.slice(offset, offset + 50) : rows2;

  useLayoutEffect(() => { setOffset(0); }, [query]);

  const rowsMarkup = rows3.map((row, index) => (
    query && !queryRows.has(idcol.get(row)) ? null :
      <IndexTable.Row
        id={idcol.get(row)}
        key={idcol.get(row)}
        selected={selectMultiple && selection.has(idcol.get(row))}
        onClick={onClickRow ? () => { onClickRow(idcol.get(row)); } : undefined}
        position={index}
        tone={row.__tone__}

      >
        {notHiddenCols.map(col => colMarkup(col, row)).lift(e => onClickRow ? [
          e[0],
          (<td key="data-primary-link" data-primary-link style={{ display: "none" }}></td>),
          ...e.slice(1)
        ] : e)}
      </IndexTable.Row>
  ));


  return loading ? <SpinnerBlock /> : <IndexTable
    resourceName={resourceName}
    itemCount={notHiddenRows.length}
    headings={headings}
    selectable={selectMultiple}
    {...sortOpts}
    sortable={notHiddenCols.map(e => !!e.sorter)}
    emptyState={emptyState}
    onSelectionChange={onSelectionChange}
    pagination={page}
  >{rowsMarkup}</IndexTable>;
}
const colMarkup = (col: ColumnBase, row: any) => {
  if (col.hidden) return null;
  const link = col.link ? col.link(row) : null;
  if (link)
    return <IndexTable.Cell key={col.key}><Link url={link}>{valMarkup(col, row)}</Link></IndexTable.Cell>;
  else
    return <IndexTable.Cell key={col.key}>{valMarkup(col, row)}</IndexTable.Cell>;
}



export function SpinnerBlock() {
  return (
    <Box padding="400" width="100%">
      <InlineStack align="center" blockAlign="center">
        <Spinner />
      </InlineStack>
    </Box>
  )
}
/** 
 * Returns an memoized array of the function with the index bound as the first argument. 
 * Remember to wrap the function in useCallback, otherwise it will just rebuild the array every time.
*/
function useArrayCallbackMemo(length: number, fn: (index: number) => any) {
  return useMemo(() => Array.from({ length }, (_, i) => fn.bind(undefined, i)), [length, fn]);
}

function matchAppliedFilters(row: unknown, curAppliedFilters?: AppliedFilterProps[]): boolean {
  if (!curAppliedFilters) return true;
  return curAppliedFilters.every(({ column, clientCheck }) => {
    if (!column || !clientCheck) return true;
    return clientCheck(row, column);
  });
}

/** Uses useMemo with an empty deps array to calculate the value once and return it. */
function useInit<T>(fn: () => T) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(fn, []);
}

export function useSortOpts(rows: any[], cols: readonly ColumnBase[], curAppliedFilters: AppliedFilterProps[] | undefined, firstSort: string | undefined) {

  const { startKey, startDirection } = useInit((): {
    startKey: string,
    startDirection: IndexTableSortDirection,
  } => {
    const sortcols = cols.filter(e => !e.hidden && e.sort).sort((a, b) => Math.abs(a.sort) - Math.abs(b.sort));
    if (firstSort) {
      const startKey = firstSort.startsWith("-") ? firstSort.slice(1) : firstSort;
      const startDirection = firstSort.startsWith("-") ? "descending" : "ascending";
      const dir = firstSort.startsWith("-") ? -1 : 1;
      cols.forEach((e) => { e.sort = (e.key === startKey) ? dir : 0; });
      return { startKey, startDirection };
    }
    if (sortcols.length > 0) return {
      startKey: sortcols[0].key,
      startDirection: sortcols[0].sort < 0 ? "descending" : "ascending"
    }
    return {
      startKey: "",
      startDirection: "ascending"
    };
  });

  const [curSortColumnKey, setSortColumnKey] = useState<string>(startKey);
  const [curSortDirection, setSortDirection] = useState<IndexTableSortDirection>(startDirection);

  const columnSort = JSON.stringify(
    cols
      .filter(e => !e.hidden && e.sort)
      .sort((a, b) => Math.abs(a.sort) - Math.abs(b.sort))
      .map(e => [e.key, e.sort])
  );
  const visibleColumns = JSON.stringify(
    cols
      .filter(e => !e.hidden)
      .map(e => e.key)
  );

  const onSort = useCallback((index: number, direction: IndexTableSortDirection) => {
    const dir = direction === "ascending" ? 1 : direction === "descending" ? -1 : 0;
    const visCols = JSON.parse(visibleColumns);
    const key = visCols[index];
    cols.forEach((e) => { e.sort = (e.key === key) ? dir : 0; });
    setSortColumnKey(key);
    setSortDirection(direction);
  }, [cols, visibleColumns]);

  const sortColumnIndex = cols.filter(e => !e.hidden).findIndex(e => e.key === curSortColumnKey);

  console.log("useSortOpts", { curSortColumnKey, curSortDirection, sortColumnIndex });

  const hiddenIndexWatch = curAppliedFilters && JSON.stringify(rows.map(row => !matchAppliedFilters(row, curAppliedFilters)));

  const colsKeyWatch = JSON.stringify(cols.map(e => e.key));
  const colsMap = useMemo(() => new Map(cols.map(e => [e.key as string, e] as const)), [cols, colsKeyWatch]);

  const sortedRows = useMemo(() => {
    const colsSort = JSON.parse(columnSort);
    const hiddenIndex = hiddenIndexWatch && JSON.parse(hiddenIndexWatch);
    const sortRows = (hiddenIndex ? rows.filter((e, i) => !hiddenIndex[i]) : rows.slice());
    if (colsSort.length === 0) return sortRows;
    return sortRows.sort((a: any, b: any) => {
      for (const [key, sort] of colsSort) {
        const col = colsMap.get(key);
        if (!col) continue;
        const res = col.sorter(a, b, Math.sign(sort) as 1 | -1);
        if (res) return res;
      }
      return 0;
    })
  }, [rows, colsMap, columnSort, hiddenIndexWatch]);

  return {
    sortColumnIndex,
    sortDirection: curSortDirection,
    onSort,
    sortedRows,
  } as const;

}


/**
 * 
 * 
 * `const customs = useMemo(() => table ? new CustomColumnState(table, data, cols) : undefined, [table, cols]);`
 */
export function useTableListTree(
  table: TABLE_NAMES | undefined,
  view: TableView,
  colsHook?: (cols: readonly ColumnBase[]) => void,
  defaultSort = true,
) {

  const tableView = useMemo(() => TableViewOptions.fromViewWithOptions(table as TABLE_NAMES, view, view, defaultSort), [table, view]);

  const colsHook2 = useRef(colsHook);
  colsHook2.current = colsHook;

  const cols = useMemo(() => {
    const cols = tableView.makeDataListColumns();
    colsHook2.current?.(cols);
    return cols;
  }, [tableView]);

  const queryColumns = useMemo(() => cols.filter(e => e.queryColumn), [cols]);

  const inner = useTableState(cols, "id");

  const { arrayList, arraySort } = tableView;

  return { ...inner, cols, tableView, queryColumns, arrayList, arraySort };

}


export function useTableState(cols: readonly ColumnBase[], idKey: string) {

  const inner = useMemo(() => {

    const idcol = new DataListIdFieldColumn(idKey);

    const setID = true;

    const { TableRowRedux, initialState } = makeTableRowRedux(setID, cols, idcol);

    const colsMap = new Map(cols.map(e => [e.key, e]));

    return { idcol, colsMap, TableRowRedux, initialState };

  }, [cols, idKey]);

  const [curValue, setValue] = useReducer(inner.TableRowRedux, inner.initialState);

  const { rows, value } = curValue;

  return { ...inner, rows, setValue, value };
}
