import { isBefore, isAfter, isEqual, parse as parseDate, toDate, } from "date-fns";
import * as datefns from "date-fns";
import { strictEqual } from "assert";

if (28 !== datefns.addMonths(datefns.parse("2023-01-31", "yyyy-MM-dd", /* ~ 8/3/2023 */ 1691070819923), 1).getDate())
  throw "Adding one month to the last day of January does not return the last day of February";

export const dateESS = "M/d/y";
export const dateCubes = "yyyy-MM-dd";

export type DateInput = number | Date;
export type DateUnit = "Year" | "Quarter" | "Month" | "Week" | "Day" | "Hour" | "Minute" | "Second";
export type SliceOne<T> = T extends [infer A, ...infer B] ? B : never;
export type SliceTwo<T> = T extends [infer A1, infer A2, ...infer B] ? B : never;
export type DateExtFunc<T, K> = T extends (...args: infer A) => infer R ?
  (...args: SliceOne<A>) =>
    K extends "toDate" ? Date :
    R extends Date ? DateExtDict :
    R : never;
export type DateFnsDict = { [K in keyof typeof datefns]: DateExtFunc<(typeof datefns)[K], K> }
export type ExtCompare = typeof compare extends (...args: infer A) => infer R ? (...args: SliceTwo<A>) => R : never;
export interface DateExtDict extends DateFnsDict {
  // compare: (b: DateInput, unit?: DateUnit) => boolean;
  isBefore: ExtCompare,
  isBeforeEqual: ExtCompare,
  isAfter: ExtCompare,
  isAfterEqual: ExtCompare,
  isEqual: ExtCompare
}

export type CompareArgs = { before?: boolean, after?: boolean, equal?: boolean, unit?: DateUnit };
export function compare(a: DateInput, { unit, before, after, equal }: CompareArgs, b: DateInput, unit2?: DateUnit) {
  if (unit2) unit = unit2;
  if (unit) {
    const item = `startOf${unit}` as const;
    a = datefns[item](a);
    b = datefns[item](b);
  }
  return (equal && isEqual(a, b)) || (before && isBefore(a, b)) || (after && isAfter(a, b)) || false;
}
export function dateSort<T>(fn: (a: T) => { valueOf(): number; }) {
  return (a: T, b: T) => {
    return fn(a).valueOf() - fn(b).valueOf();
  }
}


export function DateExt(date?: Date) {
  if (!date) date = new Date();
  date = toDate(date);
  return new Proxy<DateExtDict>(date as any, {
    get(target, p, receiver) {
      const t: any = target;
      const d: any = datefns;

      switch (p) {
        case "__isProxy":
          return true;
        case "compare":
          return compare.bind(null, t);
        case "isEqual":
          return compare.bind(null, t, { equal: true });
        case "isBefore":
          return compare.bind(null, t, { before: true });
        case "isBeforeEqual":
          return compare.bind(null, t, { before: true, equal: true });
        case "isAfter":
          return compare.bind(null, t, { after: true });
        case "isAfterEqual":
          return compare.bind(null, t, { after: true, equal: true });
      }
      // if (Object.hasOwn(t, p)) return t[p];
      if (typeof d[p] !== "undefined") return (...args: any[]): any => {
        const res = d[p](t, ...args);
        return res instanceof Date && p !== "toDate" ? DateExt(res) : res;
      }
      throw new Error(`Invalid get property ${p.toString()}`);
    },
    getPrototypeOf(target) {
      return target.constructor.prototype;
    },
    set(target, p, newValue, receiver) {
      const t: any = target;
      const d: any = datefns;
      if (typeof t[p] !== "undefined") t[p] = newValue;
      else throw new Error(`Invalid set property ${p.toString()}`);
      return true;
    },
  })
}
export function parseCubes(str: string) {
  return DateExt(parseDate(str, dateCubes, Date.now()));
}
export function parseESS(str: string) {
  return DateExt(parseDate(str, dateESS, Date.now()));
}