
import type { Tables, TABLE_NAMES } from "./cubes-utils";
import type { MemberKeys } from "./graphql-declarator";
import { TableType } from "./cubes-schema-helpers";
import { Customer } from "./cubes-schema";
import { format } from "date-fns";
import * as PrismaExtra from "prisma-client";

// declare namespace PrismaExtra {
//   export namespace Prisma {
//     export type TransactionWhereInput = any;
//   }
//   export const PrismaClient: any;
//   export type PrismaClient = any;
// }
// type PrismaExtra = any;
function capitalize<T extends string>(table: T): Capitalize<T> {
  return table.slice(0, 1).toUpperCase() + table.slice(1) as any;
}
export const proxy: PrismaExtra.PrismaClient = (() => new Proxy<any>({}, {
  get(target: any, table: any, receiver) {
    return target[table] = target[table] || new Proxy<any>({}, {
      get(target: any, action: any, receiver) {
        return target[action] = target[action] || ((arg: any) => {
          return { action, table: capitalize(table), arg };
        })
      },
    })
  },
}))();

type RequiredTruthy<T> = { [P in keyof T]-?: T[P] & {}; }
type PartialTruthy<T> = { [P in keyof T]?: T[P] & {}; }
interface ModelWhereInput<T> {
  AND?: Array<T> | T
  OR?: Array<T> | T
  NOT?: Array<T> | T
  // id?: Prisma.StringFilter | string
}
type Table<T extends TableType> = { [K in keyof Tables]: Tables[K] extends T ? K : never }[keyof Tables];

export type childFilterArg<T extends TableType, K extends MemberKeys<T>> = any;

/** If this line has an error, replace the value with any and rebuild the prisma schema */
export type parentFilterArg<T extends TableType> = /* any;// */ PrismaExtra.Prisma.TypeMap["model"][Table<T>]["operations"]["findMany"]["args"]["where"];

const WHERE_LiveTransactionLine = (): PrismaExtra.Prisma.TransactionWhereInput => ({
  VoidSince: null,
  IS_TESTING: false,
  OR: [
    { customerLedgerLine: { customer: { IS_TESTING: false } } },
    { customerLedgerLine: null },
  ]
});

export const WHERE_balanceWhereLine = () => {
  return ({
    VoidSince: null,
    IS_TESTING: false,
    OR: [
      // invoice lines that haven't arrived yet do not get paid out, even if they're paid
      { invoiceLine: { paidOn: { not: null }, }, Date: { lte: format(Date.now(), "yyyy-MM-dd") } },
      // these would be payout lines, not customer payments
      { paymentLine: { PaymentStatus: "Cleared" } },
      { paymentLine: { PaymentStatus: "Approved" } },
      // no idea what these are. Probably adjustments. They should be included regardless.
      { paymentLine: null, invoiceLine: null, }
    ]
  }) satisfies PrismaExtra.Prisma.TransactionWhereInput;
};

