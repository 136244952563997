/* eslint-disable no-empty */
import React from "react";
import { TextFieldProps } from "@shopify/polaris";
import { AsYouType, getAsYouType } from "awesome-phonenumber";
import { useObservable } from "react-utils";
import { ok } from "common";
const selectionchange = (event: any) => {
  console.log(event);
};

export const grabTextFieldInputRef: (myref: React.Ref<HTMLInputElement | null>) => TextFieldProps["renderInputField"]
  = (myref) => (props) => {
    if (props) {
      const oldref: any = props.ref;
      props.ref = (ref: HTMLInputElement) => {
        if (oldref) {
          if (typeof oldref === "string") { }
          else if (typeof oldref === "function") { oldref(ref); }
          else if (typeof oldref === "object") { oldref.current = ref; }
        }
        if (myref) {
          if (typeof myref === "string") { }
          else if (typeof myref === "function") { myref(ref); }
          else if (typeof myref === "object") { (myref as any).current = ref; }
        }
      }
    }

    return React.createElement("input", props);
  }


export function formatPhoneNumber(
  value: any,
  newval: string,
  type: AsYouType,
  inputRef: React.MutableRefObject<HTMLInputElement | null>,
) {

  if (value !== type.number())
    type.reset(value.replace(/[^\d+]/g, ""));
  if (value !== type.number())
    throw new Error(`${value} !== ${type.number()}`);

  if (inputRef.current) {
    if (inputRef.current.selectionStart === inputRef.current.selectionEnd) {
      let position = inputRef.current.selectionStart;
      if (position === null) position = newval.length;
      const current = inputRef.current.value;
      const atEnd = position === current.length;
      const newNum = current.replace(/[^\d+]/g, "");
      const oldNum = value.replace(/[^\d+]/g, "");
      if (!atEnd) {
        const isNumber = /[\d+]/;
        // format the new value
        newval = type.reset(current.replace(/[^\d+]/g, ""));
        // get the cursor position before formatting
        const oldpos = count(current.slice(0, position), (e) => isNumber.test(e));
        // get the cursor position after formatting
        const { numberchecked: newpos } = countUntil(oldpos, newval.length, (i) => isNumber.test(newval[i]));
        // set the cursor position to the digit that was at the old position
        inputRef.current.value = newval;
        inputRef.current.setSelectionRange(newpos, newpos);
      } else if (oldNum.length < newNum.length && newNum.slice(0, oldNum.length) === oldNum) {
        for (let i = oldNum.length; i < newNum.length; i++)
          newval = type.addChar(newNum[i]);
      } else if (newNum.length < oldNum.length && oldNum.slice(0, newNum.length) === newNum) {
        for (let i = oldNum.length; i > newNum.length; i--)
          newval = type.removeChar();
      } else {
        newval = type.reset(current.replace(/[^\d+]/g, ""));
      }
    }
  }

  return newval;
}


export function setInputField({
  formatted,
  inputRef,
  extraDigits
}: {
  formatted: string;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  extraDigits?: number;
}) {
  console.log(formatted);
  if (inputRef.current) {
    if (inputRef.current.selectionStart === inputRef.current.selectionEnd) {
      const current = inputRef.current.value;
      const periodStart = current.startsWith(".");
      let position = inputRef.current.selectionStart;
      if (position === null) position = current.length;
      // const atEnd = position === current.length;
      inputRef.current.value = formatted;
      // valid characters to type are digits, minus, and period
      const isNumber = /[\d-.]/;
      // get the cursor position before formatting
      const oldpos = count(current.slice(0, position), (e) => isNumber.test(e));
      // get the cursor position after formatting
      let { numberchecked: newpos } = countUntil(oldpos, formatted.length, (i) => isNumber.test(formatted[i]));
      // if the old string started with a period there will be an added zero at the beginning of the formatted string
      if (extraDigits) newpos += extraDigits;
      // set the cursor position to the digit that was at the old position
      inputRef.current.setSelectionRange(newpos, newpos);

    }
  }

}


const count = (str: string, pred: (char: string, index: number) => boolean) => str.split("").filter(pred).length;
const countUntil = (target: number, limit: number, pred: (index: number) => boolean) => {
  let numberchecked = 0;
  let numberfound = 0;
  for (; (numberchecked < limit && numberfound < target); numberchecked++)
    if (pred(numberchecked))
      numberfound++;
  return { numberchecked, numberfound };
}
{
  const { numberchecked, numberfound } = countUntil(5, 5, () => true);
  ok(numberchecked === numberfound && numberchecked === 5, "countUntil 5, 5");
}


