import React from "react";
import { Autocomplete, BlockStack, Checkbox, ChoiceList, ChoiceListProps, InlineStack, Labelled, Text, TextField } from "@shopify/polaris";
import { useCallback, useReducer, useRef, useState } from "react";
import { ChoiceListInlineStack } from "./zfields";
import { reducerKeyValueMap } from "./useSimpleValueStore";

type SimpleValueFieldsProps<T extends { readonly [K: string]: string; }, R> = R & {
  key: string & keyof T;
  title: string;
  helptext?: string;
  required?: boolean;
  validate?: (value: T) => string | undefined;
}
/** Do not memoize this class. The constructor is a react hook and contains use* hook calls */
export abstract class useSimpleValueFields<T extends { readonly [K: string]: string; }> {
  public abstract editing: boolean;
  public abstract saving: boolean;
  public curValue: T;
  public changedFields: { readonly [key: string]: boolean; };
  public blurredFields: { readonly [key: string]: boolean; };
  public setValue: React.Dispatch<React.SetStateAction<T>>;
  public setChangedFields: React.Dispatch<{ key: string & keyof T; value: boolean; } | "clear">;
  public setBlurredFields: React.Dispatch<{ key: string & keyof T; value: boolean; } | "clear">;
  // public abstract saving: boolean,
  // public abstract initValue: T;
  // public abstract savedValue: T,
  // public abstract action: (action: "edit" | "cancel" | "save" | "reset" | { key: keyof T; value: string; }) => void,
  valid = true;
  constructor(
    initValue: T
  ) {

    const [curValue, setValue] = useState(initValue);
    this.curValue = curValue;
    this.setValue = setValue;

    const [curChangedFields, setChangedFields] = useReducer(reducerKeyValueMap<string, boolean>, {});
    this.changedFields = curChangedFields;
    this.setChangedFields = setChangedFields;

    const [curBlurredFields, setBlurredFields] = useReducer(reducerKeyValueMap<string, boolean>, {});
    this.blurredFields = curBlurredFields;
    this.setBlurredFields = setBlurredFields;

  }
  checkValid(validate: ((value: T) => string | undefined) | undefined, required: boolean | undefined, key: string, title: string) {
    if (!this.blurredFields[key]) { return; }
    if (required && !this.curValue[key]) {
      this.valid = false;
      return `${title} is required`;
    }
    const error = validate?.(this.curValue);
    if (error)
      this.valid = false;
    return error;
  }

  Checkbox = ({
    key, title, helptext,
    validate, required,
    trueValue, falseValue,
  }: SimpleValueFieldsProps<T, {
    trueValue: string;
    falseValue: string;
  }>) => {

    if (!this.editing)
      return (
        <BlockStack key={key} gap="100">
          <Text as="h4" variant="headingMd">{title}</Text>
          <Text as="span" variant="bodyMd">{this.curValue[key]}</Text>
        </BlockStack>
      );

    return (
      <Checkbox
        key={key}
        label={title}
        helpText={helptext}
        error={this.checkValid(validate, required, key, title)}
        checked={this.curValue[key] === trueValue}
        onChange={(value) => {
          this.setChangedFields({ key, value: true });
          this.setValue(curValue => ({ ...curValue, [key]: value ? trueValue : falseValue }));
        }}
      />
    )
  }

  TextField = ({
    key, title, helptext, onChangeMap, validate, required
  }: SimpleValueFieldsProps<T, {
    onChangeMap?: (input: string) => string;
  }>) => {
    if (!this.editing)
      return (
        <BlockStack key={key} gap="100">
          <Text as="h4" variant="headingMd">{title}</Text>
          <Text as="span" variant="bodyMd">{this.curValue[key]}</Text>
        </BlockStack>
      );

    return (
      <TextField
        requiredIndicator={required}
        key={key}
        autoComplete={key}
        label={title}
        helpText={helptext}
        error={this.checkValid(validate, required, key, title)}
        value={this.curValue[key]}
        onChange={(value) => {
          this.setChangedFields({ key, value: true });
          this.setValue(curValue => ({ ...curValue, [key]: onChangeMap ? onChangeMap(value) : value }));
        }}
        onBlur={() => { this.setBlurredFields({ key, value: true }); }}
      />
    );

  };
  ChoiceList = ({
    required, key, title, helptext, choices, validate,
  }: SimpleValueFieldsProps<T, {
    choices: ChoiceListProps["choices"];
  }>) => {

    if (!this.editing)
      return (
        <BlockStack key={key} gap="100">
          <Text as="h4" variant="headingMd">{title}</Text>
          <Text as="span" variant="bodyMd">{this.curValue[key]}</Text>
        </BlockStack>
      );

    this.checkValid(validate, required, key, title);

    return (
      <Labelled
        key={key}
        id={key}
        label={title + (required ? " *" : "")}
        helpText={helptext}
        error={this.checkValid(validate, required, key, title)}
      >
        <ChoiceList
          title=""
          titleHidden
          choices={choices}
          selected={[this.curValue[key]]}
          onChange={(value) => {
            this.setChangedFields({ key, value: true });
            this.setValue(curValue => ({ ...curValue, [key]: value[0] }));
          }}
        />
      </Labelled>
    );

  };
  ChoiceListInline = ({
    key, title, helptext, choices, validate, required, inline
  }: SimpleValueFieldsProps<T, {
    choices: ChoiceListProps["choices"];
    inline?: boolean
  }>) => {
    const currentLabel = choices.find(e => e.value === this.curValue[key])?.label;

    if (!this.editing)
      return (
        <BlockStack key={key} gap="100">
          <Text as="h4" variant="headingMd">{title}</Text>
          <Text as="span" variant="bodyMd">{currentLabel}</Text>
        </BlockStack>
      );

    return (
      <ChoiceListInlineStack
        key={key}
        label={title + (required ? " *" : "")}
        helpText={helptext}
        error={this.checkValid(validate, required, key, title)}

        choices={choices}
        selected={[this.curValue[key]]}
        onChange={(value) => {
          this.setChangedFields({ key, value: true });
          this.setValue(curValue => ({ ...curValue, [key]: value[0] }));
        }} />
    );
  };

  AddressLookup = ({ key, title, onSearch, validate, required }: {
    required?: boolean;
    key: string & keyof T;
    title: string;
    onSearch: (value: string) => Promise<{ value: string; label: string; }[]>;
    validate?: (value: T) => string | undefined;
  }) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    // const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<{ label: string; value: string; }[]>([]);
    const [loading, setLoading] = useState(false);
    const timeout = useRef<any>();

    const onChangeField = useCallback((value: string) => {
      if (loading)
        return;
      this.setChangedFields({ key, value: true });
      this.setValue(curValue => ({ ...curValue, [key]: value }));
      if (timeout.current)
        clearTimeout(timeout.current);
      timeout.current = setTimeout(async () => {
        timeout.current = undefined;
        setLoading(true);
        setOptions(await onSearch(value));
        setLoading(false);
      }, 500);
    }, [loading, onSearch, key]);

    const onSelectResult = useCallback(([selected]: string[]) => {
      // console.log(selected);
      // setInputValue(selected);
      this.setValue(curValue => ({ ...curValue, [key]: selected }));
    }, [key]);

    if (!this.editing)
      return (
        <BlockStack key={key} gap="100">
          <Text as="h4" variant="headingMd">{title}</Text>
          <Text as="span" variant="bodyMd">{this.curValue[key]}</Text>
        </BlockStack>
      );

    return <Autocomplete
      key={key}
      options={options}
      selected={selectedOptions}
      onSelect={onSelectResult}
      loading={loading}
      textField={<Autocomplete.TextField
        requiredIndicator={required}
        error={this.checkValid(validate, required, key as string, title)}
        label={title}
        value={this.curValue[key]}
        onChange={onChangeField}
        onBlur={() => { this.setBlurredFields({ key, value: true }); }}
        placeholder="Search"
        autoComplete="off" />} />;
  };
}
