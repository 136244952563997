import { PropsWithChildren } from 'react';
import { Box, InlineStack, Text } from '@shopify/polaris';
import React from "react";
import { SpaceScale } from '@shopify/polaris-tokens';
import { ResponsiveProp } from '@shopify/polaris/utilities/css';

type Spacing = ResponsiveProp<SpaceScale>;
export function CardTitle({ title, padding = "300", children }: PropsWithChildren<{ title?: string | React.JSX.Element; padding?: Spacing; }>) {
  return (
    <Box padding={padding}>
      <InlineStack align="space-between" blockAlign='center'>
        {...title === undefined ? [] : [typeof title === "string" ? <Text as="h3" variant='headingMd'>{title}</Text> : title]}
        {children}
      </InlineStack>
    </Box>
  );
}
