import React, { Dispatch, useCallback, useReducer } from "react";
import { Card, Popover } from "@shopify/polaris";

const ComboBoxRedux = (state: { focus: boolean; open: boolean; }, action: { focus?: boolean; open?: boolean; }) => {
  const prev = { ...state };
  const next = { ...state, ...action };
  if (prev.focus !== next.focus || prev.open !== next.open) return next;
  return state;
};

export function useComboBoxState() {
  const [{ focus, open }, setState] = useReducer(ComboBoxRedux, { focus: false, open: false });
  const onFocus = useCallback(() => { setState({ open: true, focus: true }); }, []);
  const onBlur = useCallback(() => { setState({ focus: false }); }, []);
  return { focus, open, setState, onFocus, onBlur };
}

export function PopoverDropdown({ open, focus, setState, activator, dropdown }: {
  activator: React.ReactElement<any, any>;
  dropdown: React.ReactElement<any, any> | null;
  focus: boolean;
  open: boolean;
  setState: Dispatch<{ focus?: boolean; open?: boolean; }>;
}) {

  return (
    // <BlockStack inlineAlign="center" gap="400">
    // <Box minWidth="276px" padding={{ xs: "200" }}>
    <Popover
      active={open && dropdown !== null}
      autofocusTarget="none"
      preferredAlignment="left"
      preferInputActivator={false}
      preferredPosition="below"
      onClose={() => { if (!focus) setState({ open: false }); }}
      activator={activator}
    >{dropdown}</Popover>
    // </Box>
    // </BlockStack>
  );

}
