import React, { PropsWithChildren, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { BlockStack, Box, Card, DatePicker, Icon, Popover, TextField, TextFieldProps } from "@shopify/polaris";
import { CalendarIcon } from "@shopify/polaris-icons";
import { format, formatISO, isValid, parse, parseISO, parseJSON } from 'date-fns';
import { FormControl } from "@angular/forms";
import { useLayoutSubscribe } from "react-utils";
import { useComboBoxState, PopoverDropdown } from "./TextFieldDropdown";
function nodeContainsDescendant(rootNode: any, descendant: any) {
  if (rootNode === descendant) {
    return true;
  }
  let parent = descendant.parentNode;
  while (parent != null) {
    if (parent === rootNode) {
      return true;
    }
    parent = parent.parentNode;
  }
  return false;
}
type DatePickerValueTypes = {
  valueType?: "date";
  value: Date | null;
  onChange: (value: Date | null, id?: string) => void;
} | {
  valueType: "string";
  value: string | null;
  onChange: (value: string | null, id?: string) => void;
};
// export type DatePickerComboBoxProps = { pattern?: string; formControl?: FormControl };

type ValueType<A extends "string" | "date"> = A extends "string" ? string | null : Date | null;

interface DatePickerComboBoxProps<A extends "string" | "date"> {
  pattern?: string;
  valueType: A;
  value: ValueType<A>;
  onChange: (value: ValueType<A>, id?: string) => void;
  default?: ValueType<A>;
}

export function DatePickerComboBox<A extends "string" | "date">({ pattern, id, valueType, value, onChange, default: def, ...props }: Omit<TextFieldProps, "value" | "onChange"> & (DatePickerComboBoxProps<A>)) {

  const parseDateTime = useCallback((text: string) => pattern ? parse(text, pattern, Date.now()) : parseISO(text), [pattern]);
  const formatDateTime = useCallback((date: Date) => pattern ? format(date, pattern) : formatISO(date), [pattern]);

  const state = useMemo(() => ({ value, valueType, onChange }) as DatePickerValueTypes, [value, onChange, valueType]);
  const init = useMemo(() => def && (typeof def === "string" ? parseDateTime(def) : def as Date) || new Date(), [def, parseDateTime]);

  // text field value
  const [raw, setRaw] = useState(formatDateTime(init));
  // text field error message
  const [error, setError] = useState<string | undefined>(undefined);
  // combo box state
  const { focus, open, setState: setComboBox, onFocus, onBlur } = useComboBoxState();
  // date picker calendar view
  const [{ month, year }, setDate] = useState(useMemo(() => ({ month: init.getMonth(), year: init.getFullYear(), }), [init]));

  const onMonthChange = useCallback((month: number, year: number) => { setDate({ month, year }); }, []);

  const selectedDate = !state.value ? undefined : state.valueType === "string" ? parseDateTime(state.value) : state.value;

  // console.log(state, init, raw, month, year, selectedDate)

  const setParsedValue = useCallback((parsed: Date | null) => {
    if (parsed === null) return state.onChange(null, id);
    setDate({ month: parsed.getMonth(), year: parsed.getFullYear() });
    if (state.valueType === "string") {
      const value = formatDateTime(parsed);
      state.onChange(value, id);
    } else {
      state.onChange(parsed, id);
    }
  }, [formatDateTime, id, state]);

  useLayoutEffect(() => {
    const [, year, month] = /(\d{4})-(\d{2})/.exec(raw) || [];
    if (year && month) setDate({ year: Number(year), month: Number(month) - 1 });
  }, [raw]);

  useEffect(() => {
    if (open || focus) return;
    console.log("DatePicker blur");
    if (!selectedDate) { setRaw(""); state.onChange(null); }
    else setRaw(formatDateTime(selectedDate));
    setError(undefined);
  }, [open, focus, selectedDate, state, formatDateTime]);

  return <PopoverDropdown
    open={open}
    focus={focus}
    setState={setComboBox}
    activator={
      <TextField
        prefix={<Icon source={CalendarIcon} />}
        {...props}
        onFocus={onFocus}
        onBlur={onBlur}
        error={error}
        role="combobox"
        pattern={/\d{4}-\d{2}-\d{2}/.source}
        value={raw}
        onChange={(raw, id) => {
          // setComboBox({ open: true, focus: true });
          setRaw(raw);
          if (raw === "") setParsedValue(null);
          const parsed = parseDateTime(raw);
          const valid = !Number.isNaN(parsed.valueOf());
          setError(valid ? undefined : "Invalid date");
          if (valid) setParsedValue(parsed);
        }}
      />
    }
    dropdown={
      <Card padding="200">
        <Box width="18rem">
          <DatePicker

            month={month}
            year={year}
            selected={selectedDate}
            onMonthChange={onMonthChange}
            onChange={({ end: value }) => {
              setRaw(formatDateTime(value));
              setError(undefined);
              setComboBox({ open: false });
              setParsedValue(value);
            }}
          />
        </Box>
      </Card>
    }
  />

}

