import { Dispatch } from "react";
import { ColumnBase } from '../utils';
import { okNull } from "common";


export type TableRowRedux = ReturnType<typeof makeTableRowRedux>["TableRowRedux"];
export type TableRowDispatch = Dispatch<TableRowAction>;
export type TableRowState = { value: any[] | null | undefined; rows: any[]; };
export type TableRowAction = { action: "reset"; newValue: any[] | null | undefined; } |
{ action: "bump"; } |
{ action: "setRow"; row: any; formValue: any; } |
{ action: "addRow"; row: any; } |
{ action: "addRowIfNew"; row: any; } |
{ action: "deleteRow"; row: any; };
export function makeTableRowRedux(setID: boolean, cols: readonly ColumnBase[], idcol: ColumnBase) {
  const initialState: TableRowState = { value: undefined, rows: [] };
  return { TableRowRedux, initialState };

  function TableRowRedux(state: TableRowState, action: TableRowAction): TableRowState {

    switch (action.action) {
      case "setRow": {
        let { row, formValue } = action;
        if (state.rows.indexOf(row) > -1) {
          Object.assign(row, formValue);
        } else {
          mapRow(formValue, state.rows.length);
          state.rows.push(formValue as any);
          row = formValue;
        }
        return { rows: state.rows, value: JSON.clone(state.rows) };
      }
      case "addRow": {
        const { row } = action;
        mapRow(row, state.rows.length);
        state.rows.push(row);
        return { rows: state.rows, value: JSON.clone(state.rows) };
      }
      case "addRowIfNew": {
        const { row } = action;
        if (state.rows.indexOf(row) === -1) {
          mapRow(row, state.rows.length);
          state.rows.push(row);
        }
        return { rows: state.rows, value: JSON.clone(state.rows) };
      }
      case "deleteRow": {
        const { row } = action;
        const index = state.rows.indexOf(row);
        state.rows.splice(index, 1);
        return { rows: state.rows, value: JSON.clone(state.rows) };
      }
      case "reset": {
        const { newValue } = action;
        if (newValue === null || newValue === undefined)
          return { value: newValue, rows: [] };
        newValue.forEach(mapRow);
        // console.log("useTableListInner useMemo value", newValue);
        return { rows: newValue, value: JSON.clone(newValue) };
      }
      case "bump": {
        return { value: JSON.clone(state.rows), rows: state.rows };
      }
    }

  }
  function mapRow(row: any, i: number) {

    if (setID) {
      if (!idcol.get(row))
        Object.defineProperty(row, idcol.key, {
          configurable: true,
          enumerable: false,
          value: i.toFixed(0),
          writable: false
        });
    } else {
      okNull(idcol.get(row));
    }

    Object.defineProperties(row, cols.reduce((n, e, col) => (
      n["filter:" + e.key] = {
        get: () => cols[col].valFilter(row),
        enumerable: false,
        configurable: true,
      }, n), {} as PropertyDescriptorMap)
    );

  }
}
