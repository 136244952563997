import {
  ArrayColumn,
  ColumnBase,
  DataListIdFieldColumn,
  Dialog,
  FormsQuestionService,
  GroupDialog,
  LedgerTables,
  QuestionHasManyEvent,
  QuestionSelectLabel,
  QuestionSelectOptions,
  QuestionTinyMCE,
  RowEditAccessor,
  SelectEnumColumn,
  SimpleKeyColumn
} from '../utils';

import { EventEmitter, Injectable, Injector } from "@angular/core";
import { UrlSegment } from "@angular/router";

import {
  AnyMember,
  belongsTo,
  ConfirmType,
  field as fieldAttr,
  FieldClass,
  GeocodeAddress, getValueByPath, hasOne,
  is, lookup,
  MemberKeys,
  Modes, ok, okNull,
  prejoin, PrismaQuery, ProxyPromise,
  RecordType, root,
  schema,
  select, SPPI, StringPathProxy as SPP,
  SyncCheck, TableType, TABLE_NAMES, toKeysTyped,
  truthy,
  TYPE_NAMES, ValueTree, DataQueryGraph, arrayListKeys, Root
} from "common";
import { Generator } from "./Generator";
import {
  FGC,
  FGCR,
  QuestionAutoComplete,
  QuestionCheckboxGrid,
  QuestionGroup,
  QuestionOptions,
  QuestionSelect,
  QuestionSimple,
  QuestionSubGroup,
  QuestionTable
} from '../utils';
import {
  concatMap,
  debounceTime,
  from,
  mergeAll,
  Observable,
  ReplaySubject,
  startWith,
  Subscription,
  tap
} from "rxjs";


import { fetchAuthSession } from '@aws-amplify/auth';
import { Location } from "@aws-sdk/client-location";
import { join } from "path";
import { plural } from "pluralize";
import { } from "common";
import { DataService } from "data-service";
import { UI_Schema } from "./ui.utils";
import { RowProps } from "@shopify/polaris/components/IndexTable";
import { ListValue, TableViewClass } from "../tables/table-views";
import { ReactInjectable } from "react-utils";

export interface QuestionEnumOption extends ValueTree<fieldAttr<any, any>> {
  value: string;
  order: number;
}

// export interface selectRelation<T extends TableType, H extends RecordType> {
//   hostTable?: string;
//   targetTable?: string;
//   belongsTo?: MemberKeys<H>;
//   currentHasOne_RemoteID?: MemberKeys<T>;
//   currentHasOne?: MemberKeys<H>;
//   dropdownColumns?: string[];
//   dropdownFilter?: ValueTree<FilterWith<any, any>>[] | undefined;
//   customQuery?: string;
// };

function isTable(type: TYPE_NAMES): type is TABLE_NAMES {
  return type in schema.tables;
}


interface LookupFilter {
  filterThis: string;
  filterWith?: string;
  filterConst?: any;
}
interface Lookup<T extends TableType> {
  optionValue: MemberKeys<T>,
  optionList: string[],
  optionFilter: LookupFilter[],
  targetTable: string,
}
@ReactInjectable()
@Injectable()
export class UIService {
  fq;
  tables;
  schema: UI_Schema;
  private data;
  constructor(
    injector: Injector,
  ) {
    this.data = injector.get(DataService);
    this.schema = {} as any;
    this.schema.ui = this;
    this.setSchemaEnums(this.schema);
    this.setSchemaTypes(this.schema);
    this.setSchemaForms(this.schema);
    this.tables = toKeysTyped(schema.tables, true).filter(e => e.attributes.forms?.first());
    this.fq = new FormsQuestionService(injector);
  }

  setSchemaTypes(types: any) {
    Object.entries(root.types).forEach(([k, v]) => {
      types[k] = (mode: Modes) => Generator.Group(k as TYPE_NAMES, mode, this);
    });
  }
  setSchemaForms(forms: any) {
    Object.entries(root.types).forEach(([k, v]) => {
      Object.entries(v.__typeAttributes().forms?.first()?.extraForms ?? {}).forEach(([k2, c]) => {
        forms[k2] = (mode: Modes) => Generator.Group(k as TYPE_NAMES, mode, this, c as any[]);
      });
    });
  }
  setSchemaEnums(self: UI_Schema) {
    Object.keys(schema.enums).map((k) => {
      const opts = Object.values(schema.enums[k].options).map((e, i) => {
        const field: ValueTree<fieldAttr<any, any>> = e.attributes.field?.first() ?? {};
        const { value, order } = e;
        return { ...field, value, order };
      });
      self[k] = JSON.parse(JSON.stringify(opts));
    });
  }

  makeCustomType<K extends string, V extends TYPE_NAMES, C extends readonly string[]>(k: K, v: V, c: C) {
    return { [k]: (mode: Modes) => Generator.Group(v, mode, this, c as any) }[k];
  }

  #title?: string;
  get title() { return this.#title; }
  set title(v) { this.#title = v; this.titleChange.emit(v); }
  titleChange = new EventEmitter<string | undefined>();

  #showSave?: boolean;
  get showSave() { return this.#showSave; }
  set showSave(v) { this.#showSave = v; this.showSaveChange.emit(v); }
  showSaveChange = new EventEmitter<boolean | undefined>();

  onSave = new EventEmitter<void>();
  onDialog = new EventEmitter<Dialog<any, any, any>>();


  enums = schema.enums;
  subs: Subscription = new Subscription(() => {
    this.onDialog.complete();
    this.onSave.complete();
    this.titleChange.complete();
    this.showSaveChange.complete();
  });
  group: QuestionGroup<any, any> | null = null;
  table!: UrlSegment;
  mode!: UrlSegment;
  id?: UrlSegment;



  makeDataListColumns<L extends Record<string, ListValue> | ListValue[]>(type: TYPE_NAMES, list: L, sort: SPPI[] = []) {
    return new TableViewClass(type, list, sort).makeDataListColumns();
  }

  makeBasicColumns(columns: { key: string, title?: string; text?: (e: any) => string; sort?: number; hidden?: boolean, readonly?: boolean, required?: boolean }[]) {

    return columns.map(({ key, title = "", text = e => e, sort = 0, hidden = false, readonly = false, required = false }) =>
      new SimpleKeyColumn(key, title, text, sort)
    );

  }

  QuestionBelongsToUnique<T extends TableType, H extends TableType>(
    group: QuestionGroup<any, FGCR>,
    mode: Modes,
    belongsTo: ValueTree<belongsTo<H>>,
    so: QuestionOptions<any, any>
  ) {
    const field = new QuestionSubGroup(group, so);
    field.getValue = (mode: Modes): {} | null | undefined => {
      const cleared = { createdAt: undefined, updatedAt: undefined, id: undefined }
      if (mode === "CREATE") return {
        create: { ...group.getValue(mode, this.data.userRole), ...cleared, } satisfies ValueTree<TableType>
      }
      if (mode === "UPDATE") return {
        update: { ...group.getValue(mode, this.data.userRole), ...cleared, } satisfies ValueTree<TableType>
      }
      return undefined;
    };

    return field;

  }
  QuestionHasOne<T extends TableType, H extends TableType>(
    group: QuestionGroup<any, FGCR>,
    mode: Modes,
    hasOne: ValueTree<hasOne>,
    so: QuestionOptions<any, any>
  ) {

    const [id] = hasOne.fields ?? [];
    if (id && group.controls[id]) {
      group.controls[id].hidden = true;
      group.controls[id].onlyfor = [];
    }

    const field = new QuestionSubGroup(group, so);
    field.getValue = (mode: Modes): {} | null | undefined => {
      const cleared = { createdAt: undefined, updatedAt: undefined, id: undefined };
      const data = group.getValue(mode, this.data.userRole);
      if (mode === "CREATE") return {
        create: { ...data, ...cleared } satisfies ValueTree<TableType>
      }
      // it seems ridiculous to always blindly upsert, but I guess that's the point of upsert
      if (mode === "UPDATE") return {
        upsert: {
          update: { ...data, ...cleared } satisfies ValueTree<TableType>,
          create: { ...data, ...cleared } satisfies ValueTree<TableType>,
        }
      }
      return undefined;
    };

    return field;

  }

  QuestionHasMany<T extends TYPE_NAMES, H extends TYPE_NAMES>(
    type: T,
    remote: undefined,
    prejointable: undefined,
    so: QuestionOptions<any, any>
  ): QuestionTable<any>;
  QuestionHasMany<T extends TABLE_NAMES, H extends TABLE_NAMES>(
    type: T,
    remote: MemberKeys<Root["types"][T]> | undefined,
    prejointable: ValueTree<prejoin<Root["types"][T], Root["types"][H]>> | undefined,
    so: QuestionOptions<any, any>
  ): QuestionTable<any>;
  QuestionHasMany<T extends TYPE_NAMES, H extends TYPE_NAMES>(
    type: T,
    remote: any,
    prejointable: any,
    so: QuestionOptions<any, any>
  ): QuestionTable<any> {
    type R = { id: string, _____groupsave_____?: any, _____groupdelete_____?: boolean };

    const prefilled: { current: any[] } = { current: [] };

    if (isTable(type) && !so.hidden) so.onlyfor = undefined;

    okNull(so.arrayList);

    const cols = prejointable && isTable(type)
      ? this.makePrejoinTableCols<any, any>(type, prejointable, so, prefilled)
      : this.makeDataListColumns(type, so.arrayList, so.arraySort)

    const idcol = new DataListIdFieldColumn("id");

    const table = new QuestionTable<UIService["row"]>({
      title: plural(type),
      ...so,
      rowType: type,
      cols,
      idcol,
      // height: "full",
      showAdd: !so.preventCreate && !prejointable,
      showEdit: !so.preventUpdate,
      // showDelete: !so.preventDelete,
      // showResize: true,
      // showSelection: false,
      showFilter: false,
      preventCreate: false,
      preventUpdate: false,
    });

    // if (!prejointable && QuestionHasManyEvent.contains(type)) {
    //   table.onCreate = so.preventCreate ? undefined : () => { this.handleRowEvent(false, "add", type) };
    //   table.onSelect = so.preventUpdate ? undefined : (row) => { this.handleRowEvent(row, "edit", type) };
    //   table.onDelete = so.preventDelete ? undefined : (row) => { this.handleRowEvent(row, "delete", type) };
    // } else {
    // this handles
    // - prejoin tables
    // - tables with remote fields
    // - record arrays and tables with no remote fields
    const group = (mode: Modes) => {
      if (isTable(type) && remote) {
        const group: any = this.schema[type](mode);
        group.controls[remote].onlyfor = [];
        group.controls[remote].required = false;
        group.controls[remote].hidden = true;
        group.controls[remote].form.disable();
        return group;
      } else {
        return this.schema[type](mode);
      }
    };

    const list = new RowEditAccessor(idcol, this.data.userRole, group, table.setState, !so.preventDelete);

    table.onCreate = (so.preventCreate || prejointable) ? undefined :
      () => { this.handleRowPopup(false, "add", type, list) };
    table.onSelect = so.preventUpdate ? undefined :
      (row) => { this.handleRowPopup(row, "edit", type, list) };
    // table.onDelete = so.preventDelete ? undefined :
    //   (row) => { this.handleRowPopup(row, "delete", type, list) };

    if (isTable(type)) {

      table.getValue = (mode: Modes) => {
        if (mode !== "UPDATE" && mode !== "CREATE") return {};

        const [other, deletes] = table.rows
          .partition(e => e.__groupdelete__)

        const [creates, updates] = other
          // just get rows that were deliberately updated
          .filter(e => e.__groupsave__)
          // we have to use Object.keys since setID creates a non-enumerable id field
          .partition(e => Object.keys(e).contains("id"));

        console.log(creates, updates, deletes);

        return {
          create: !table.preventCreate && creates.length
            ? creates.map(e => e.__groupsave__)
            : undefined,
          update: !table.preventUpdate && updates.length
            ? updates.map((e) => ({ data: e.__groupsave__, where: { id: e.id } }))
            : undefined,
          delete: !table.preventDelete && deletes.length
            ? deletes.map(e => ({ id: e.id }))
            : undefined,
        };

      };

      table.onlyfor = ([...so.onlyfor ?? ["CREATE", "UPDATE"] as const]).filter(e => {
        if (e === "CREATE" && so.preventCreate) return false;
        if (e === "UPDATE" && so.preventUpdate) return false;
        return true;
      });

    }



    if (prejointable) {
      const innerSetState = table.setState;

      table.setState = (action) => {

        switch (action.action) {
          case "bump":
          case "setRow":
            return innerSetState(action);
          case "deleteRow": {
            const { rows } = table.TableRowRedux(table, action);
            checkPrefilled(rows);
            innerSetState({ action: "bump" });
          } break;
          case "reset":
            action.newValue = action.newValue ?? [];
            checkPrefilled(action.newValue);
            innerSetState(action);
            break;

        }
      }
    }

    return table;

    function checkPrefilled(newrows: any[]) {
      const idhash = {} as any;

      newrows.forEach((row: any) => {
        idhash[cols[0].get(row)] = row;
      });

      prefilled.current.forEach(row => {
        if (!idhash[cols[0].get(row)]) {
          const cloned = JSON.parse(JSON.stringify(row));
          // (row.__tone__ as IndexTableRowProps["tone"]) = "warning";
          Object.defineProperty(cloned, "__tone__", {
            value: "warning",
            writable: false,
            enumerable: false,
            configurable: true
          });
          Object.defineProperty(cloned, "__prefilled__", {
            value: true,
            writable: false,
            enumerable: false,
            configurable: true
          });
          newrows.push(cloned);
        }
      });
    }


  }

  private makePrejoinTableCols<T extends TableType, H extends TableType>(
    type: TABLE_NAMES,
    prejointable: {
      childField?: MemberKeys<T> | undefined;
      otherField?: MemberKeys<T> | undefined;
      prefill?: boolean | undefined;
    },
    so: QuestionOptions<any, any>,
    prefilled: { current: any[] }
  ) {
    const {
      otherBelongsTo, childBelongsTo, otherTable, otherList, arrayList, arraySort, childType, childField, otherField,
    } = this.getPrefilledVars<T, H>(type, prejointable, so);

    const list = [otherField + "/id" as SPPI, ...otherList.map(e => join(otherBelongsTo, e) as SPPI), ...arrayList];
    const cols = this.makeDataListColumns(type, list, arraySort);
    cols[0].hidden = true;
    cols[0].readonly = true;

    cols.forEach((e, i) => { if (i < otherList.length + 1) { e.readonly = true; } });

    so.extraGetPaths = so.extraGetPaths || [];
    so.extraGetPaths.push(childField as SPPI, otherField as SPPI);



    const { onLoadHook } = so;
    so.onLoadHook = async (tag) => {

      onLoadHook && onLoadHook(tag);

      const res = await tag.addPromise(new ProxyPromise({
        action: "findMany",
        table: otherTable.name,
        arg: { select: this.data.selectPaths(otherTable.name, ["id" as SPPI, ...otherList], false) }
      }));
      // since this is definitely on a relation field rather 
      // than a scalar, we can just use the connect nested query
      prefilled.current = [...res.map((e: any) => pathmap({
        [otherBelongsTo]: e,
        [childBelongsTo]: { id: tag.id },
      }, childType, arrayList, (item) => {
        const def = item.__fieldAttributes().field?.first()?.default;
        return def ? JSON.parse(def) : undefined;
      }))];

      // tree.value = tree.value;
    };
    return cols;
  }

  private handleRowPopup = async (row: any, action: "add" | "edit" | "delete", rowType: TYPE_NAMES, list: RowEditAccessor) => {

    console.log(row, action, rowType);
    if (LedgerTables.contains(rowType)) {
      await this.fq.onSelectLedgerRow(row, false);
    } else if (QuestionHasManyEvent.contains(rowType)) {
      if (schema.tables[rowType]) {
        switch (action) {
          case "add":
            this.fq.onClickEvent({ action, table: rowType, params: {} }); break;
          case "edit":
          case "delete":
            this.fq.onClickEvent({ action, table: rowType, id: row.id }); break;
        }
      }
    } else {
      if (action === "add" || action === "edit") {
        await this.showRowEditDialog(action, rowType, row, list);
      } else if (action === "delete") {
        if (!schema.tables[rowType]) {
          await this.fq.deleteItemConfirmation(async () => { list.deleteRow(row); });
        } else {
          this.fq.onClickEvent({ action: "delete", table: rowType, id: row.id });
        }
      }
    }
  }

  declare row: {
    id?: string;
    __prefilled__?: boolean;
    __tone__?: RowProps["tone"]
    __fresh__?: any;
    __formvalue__?: any;
    __groupsave__?: any;
    __groupdelete__?: boolean;
  };
  async showRowEditDialog(
    action: "add" | "edit",
    rowType: TYPE_NAMES,
    row: UIService["row"],
    list: RowEditAccessor
  ) {
    const { data, fq } = this;

    if (!row) row = {};

    const opts: { title: string; mode: "CREATE" | "UPDATE"; } = { title: "", mode: "CREATE" };
    switch (action) {
      case "add":
        opts.title = "Add";
        opts.mode = "CREATE";
        break;
      case "edit":
        opts.title = "Edit";
        opts.mode = "UPDATE";
        break;
    }

    list.onRowEditInit(row);
    console.log(list, row, list.getGroup(row));

    const dialog = new GroupDialog<TYPE_NAMES, any, QuestionGroup<TYPE_NAMES, FGCR>>(
      data,
      rowType,
      opts.mode,
      () => list.getGroup(row),
      async () => {
        if (list.hasGroup(row))
          list.onRowEditCancel(row); dialog.subs.unsubscribe();
      },
      async function () {
        if (!this.group)
          return false;
        const data = new DataQueryGraph(this.table, row.id, this.data.userRole);
        this.group.onLoadHook(data);

        if (row.__formvalue__) {

          await this.data.dataGraphQuery(data, "requests");
          dialog.group?.form.patchValue(row.__formvalue__);

        } else if (schema.tables[rowType] && row.id && action === "edit" && !row.__prefilled__) {

          const fresh = await this.data.dataGraphQuery(data, "requests", this.data.queryFormData(this.table as TABLE_NAMES, row.id, this.group));
          Object.defineProperty(row, "_____fresh_____", { configurable: true, enumerable: false, writable: false, value: fresh, });
          this.group?.form.patchValue(fresh as any);

        } else {

          await this.data.dataGraphQuery(data, "requests");
          if (row) dialog.group?.form.patchValue(row);

        }

        return true;
      },
      async () => { },
      async (): Promise<boolean> => {
        if (list.allowDelete) {

          dialog.onClose();

          const deleting = !row.__groupdelete__;
          const edited = !!row.__groupsave__;

          Object.defineProperty(row, "__groupdelete__", {
            configurable: true,
            enumerable: false,
            writable: false,
            value: deleting,
          });

          Object.defineProperty(row, "__tone__", {
            configurable: true,
            enumerable: false,
            writable: false,
            value: deleting ? "critical" : edited ? "success" : undefined,
          });

          list.setState({ action: "bump" });

          return true;
        }
        return false;
      }
    );
    dialog.onClickSave = async (): Promise<void> => {

      const group = list.getGroup(row);

      const { value, formValue } = group.getValueAndValidity(row.__fresh__ ? "UPDATE" : "CREATE", list.userRole);

      if (!value) return;

      dialog.onClose();

      list._editGroups.delete(row);

      Object.assign(row, formValue as any);

      Object.defineProperty(row, "__formvalue__", {
        configurable: true,
        enumerable: false,
        writable: false,
        value: formValue,
      });

      Object.defineProperty(row, "__groupsave__", {
        configurable: true,
        enumerable: false,
        writable: false,
        value,
      });

      Object.defineProperty(row, "__tone__", {
        configurable: true,
        enumerable: false,
        writable: false,
        value: "success",
      });

      list.setState({ action: "addRowIfNew", row });

    };
    this.onDialog.emit(dialog);
    dialog.showDelete = list.allowDelete;
    dialog.deleteLabel = row.__groupdelete__ ? "Restore" : "Delete";
    await dialog.pageSetup(true);
  }



  QuestionRecordArray<R extends { id: string }>(type: TYPE_NAMES, so: QuestionOptions<any, any>) {
    return this.QuestionHasMany(type, undefined, undefined, so);
  }

  private getPrefilledVars<T extends TableType, H extends TableType>(
    type: TABLE_NAMES,
    prejointable: ValueTree<prejoin<T, H>>,
    so: QuestionOptions<any, any>
  ) {

    okNull(so.arrayList);
    const { arrayList, arraySort } = so;
    const childType = root.types[type];
    okNull(prejointable); okNull(prejointable.childField); okNull(prejointable.otherField);
    const { childField, otherField } = prejointable;

    const child = schema.tables[type as TABLE_NAMES].fields[childField]; okNull(child);
    const childAttrField = child.attributes.field?.first(); okNull(childAttrField);
    okNull(child.attributes.belongsTo?.first()?.isRelation);
    const childTable = schema.tables[child.name]; okNull(childTable);
    const childList = childAttrField.arrayList || childAttrField.arrayList; okNull(childList);
    const childBelongsTo = child.key as SPPI;

    const other = schema.tables[type as TABLE_NAMES].fields[otherField]; okNull(other);
    const otherAttrField = other.attributes.field?.first(); okNull(otherAttrField);
    okNull(other.attributes.belongsTo?.first()?.isRelation);
    const otherTable = schema.tables[other.name]; okNull(otherTable);
    const otherList = otherAttrField.arrayList?.map(arrayListKeys); okNull(otherList);
    const otherBelongsTo = other.key as SPPI;

    return {
      otherBelongsTo,
      childBelongsTo,
      otherTable,
      otherList,
      arrayList: arrayList.map(arrayListKeys),
      arraySort,
      childType,
      childField,
      otherField,
    }
  }

  private getValueHasMany(table: QuestionTable<any>) {
    return (mode: Modes) => {
      if (mode !== "UPDATE" && mode !== "CREATE") return table.value;

      okNull(table.value);

      const [creates, updates] = table.rows
        .filter(e => e._____groupsave_____)
        .map(e => ({ id: e.id, data: e._____groupsave_____ }))
        .partition(e => !!e.id);

      if (!creates.length && !updates.length) return undefined;

      return {
        create: !table.preventCreate && creates.length ? creates.map(e => e.data) : undefined,
        update: !table.preventUpdate && updates.length ? updates.map(({ data, id }) => ({ data, where: { id } })) : undefined,
      };

    };
  }

  select<T extends TableType, H extends RecordType>(mode: Modes, lookup: ValueTree<lookup<T, H>>, select?: ValueTree<select<T, H>>) {
    const sync = new SyncCheck();
    const self = this;
    return (so: QuestionOptions<any, any>) => {
      sync.done();
      if (!lookup || !so.arrayList || !is<TABLE_NAMES>(lookup.targetTable, true))
        throw new Error("Couldn't get query name or option label");

      if (so.hidden) { return new QuestionSimple("Hidden", so) }

      const optionList = so.arrayList.map(arrayListKeys);

      const { hostTable, relation } = select?.forReadonly ?? {};

      if (so.preventUpdate && mode === "UPDATE") {
        if (hostTable && relation)
          return new QuestionSelectLabel({
            // extraGetPaths is relative to this field
            extraGetPaths: optionList.map(e => join("..", relation, e.toString()) as SPPI),
            // optionLabels is relative to the parent group to simplify path access
            optionLabels: this.makeDataListColumns(hostTable as TYPE_NAMES, optionList.map(e => join(relation, e) as SPPI), []),
          })
        else if (so.clientSideOnly)
          return new QuestionSimple("Hidden", so);
      }

      if (so.preventCreate && mode === "CREATE") {
        // even if this is a required field, the value will be handled elsewhere
        return new QuestionSimple("Hidden", so);
      }

      return this.lookupField<T, H>(mode, lookup, so);

    }

    function optionTitle(e: string): string {
      return schema.getpath(lookup.targetTable as string, e).attributes.field?.first()?.title ?? e;
    }
  }


  lookupField<T extends TableType, H extends RecordType>(
    mode: Modes,
    lookup: ValueTree<lookup<T, H>>,
    so: QuestionOptions<any, any>,
  ) {
    if (!lookup || !so.arrayList || !is<TABLE_NAMES>(lookup.targetTable, true))
      throw new Error("Couldn't get query name or option label");

    const filters = !lookup.optionFilterWith ? []
      : lookup.optionFilterWith?.filter(e => e && (!e.onlyfor || e.onlyfor.indexOf(mode) !== -1));

    const { targetTable, onRelation } = lookup;
    const { arrayList, arraySort } = so;
    const self = this;
    const currentWith: string[] = new Array(filters.length);
    const selectPaths = this.data.selectPaths(targetTable, ["id" as SPPI, ...arrayList.map(arrayListKeys)], false);

    const firstCallTag = Symbol("first call tag");
    const dataLoaded = new ReplaySubject(1);

    const field: QuestionSelect<Record<string, any>, MemberKeys<T>> = new QuestionSelect({
      ...so,
      optionLabels: this.makeDataListColumns(targetTable, arrayList, []),
      optionValue: lookup.optionValue ?? "id" as MemberKeys<T>,
      placeholder: " ",
      onRelation,
      options: dataLoaded.asObservable().pipe(
        tap((e): any => {
          let disabled = false, changed = false, reset = false;
          field.updateList();
          if (!field.parent)
            return;

          for (let i = 0; i < filters.length; i++) {
            const { filterWith, filterThis } = filters[i];
            if (filterWith === undefined)
              continue;
            const value = field.parent.getPathValue(filterWith.split("/"));

            if (value === null)
              disabled = true;

            if (value !== currentWith[i])
              changed = true;

            currentWith[i] = value;
          }

          field.readonly = disabled || so.readonly;

          if (disabled)
            console.log("select disabled");

          if (reset)
            field.form.setValue(null);

        })
      ) as Observable<any>,
      onLoadHook: async (tag) => {
        if (so.rlsRestrict && tag.role === "web_user"
          // || so.allowedScope && tag.role && !isPrivScope(tag.role, so.allowedScope)
          // || so.allowedLevel && tag.role && !isPrivLevel(tag.role, so.allowedLevel)
        ) { field.hidden = true; return; }

        if (so.clientSideLoad) {
          const value = await tag.addPromise(field.handleClientSideLoadHook(tag));
          dataLoaded.next(so.clientSidePath ? getValueByPath(value, so.clientSidePath.split("/")) : value);
          return;
        }

        field.subs.add(filters
          .map(f => f.filterWith && field.parent?.getPath(f.filterWith.split("/"), true)[0].form.valueChanges)
          .filter(truthy)
          .lift(o => from(o))
          .pipe(
            mergeAll(),
            tap(e => { dataLoaded.next(null); }),
            debounceTime(500),
            startWith(firstCallTag),
            concatMap(async (state) => {
              const filter: unknown[] = filters.map((e): any => {
                if (!e) return;
                const belongsTo = schema.tables[targetTable].fields[e.filterThis].attributes.belongsTo?.first();

                if (e.filterWith) {
                  const val = field.parent?.getPathValue(e.filterWith.split("/"));
                  if (val) {
                    if (belongsTo?.isRelation) {
                      return { [e.filterThis]: { id: { equals: val } } };
                    } else {
                      return { [belongsTo?.root ?? e.filterThis]: { equals: val } };
                    }
                  }
                }
              }).filter(truthy);
              if (lookup.optionFilterWhere?.length)
                filter.push(...lookup.optionFilterWhere)


              const arg = {} as any;

              arg.select = selectPaths;
              arg.where = arg.where ?? {};
              arg.orderBy = arraySort?.map(PrismaQuery.orderByPath) ?? [];

              if (arg.where.AND)
                arg.where.AND.push(...filter);

              else
                arg.where.AND = filter;

              const req = new ProxyPromise({ action: "findMany", table: targetTable, arg });

              if (state === firstCallTag) {
                return tag.addPromise(req);
              } else {
                return self.data.singleDataQuery(req);
              }
            })
          ).subscribe(e => { dataLoaded.next(e); }));

      },
    });
    if (lookup.onRelation && mode === "UPDATE")
      field.mapGetValue = (val: any) => {
        return val === undefined ? val : val === null ? { disconnect: true } : { connect: { id: val.id } };
      };
    else if (lookup.onRelation && mode === "CREATE")
      field.mapGetValue = (val: any) => {
        return !val ? undefined : { connect: { id: val.id } };
      };
    return field;
  }


  lookupTable<T extends TableType, H extends RecordType>(
    mode: Modes,
    lookup: ValueTree<lookup<T, H>>,
    so: QuestionOptions<any, any>,
  ) {
    if (!lookup || !so.arrayList || !is<TABLE_NAMES>(lookup.targetTable, true))
      throw new Error("Couldn't get query name or option label");

    const filters = !lookup.optionFilterWith ? []
      : lookup.optionFilterWith?.filter(e => e && (!e.onlyfor || e.onlyfor.indexOf(mode) !== -1));

    const { targetTable, onRelation } = lookup;
    const { arrayList, arraySort } = so;
    const self = this;
    const currentWith: string[] = new Array(filters.length);
    const selectPaths = this.data.selectPaths(targetTable, ["id" as SPPI, ...arrayList.map(arrayListKeys)], false);

    const firstCallTag = Symbol("first call tag");
    const dataLoaded = new ReplaySubject(1);

    ok(so.arrayList);

    const type = lookup.targetTable;

    const cols = this.makeDataListColumns(type, so.arrayList, so.arraySort)

    const idcol = new DataListIdFieldColumn("id");

    const table = new QuestionTable<UIService["row"]>({
      ...so,
      rowType: type,
      cols,
      idcol,
      showAdd: !so.preventCreate,
      showEdit: !so.preventUpdate,
      // showDelete: !so.preventDelete,
      // showResize: true,
      // showSelection: false,
      showFilter: false,
      preventCreate: true,
      preventUpdate: true,
      title: so.title || plural(type),
      //setting this to true gets us what we need, but we have to set onloadhook after the constructor
      clientSideLoad: true,
    });
    table.emptySize = "small";
    table.onLoadHook = async (tag) => {

      if (so.rlsRestrict && tag.role === "web_user") {
        table.hidden = true;
        return;
      }

      if (so.clientSideLoad) {
        const value = await tag.addPromise(table.handleClientSideLoadHook(tag));
        dataLoaded.next(so.clientSidePath ? getValueByPath(value, so.clientSidePath.split("/")) : value);
        return;
      }

      table.subs.add(filters
        .map(f => f.filterWith && table.parent?.getPath(f.filterWith.split("/"), true)[0].form.valueChanges)
        .filter(truthy)
        .lift(o => from(o))
        .pipe(
          mergeAll(),
          tap(e => {
            dataLoaded.next(null);
            table.loading = true;
          }),
          debounceTime(500),
          startWith(firstCallTag),
          concatMap(async (state) => {
            const filter: unknown[] = filters.map((e): any => {
              if (!e) return;
              const belongsTo = schema.tables[targetTable].fields[e.filterThis].attributes.belongsTo?.first();

              if (e.filterWith) {
                const val = table.parent?.getPathValue(e.filterWith.split("/"));
                if (val) {
                  if (belongsTo?.isRelation) {
                    return { [e.filterThis]: { id: { equals: val } } };
                  } else {
                    return { [belongsTo?.root ?? e.filterThis]: { equals: val } };
                  }
                }
              }
            }).filter(truthy);

            if (lookup.optionFilterWhere?.length)
              filter.push(...lookup.optionFilterWhere)


            const arg = {} as any;

            arg.select = selectPaths;
            arg.where = arg.where ?? {};
            arg.orderBy = arraySort?.map(PrismaQuery.orderByPath) ?? [];

            if (arg.where.AND)
              arg.where.AND.push(...filter);

            else
              arg.where.AND = filter;

            const req = new ProxyPromise({ action: "findMany", table: targetTable, arg });

            if (state === firstCallTag) {
              return tag.addPromise(req);
            } else {
              return self.data.singleDataQuery(req);
            }
          })
        ).subscribe(e => {
          dataLoaded.next(e);
          table.loading = false;
        }));

    };

    table.subs.add(dataLoaded.asObservable().subscribe((e): any => {
      let disabled = false, changed = false, reset = false;

      table.setState({ action: "reset", newValue: e as any });

      if (!table.parent) return;

      for (let i = 0; i < filters.length; i++) {
        const { filterWith, filterThis } = filters[i];

        if (filterWith === undefined) continue;

        const value = table.parent.getPathValue(filterWith.split("/"));

        if (value === null) disabled = true;

        if (value !== currentWith[i]) changed = true;

        currentWith[i] = value;

      }

      table.readonly = disabled || so.readonly;

      if (disabled) console.log("select disabled");

      // if (reset) table.form.setValue(null);

    }));

    table.mapGetValue = (val: any) => { return undefined };

    return table;
  }

  makeTableStub<K extends string>(item: FieldClass<TYPE_NAMES>, key: K, mode: MemberKeys<ConfirmType>) {
    let title = item.attributes.field?.first()?.title || item.key;
    title += " Table Stub";
    return new QuestionSubGroup(new QuestionGroup({
      collapsible: true,
      controls: {} as FGC<never>,
      __typename: item.name
    }), {
      order: item.order,
      title: title
    });
  }

  QuestionEmailDoc(mode: Modes, so: QuestionOptions<any, any>) {
    const editor = new QuestionTinyMCE({
      preset: "Email",
      hidden: true,
      ...so,
      onChange(val) {
        if (!val.NoticeType) return;
        const noticetagsEmailBody = schema.anyModel("NoticeTemplate").fields["EmailBody"].attributes.noticeTags ?? []
        const noticetags = noticetagsEmailBody
          .filter(e => e.NoticeType === val.NoticeType)
          .flatMap((e, i) => e?.tags ?? []);
        // console.log(val, noticetagsEmailBody, noticetags);
        editor.updateTags(noticetags);
        editor.helptext = [noticetags.length ? "Available Tags: " + noticetags.join(", ") + "." : "", so.helptext].filter(truthy).join(" ");
        editor.hidden = false;
      },
    });
    return editor;
  }

  QuestionNotificationPreferences(mode: Modes, so: QuestionOptions<any, any>) {

    const { fields } = schema.anyModel("NotificationPreferences");
    const { options } = schema.enums.NoticeTypes;
    const rowValues = Object.values(options);
    const field = new QuestionCheckboxGrid({
      mode: "column",
      rowLabels: rowValues.map(({ value, label }) => ({
        value,
        label: label ?? value
      })),
      columnLabels: Object.values(fields).map(e => ({
        value: e.key,
        label: e.attributes.field?.first()?.title || e.key,
        get selection(): boolean | null {
          if (field.value[e.key].length === rowValues.length) return true;
          if (field.value[e.key].length === 0) return false;
          return null;
        },
        set selection(v: boolean | null) {
          if (v === null) v = true;
          field.value[e.key] = v ? rowValues.map(e => e.value) : [];
        }
      })),
      ...so,
    });
    return field;
  }
  async QuestionGeocodeAddress_request(input: string): Promise<{ description: string | undefined }[]> {
    const { credentials } = await fetchAuthSession();
    const res = await new Location({
      region: "us-east-2",
      credentials,
    }).searchPlaceIndexForSuggestions({
      IndexName: "CustomerLocationLookup",
      FilterCountries: ["USA"],
      Text: input,
    });
    return res.Results?.map(e => ({
      description: e.Text,
    })) ?? [];
  }
  QuestionGeocodeAddress(mode: Modes, forceSelection: boolean, so: QuestionOptions<any, any>) {
    const self = this;

    ok(forceSelection, "the current implementation only supports forced selection, I just didn't feel like removing the option, so it must be true");
    return new QuestionAutoComplete<ValueTree<GeocodeAddress> | string, ValueTree<GeocodeAddress>>({
      ...so,
      forceSelection,
      mapIn: (e) => typeof e === "string" ? { description: e } : e,
      mapOut: (e) => e,
      mapInput: async (input: string) => {
        return await this.QuestionGeocodeAddress_request(input);
      },
      optionLabel: "description",
      optionValue: "description",
    });
  }

  QuestionEnum(
    mode: Modes,
    multiple: boolean,
    options: QuestionEnumOption[],
    so: Partial<QuestionSelectOptions<any, any>>
  ) {
    if (mode === "UPDATE" && so.unique)
      return new QuestionSimple("InputLabel", {});
    else
      return this.QuestionEnumSelect(mode, multiple, options, so);
  }

  QuestionEnumSelect(
    mode: Modes,
    multiple: boolean,
    options: QuestionEnumOption[],
    so: Partial<QuestionSelectOptions<any, any>>

  ) {
    return new QuestionSelect({
      display: "buttons",
      multiple,
      showToggleAll: true,
      optionLabels: [new SelectEnumColumn(options)],
      optionValue: "value",
      placeholder: " ",
      options: Promise.resolve(options),
      ...so as any
    });
  }

  QuestionSelectDropdown2D(titles: string[], values: string[][], so: Partial<QuestionSelectOptions<any, any>>) {
    ColumnBase
    const optionLabels: QuestionSelectOptions<any, any>["optionLabels"]
      = titles.map((title, i) => new ArrayColumn(i, title, e => e, 0));

    return new QuestionSelect({
      display: "dropdown",
      multiple: false,
      showToggleAll: true,
      optionLabels,
      optionValue: "0",
      placeholder: " ",
      options: Promise.resolve(values),
      ...so as any
    });
  }


  tableControls(mode: Modes, includeExtra: boolean) {
    return {
      // id does not get updated and the where clause is applied separately
      id: new QuestionSimple("Hidden", { onlyfor: [] }),
      createdAt: new QuestionSimple("Hidden", { onlyfor: [] }),
      updatedAt: new QuestionSimple("Hidden", { onlyfor: [] }),
      ...includeExtra ? { extra: new QuestionSimple("Hidden", { useDBNull: true }) } : {}
    }
  }

  getRentalColumns() {
    return this.makeDataListColumns("Rental", SPP<"Rental">()(x => ({
      ActiveUnitRental: x.activeUnit.currentRental.id.__,
      BillingStatus: x.customer.BillingStatus.__,
      Created: x.createdAt.__,
      CustomerBillingDay: x.customer.BillingDay.__,
      CustomerType: x.customer.CustomerType.__,
      EndDate: x.EndDate.__,
      id: x.id.__,
      Markup: x.unit.currentBranchMarkup.Markup.__,
      Promotion: x.promotion.Title.__,
      promotionID: x.promotion.id.__,
      RentalPrice: x.unit.unitType.RentalPrice.__,
      RentalStatus: x.RentalStatus.__,
      StartDate: x.StartDate.__,
      UnitName: x.unit.Name.__,
      UnitType: x.unit.unitType.Name.__,
      unitID: x.unit.id.__,
      IsRentToOwn: x.IsRentToOwn.__,
      branchID: x.unit.currentBranch.id.__,
      ownerID: x.unit.currentOwner.id.__,
    })), []);
  }

  getCustomerInfoColumns() {

    return this.makeDataListColumns("Customer", SPP<"Customer">()((_) => ({
      id: _.id.__,
      Email: _.Email.__,
      AWSID: _.AWSID.__,
      AutoPay: _.AutoPay.__,
      EmailVerified: _.EmailVerified.__,
      CustomerType: _.CustomerType.__,
      BillingDay: _.BillingDay.__,
      Name: _.billing.Name.__,
      Address: _.billing.Address.description.__,
      Phone: _.billing.Phone.__,
      Notes: _.billing.Notes.__,
      LateFeeExempt: _.billing.LateFeeExempt.__,
      TaxExempt: _.billing.TaxExempt.__,
      StorageAgreementCompleted: _.StorageAgreementCompleted.__,
      StorageAgreementStatus: _.StorageAgreementStatus.__,
      PaymentInfoValid: _.PaymentInfoValid.__,
      IS_TESTING: _.IS_TESTING.__,
    })), []);
  }


}


/**
 * @param rootObject object to set the paths on
 * @param rootType type at the root of the paths
 * @param arrayList list of paths to set
 * @param tap function called to set the final value
 * @returns the resulting object
 */
export function pathmap(
  rootObject: any,
  rootType: AnyMember<any, any>,
  arrayList: SPPI[],
  map: (item: AnyMember<any, any>, keys: string[]) => void
) {
  arrayList.forEach((e) => {
    let end = rootObject, last: any = rootType;
    const keys: string[] = e.split('/');
    let i;
    for (i = 0; i < keys.length; i++) {
      if (!last[keys[i]]) okNull(null);
      last = last[keys[i]];
      if (i === keys.length - 1) {
        end[keys[i]] = map(last, keys);
      } else if (!end[keys[i]]) {
        end[keys[i]] = {};
      }
      end = end[keys[i]];
    }
  });
  return rootObject
}

