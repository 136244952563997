// "x-cubes-branch-token" is the branch user id-token and is returned by getAuthToken if it exists
// "x-cubes-as-customer" is the customer table id and reconfigures everything else
{
  const [page, hash] = location.href.split("#");
  hash?.split("&")
    .map((pair) => pair.split("=").map(decodeURIComponent))
    .forEach(([key, value]) => { sessionStorage.setItem(key, value); });
  // reload the page to make sure everything gets the change properly
  if (hash) location.href = page;
}
export const asCustomer = sessionStorage.getItem("x-cubes-as-customer") ?? "";