
import { asCustomer } from './flags';
import { Fragment, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import { Authenticator } from '@aws-amplify/ui-react';
import { NgContextProvider, rootInjector } from 'react-utils';

const AuthProviderOrAsCustomer = asCustomer ? Fragment : Authenticator.Provider;

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <AuthProviderOrAsCustomer>
      <NgContextProvider injector={rootInjector}>
        <App />
      </NgContextProvider>
    </AuthProviderOrAsCustomer>
  </StrictMode>,
)
