import { TABLE_NAMES, ModelClass, TypeTreeWithID, FieldClass } from "./cubes-utils";
import { truthy } from "./graphql-declarator";
import { schema } from "./cubes-index";
import { Root } from "./cubes-index";


type Levels = {
  OR?: (Levels)[];
} | {
  [key: string]: string | boolean | QueryJoinerValue;
};



export function createJoinQueryTest() {
  const table = "BranchBillingInfo";
  const PermissionName = (level: "table" | "groups" | "rows") => `${"write"} in ${table} for ${level} of ${"Branch"}`;
  const root = `FROM public."UserPermission" userperm0`;
  console.log(new QueryJoiner({ userperm: "UserPermission", table }, { userperm: "userperm0" }, root)
    .build(({ userperm, table }) => ({
      [userperm.userID.__]: `'577ec71b-280c-4cfc-8a80-996439156fd7'`,
      [userperm.perm.name.__]: `'${PermissionName("groups")}'`,
      // [table.branch.Groups.groupID.__]: new QueryJoinerValue(userperm.value.__, (val) => `ANY(${val}::UUID[])`),
      [table.branch.division.Branches.Users.userID.__]: `'577ec71b-280c-4cfc-8a80-996439156fd7'`,
      // [userperm.userID.__]: `current_setting('rls_security.user_id')::UUID`,
      // [userperm.perm.name.__]: `'${PermissionName("groups")}'`,
      // [tableRLS.groups]: `ANY(${PERMISSION_VALUE})`,
    }), [])
    .format()
  );

  // SELECT 
  //   public."BranchBillingInfo"."id", 
  //   public."Branch"."id", 
  //   public."User"."name"
  // FROM public."BranchBillingInfo"
  // INNER JOIN public."Branch" a
  // ON a."id" = public."BranchBillingInfo"."branchID"
  // INNER JOIN public."Branch" b
  // ON a."divisionID" = b."divisionID"
  // INNER JOIN public."BranchUser"
  // ON public."BranchUser"."branchID" = b."id"
  // AND public."BranchUser"."userID" = '577ec71b-280c-4cfc-8a80-996439156fd7'
}




interface QueryJoinLevel {
  parent: ModelClass<TABLE_NAMES>;
  fieldName: string;
  field: FieldClass<any>;
  sliced: boolean;
  level: number;
  childTree: Record<string, QueryJoinLevel>;
}

export class QueryJoiner<T extends Record<string, TABLE_NAMES>> {
  startKeys: Record<string, string>;

  constructor(
    public start: T,
    public vars: { [K in keyof T]?: string } = Object.fromEntries(Object.keys(start).map(e => [e, e + "0"] as const)) as any,
    /** 
     * The from clause. It needs to declare each key in start as `public."${start[k]}" ${k}0`. 
     * It has a default which will declare them ON TRUE and you can use the whereQuery to filter it. 
     * The query engine should take care of optimizing that.
     */
    public roots = `FROM ${Object.entries(start).map(([k, v], i) => `public."${v}" ${vars[k] ?? ""} ${i ? "ON TRUE" : ""}`).join("\nINNER JOIN ")}`,
  ) {
    Object.keys(start).forEach(e => { this.tablesTree[e] = {}; });
    this.startKeys = Object.fromEntries(Object.entries(start).map(([k, v]) => [v, k]));

  }



  public joins: Array<string> = [];

  join(str: string) {
    this.joins.push(str);
  }


  keys: string[] = [];
  keyval: Record<string, string | QueryJoinerValue> = {};
  where: string[] = [];

  build(
    // table: T,
    // func: (tables: any) => Record<string, string | QueryJoinerValue | boolean>,
    whereQuery: (
      e: { [K in keyof T]: TypeTreeWithID<Root["types"][T[K]], string>; }
      // e: any
    ) => Record<string, string | QueryJoinerValue | boolean>,
    /** Extra keys in format `startKey:path` */
    extraKeys: string[] = []
  ): this {
    const self = this;
    const keyval = whereQuery(Object.keys(this.start).reduce((n, k) => {

      n[k] = GenericPathMapProxy((e) => {
        const key = `${k}:${e}`;
        self.keys.push(key);
        self.parseKey(key);
        return key;
      });
      return n;
    }, {} as any));

    extraKeys.forEach(key => {
      self.keys.push(key);
      self.parseKey(key);
    });

    Object.keys(this.tablesTree).forEach(e => {
      // this.parseTreeInner(e, this.tablesTree[e], `public."${this.start[e]}"`);
      this.parseTreeInner(e, this.tablesTree[e], this.vars[e] ?? `public."${this.start[e]}"`);
    });

    const getKey = (k: string) => {
      const short = k.split(":")[0];
      const stack = this.fieldsMap.get(k) as (QueryJoinLevel & { joinName: string })[];
      if (!stack) {
        console.log(this.fieldsMap.keys());
        throw new Error(`invalid key ${k} for ${this.start[short]}`);
      }
      const joinName = stack.length > 1
        ? stack[stack.length - 2].joinName
        : this.vars[short] ?? `public."${this.start[short]}"`;

      if (!joinName) throw new Error("missing join name " + k);
      const { fieldName, sliced } = stack?.last() ?? {};
      if (!fieldName) throw new Error("missing field name " + k);
      // return { short, stack, joinName, fieldName, sliced };
      return `${joinName}."${fieldName + (sliced ? "ID" : "")}"`;
    }

    this.where = Object.entries(keyval).map(([k, v]) => {
      if (v === true) return "TRUE";
      if (v === false) throw new Error("false not supported");
      const key = getKey(k);
      const val = v instanceof QueryJoinerValue ? v.val(getKey(v.key)) : v;
      return `${key} = ${val}`
    });

    return this;

  }

  format() {
    return [
      this.roots,
      `INNER JOIN ${this.joins.join("\nINNER JOIN ")}`,
      `WHERE ${this.where.join("\n  AND ")}`,
    ].join("\n");
  }

  tablesTree: Record<string, Record<string, QueryJoinLevel>> = {} as any;
  fieldsMap: Map<string, QueryJoinLevel[]> = new Map();

  parseKey(key: string) {
    const shortcut = key.split(":")[0];
    const start = this.start[shortcut];
    if (!is<TABLE_NAMES>(start, !!schema.tables[start]))
      throw new Error("invalid key " + key);

    const path = key.split(":").slice(1).join(":");
    const arr = path.split("/");
    let parent = schema.tables[start];
    let fieldsTree = this.tablesTree[shortcut];
    this.fieldsMap.set(key, arr.map((e, i) => {
      const sliced = isRelationID(parent, e);
      if (sliced) e = e.slice(0, -2);
      const field = parent.fields[e];
      if (!field) throw new Error("invalid key " + key);

      if (fieldsTree[e]) {
        if (fieldsTree[e].field !== field) throw new Error("field mismatch for key " + key);
      } else {
        fieldsTree[e] = { parent, fieldName: e, field, level: i + 1, sliced, childTree: {} };
      }
      const res = fieldsTree[e];
      fieldsTree = fieldsTree[e].childTree;
      parent = schema.tables[parent.fields[e].name];
      return res;

    }));

  }

  joinIndex = 1;
  parseTreeInner(
    name: string,
    tree: Record<string, QueryJoinLevel & { joinName?: string }>,
    parentName: string,
  ) {
    Object.keys(tree).forEach(e => {
      const item = tree[e];
      if (!item.joinName) {
        item.joinName = `${name}${this.joinIndex++}`;
      }
      const { parent, field, fieldName, childTree, level, sliced, joinName } = item;
      const isLast = Object.keys(childTree).length === 0;

      const belongsToRoot = field.attributes.belongsToRoot?.first();
      const belongsTo = field.attributes.belongsTo?.first();
      const hasOne = field.attributes.hasOne?.first();
      const hasMany = field.attributes.hasMany?.first();

      if (hasMany || hasOne) {
        const remoteField: (string & {}) | undefined = hasMany?.remote || hasOne?.remote || undefined;
        const remote = remoteField && schema.tables[field.name].fields[remoteField];
        if (!remote) throw new Error("remote field not found for key " + name + ":" + e);
        const belongsTo = remote?.attributes?.belongsTo?.first();
        const belongsToRoot = remote?.attributes?.belongsToRoot?.first();

        if (!belongsTo && !belongsToRoot) {
          console.log("remote", parent.name, e, remoteField, sliced);
          throw new Error("remote without belongsTo or belongsToRoot is not supported for key " + name + ":" + e);
        }

        if (remote) {
          this.join([
            `public."${field.name}"`,
            `${joinName}`,
            `ON`,
            belongsTo
              ? `${joinName}."${belongsTo.isRelation ? `${remoteField}ID` : belongsTo.root}" = ${parentName}."id"`
              : `${joinName}."${remoteField}" = ${parentName}."id"`
          ].join(" "));
        } else {
          console.log("unknown", parent.name, e, field, remoteField, remote, belongsTo);
          throw new Error("unknown field type for key " + name + ":" + e);
        }

      } else if (belongsTo && (!isLast || !sliced)) {

        if (belongsTo.isRelation || belongsTo.root) {
          // if the user actually specified the relation field, then they just want a join
          this.join([
            `public."${field.name}" ${joinName}`,
            `ON ${joinName}."id" = ${parentName}."${belongsTo.isRelation ? `${e}ID` : belongsTo.root}"`,
          ].join(" "));
        } else {
          console.log("belongsTo", parent.name, e, belongsTo, sliced);
          throw new Error(`belongsTo without isRelation or root is not supported for ${fieldName} on ${parent.name}`);
        }

      } else {
        // console.log("unknown", parent.name, e, field);
        // throw new Error("unknown field type for key " + name + ":" + e);
      }
      this.parseTreeInner(name, childTree, joinName);
    });
  }


}

export function GenericPathProxyWithTables<T extends Record<string, string>, R>(table: T, func: (table: { [K in keyof T]: any }) => R): R {
  return func(Object.keys(table).reduce((n, k) => {
    n[k] = GenericPathMapProxy((e) => `${table[k]}:${e}`);
    return n;
  }, {} as any));
}

function GenericPathMapProxy(map: (val: string) => string, path: string[] = []): any {

  return new Proxy<any>({}, {
    get(t: any, p: string, r) {
      if (p === "__") return map(path.join("/"));
      return GenericPathMapProxy(map, [...path, p]);
    }
  });

}

interface QueryTree {
  type: ModelClass<TABLE_NAMES>;
  children: Map<string, QueryTree>;
}
function is<T>(a: any, b: boolean): a is T { return b; }
class QueryJoiner2 {
  start;
  fields;
  parent;
  fieldsTree;
  constructor(
    public key: string,
    public tablesTree: Record<TABLE_NAMES, any>,
  ) {

    const start = key.split(":")[0];
    if (!is<TABLE_NAMES>(start, !!schema.tables[start]))
      throw new Error("invalid key " + key);
    this.start = start;

    const path = key.split(":").slice(1).join(":");
    const arr = path.split("/");

    let parent = this.parent = schema.tables[start];
    let fieldsTree = this.fieldsTree = this.tablesTree[start];
    this.fields = arr.map((e, i) => {

      if (isRelationID(parent, e)) e = e.slice(0, -2);
      const field = parent.fields[e];
      if (!field) throw new Error("invalid key " + key);

      if (fieldsTree[e]) {
        if (fieldsTree[e].field !== field) throw new Error("field mismatch for key " + key);
      } else {
        fieldsTree[e] = { parent, key: e, field, level: i, childTree: {} };
      }

      fieldsTree = fieldsTree[e].childTree;
      parent = schema.tables[parent.fields[e].name];
      return { parent, key: e, field };

    });


  }


}


// type Levels = {
//   OR?: (Levels)[];
// } | {
//   [key: string]: string | boolean | QueryJoinerValue;
// };

// function createJoinQuery<T extends Record<string, TABLE_NAMES>>(
//   start: T,
//   keyFunc: (e: { [K in keyof T]: TypeTreeWithID<Root["types"][T[K]], `${T[K]}:`> }) => Levels,
// ) {
//   const joins: Record<string, true> = {};
//   const where: Record<string, true> = {};
//   const keyval = GenericPathProxyWithTables(start, keyFunc);
//   const levels = createJoinQueryOuter<T>(joins, keyval).join(" AND ");
//   const joins2 = Object.keys(joins);
//   return [
//     `FROM ${Object.keys(joins).join("\n  INNER JOIN ")}`,
//     // `WHERE (${Object.keys(where).join(")\nAND (")})`,
//     `WHERE ${levels}`,
//   ].join("\n");
// }
function isRelationID(parent: ModelClass<TABLE_NAMES>, e: string) {
  return !parent.fields[e] && e.endsWith("ID") && parent.fields[e.slice(0, -2)] && hasID(parent.name, e.slice(0, -2));
}
function hasID(name: string, remote: string) {
  return schema.tables[name].fields[remote].attributes.belongsTo?.first()?.isRelation;
}

export class QueryJoinerValue {
  constructor(public key: string, public val: (val: string) => string) { }
}


class QueryJoinerOld {

  static createJoinQuery<T extends Record<string, TABLE_NAMES>>(
    start: T,
    keyFunc: (e: {
      [K in keyof T]: TypeTreeWithID<Root["types"][T[K]]>;
    }) => Levels) {
    const joiner = new QueryJoinerOld();
    const keyval = GenericPathProxyWithTables(start, keyFunc);
    // console.log(keyval)
    const levels = joiner.createJoinQueryOuter<T>(keyval).join(" AND ");
    const joins = [...joiner.joins.keys()];
    return [
      `FROM ${joins.join("\n  INNER JOIN ")}`,
      `WHERE ${levels}`,
    ].join("\n");
  }


  public joins: Set<string> = new Set();

  join(str: string) {
    this.joins.add(str);
  }

  createJoinQueryOuter<T extends Record<string, TABLE_NAMES>>(keyval: Levels): string[] {
    if (typeof keyval === "string")
      return [keyval];
    const { OR = [] } = keyval as { OR?: Levels[] };
    delete keyval.OR;
    // delete keyval.AND;

    const levelCUR = {};

    Object.keys(keyval).forEach(this.createJoinQueryInner(levelCUR, keyval as Record<string, string | boolean>));

    const levelOR = OR.flatMap(e => this.createJoinQueryOuter<T>(e));

    return [
      Object.keys(levelCUR).join("\nAND\n"),
      levelOR.join("\nOR\n"),
    ].filter(truthy).map(e => `(\n  ${e.split("\n").join("\n  ")}\n)`);

  }


  createJoinQueryInner(
    level: Record<string, true>,
    keyval: Record<string, string | boolean>
  ): (value: string, index: number, array: string[]) => void {
    return key => {


      const start = key.split(":")[0];

      const path = key.split(":").slice(1).join(":");
      const a = path.split("/");
      const val = keyval[key];

      if (!schema.tables[start])
        throw new Error("invalid key " + key);

      if (!path)
        this.join(`public."${start}" ${this.joins.size === 0 ? "" : "ON TRUE"}`);

      else {
        // console.log(new QueryJoiner2(key));

        for (
          let i = 0,
          parent = schema.tables[start],
          e = a[i];
          i < a.length;
          (
            parent = schema.tables[parent.fields[e].name],
            i++,
            e = a[i]
          )
        ) {

          const sliced = isRelationID(parent, e);
          if (sliced) e = e.slice(0, -2);
          const field = parent.fields[e];
          if (!field)
            throw new Error("invalid key " + key);
          const hasMany = field.attributes.hasMany?.first();
          const hasOne = field.attributes.hasOne?.first();
          const belongsTo = field.attributes.belongsTo?.first();


          if (hasMany || hasOne) {
            const remoteField = hasMany?.remote || hasOne?.remote;
            const tag = hasMany ? "hasMany" : hasOne ? "hasOne" : "unknown";
            if (remoteField && schema.tables[field.name].fields[remoteField]) {
              const remote = schema.tables[field.name].fields[remoteField];
              const isRelation = remote.attributes.belongsTo?.first()?.isRelation;
              this.join(`public."${field.name}" ON public."${field.name}"."${remoteField}${isRelation ? "ID" : ""}" = public."${parent.name}"."id"`);
            } else {
              console.log(tag, parent.name, e, remoteField);
              throw new Error(tag + " is not implemented for key " + path);
            }
          }
          else if (belongsTo) {
            if (i < a.length - 1 || !sliced) {
              if (!belongsTo.isRelation && !belongsTo.root) {
                console.log("belongsTo", parent.name, e, belongsTo);
                throw new Error("belongsTo without isRelation is not implemented for key " + path);
              }

              // if the user actually specified the relation field, then they just want a join
              this.join(`public."${field.name}" ON public."${field.name}"."id" = public."${parent.name}"."${belongsTo.isRelation ? `${e}ID` : belongsTo.root}"`);
              // where[``] = true;
            } else if (typeof val === "string") {
              // but if they specified the scalar field, then they want to filter by the scalar field
              level[`public."${parent.name}"."${a[i]}" = ${val}`] = true;

            }
          } else if (belongsTo && sliced) {
            console.log("belongsTo", parent.name, e, belongsTo);
            throw new Error("belongsTo without isRelation is not implemented for key " + path);
          } else if (i === a.length - 1 && typeof val === "string") {
            level[`public."${parent.name}"."${a[i]}" = ${val}`] = true;
          } else {
            console.log("unknown", parent.name, e, i, a, field, field.attributes, val);
            throw new Error("unknown field type for key " + path);
          }
          // if we're going through a hasMany, the remote specifies the child field connecting to this field. 
          // if it's a belongsTo with isRelation, then the field name with 'ID' appended is the foriegn key
        }
      }

    };
  }

}
