import { ScalarDate, ScalarDateTime, ScalarEmail, ScalarJSON, ScalarPhone, ScalarTime, ScalarTimestamp, Boolean, CubesDinero, Float, ID, Int } from "./cubes-amazon-old";
import { EnumMember, IsArray, RecordType, RecordMember, ValueTree, AnyMember, GraphRoot, Wrapping } from "./graphql-declarator";
import { } from "tslib";
import { Attributes, ENUM_NAMES, RecursiveArray, recursiveindent } from "./cubes-utils";
import { header, output } from "./cubes-prisma";
import { schema } from "./cubes-index";
import { EmailDoc, FileUpload, GeocodeAddress, NoticeTemplate, NotificationPreferences, select } from "./cubes-schema";
import { Member } from "./cubes-schema-helpers";
import { lookup } from "./cubes-attributes";
import { root, Root } from "./cubes-index";
function ok(a: any, message?: string): asserts a { if ((a ?? null) === null) throw new Error(message || "Assertion Failed"); }




export type PathString2<T> = T extends {} ? `${string & keyof T}` | `${string & keyof T}/${string & keyof (T[string & keyof T]) | string}` | `${string & keyof T}/${string & keyof (T[string & keyof T])}/${string}` : never;



export class Manual<T extends RecordMember<any>> extends Wrapping<T> {
  // static register = true;
  override get __name(): T["__name"] {
    return this.__wrap.__name;
  }
  override get __plural(): T["__plural"] {
    return this.__wrap.__plural;
  }
  override get __type(): T["__type"] {
    return this.__wrap.__type;
  };



  constructor(__required: boolean, private __lazywrap: () => T) {
    super();
    this.__required = __required;
    Object.defineProperty(this, "__wrap", {
      configurable: true,
      enumerable: true,
      get: () => this.__lazywrap(),
      set: () => { }
    })
    this.__hookRegister = this.__wrap.__hookRegister;
    this.__register = (state) => this.__wrap.__register(state);
    if (this.__wrap instanceof EnumMember) throw new Error("Enum Values do not use IsArray");
  }

  override __fieldAttributes: T["__fieldAttributes"] = () => {
    return this.__wrap.__fieldAttributes();
  }

}


export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelNumberInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelEnumInput<T> = {
  eq?: T | null;
  ne?: T | null;
};

export type ModelRecordInput<T> = {
  and?: Array<ModelRecordInput<T> | null> | null;
  or?: Array<ModelRecordInput<T> | null> | null;
  not?: ModelRecordInput<T> | null;
}

const j = JSON.stringify;
export function printCubes_questions(root: Root) {

  return recursiveindent([
    `
import { 
  QuestionGroup, QuestionInputMask, QuestionInputNumber, 
  QuestionOptions, QuestionSimple, QuestionSubGroup, 
  QuestionCalendar 
} from '../utils';
import {  QuestionEnumOption, Modes, UIService,   } from "common"; import { DataService } from "data-service";
import * as c from "common";
import { Injectable } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
`,
    `export class _CubesSchema { `,

    [
      `constructor(private ui: UIService){}`,
      `static tables = ${JSON.stringify(Object.keys(root.types).filter(e => root.types[e].__is("table")))} as const;`
    ],
    Object.keys(schema.enums).map((k) => {
      let opts = enumValueBody(k as ENUM_NAMES);
      return `private ${k}: QuestionEnumOption[] = ${JSON.stringify(opts)};`
    }),
    ...Object.entries(root.types).map(([k, v]) => [
      `${v.__is("table") ? "" : "private "}${v.__name}(mode: Modes){`,
      typesFuncBody(v, false),
      `}`,
      ...Object.entries(v.__typeAttributes().forms?.first()?.extraForms ?? {}).flatMap(([k, c]) => [
        `${v.__is("table") ? "" : "private "}${k}(mode: Modes){`,
        typesFuncBody(v, false, c),
        `}`,
      ])
    ]),
    `}`,
  ])

}
function enumValueBody(k: ENUM_NAMES) {
  return Object.values(schema.enums[k].options).map((e, i) => {
    return {
      ...e.attributes.field?.first() ?? {},
      value: e.value,
      order: e.order,
    };
  }, {} as any);
}

function fixAttrs(v: Attributes<any>) {
  const attrs: any = JSON.parse(JSON.stringify(v));
  Object.keys(attrs).forEach((k) => { if (k !== "index") attrs[k] = attrs[k] && attrs[k].first(); });
  return attrs;
}

export function typesFuncBody(v: RecordType, js: boolean, filterControls?: readonly string[]): RecursiveArray<string> {
  const forms = v.__typeAttributes().forms;
  const filterControlsSort = ([ka, va]: [string, RecordMember<any>], [kb, vb]: [string, RecordMember<any>]) =>
    filterControls ? filterControls.indexOf(ka) - filterControls.indexOf(kb) : va.__index - vb.__index;
  return [
    `return new QuestionGroup({`, [
      `__typename: "${v.__name}",`,
      ...(header[v.__name] ? `__prismaheader: ${JSON.stringify(header[v.__name].map((e: string) => e.trim()), null, 2)},`.split("\n") : []),
      `forms: ${JSON.stringify(forms && forms[0])},`,
      `controls: {`, [
        `${v.__type === "table" ? `...this.ui.tableControls(mode, ${!filterControls || filterControls.indexOf("extra") !== -1}),` : `id: new QuestionSimple("Hidden", {onlyfor: []}),`}`,
        ...v.__listMembers().sort(filterControlsSort).flatMap(([k, v2]) => {

          if (k === "id" || k === "createdAt" || k === "updatedAt" || k === "extra") return [];

          if (filterControls && filterControls.indexOf(k) === -1) return [];

          let isArray = false, isArrayRequired = false;

          if (v2 instanceof IsArray) { isArrayRequired = v2.__required; v2 = v2.__wrap; isArray = true; }

          const attrs: Attributes = v2.__fieldAttributes();
          const field = attrs.field?.first();
          // const validators = attrs.validators?.first();
          const select = attrs.select?.first();
          const hasOne = attrs.hasOne?.first();
          const hasMany = attrs.hasMany?.first();
          const prejoin = attrs.prejoin?.first();
          const relations = attrs.relationPrisma?.first();
          const belongsTo = attrs.belongsTo?.first();
          const extra = { ...fixAttrs(attrs), field: undefined, index: undefined };
          const added = {} as any;

          let so: RecursiveArray<string> = [
            ...(v2.__required ? [`required: true,`] : []),
            ...Object.entries(field ?? []).map(([k, v]) => {
              const sppi = ["arrayList", "arraySort", "extraGetPaths"].contains(k) && Array.isArray(v) && !js ? ' as c.SPPI[]' : '';
              return `${JSON.stringify(k)}: ${JSON.stringify(v)}${sppi},`;
            }),
            // `attr_field: ${JSON.stringify(field)},`,
            // `attr_validators: ${JSON.stringify(validators)},`
          ];
          let res: RecursiveArray<string> = [];
          const tags = attrs.noticeTags?.first()?.tags;
          if (v2.__is("enum")) {
            res.push(`this.ui.QuestionEnum(mode, ${isArray}, this.${v2.__name}, {`);
            so.push(...Object.entries(select ?? []).map(([k, v]) => `${JSON.stringify(k)}: ${JSON.stringify(v)},`));
          } else if (v2.__is("scalar")) {
            let lookup = attrs.lookup?.first() as ValueTree<lookup<any, any>> | undefined;
            let select = attrs.select?.first() as ValueTree<select<any, any>> | undefined;
            if (isArray) {
              // TODO: this is not implemented yet
              res.push(`new QuestionSimple("Hidden", {`);
            } else if (lookup && lookup.optionValue) {
              lookupSelect(res, lookup, select, k, added, extra);
            } else if (v2 instanceof ScalarDate || v2 instanceof ScalarDateTime || v2 instanceof ScalarTime) {
              res.push(`new QuestionCalendar({`);
              so.unshift(`format: ${j(v2.format)},`);
              so.unshift(`showDate: ${v2 instanceof ScalarDate || v2 instanceof ScalarDateTime},`);
              so.unshift(`showTime: ${v2 instanceof ScalarTime || v2 instanceof ScalarDateTime},`);
            }
            else {
              switch (v2.__name) {
                case CubesDinero.name:
                  res.push(`new QuestionInputNumber({`);
                  so.unshift(
                    `inputMode: "currency",`,
                    // `currency: 'USD',`
                  );
                  extra.currency = undefined;
                  break;
                case Float.name:
                  res.push(`new QuestionInputNumber({`);
                  so.unshift(`inputMode: "decimal",`);
                  break;
                case Int.name: {
                  res.push(`new QuestionInputNumber({`);
                  so.unshift(`inputMode: "integer",`);
                  break;
                }

                case ScalarPhone.name: { res.push(`new QuestionInputMask({`); so.unshift(`mask: "(999) 999-9999",`); break; }
                case Boolean.name: { res.push(`new QuestionSimple("CheckBox", {`); break; }
                case String.name: { res.push(`new QuestionSimple("InputText", {`); break; }
                case ScalarEmail.name: { res.push(`new QuestionSimple("InputText", {`); break; }
                case ScalarJSON.name: {
                  res.push(`new QuestionSimple("Textarea", {`);
                  so.unshift(`useDBNull: true,`);
                  break;
                }
                case ScalarTimestamp.name: { res.push(`new QuestionSimple("Hidden", {`); break; }
                case ID.name: {

                  let unique = attrs.field?.first()?.unique;
                  if (lookup) {
                    lookupSelect(res, lookup, select, k, added, extra);
                  } else if (unique) {
                    // unique fields probably are showing the subform, so just hide the id field
                    res.push(`new QuestionSimple("Hidden", {`);
                  } else {
                    res.push(`new QuestionSimple("InputLabel", {`);
                  }
                  break;
                }
                default: { res.push(`new QuestionSimple("InputText", {`); break; }
              }
            }
          } else if (v2.__is("record")) {
            if (!isArray) {
              if (v2.__name === NotificationPreferences.name) {
                res.push(`this.ui.QuestionNotificationPreferences(mode, {`);
              } else if (v2.__name === GeocodeAddress.name) {
                res.push(`this.ui.QuestionGeocodeAddress(mode, true, {`);
              } else if (v2.__name === EmailDoc.name) {
                res.push(`this.ui.QuestionEmailDoc(mode, {`);
              } else {
                res.push(`new QuestionSubGroup(this.${v2.__name}(mode), {`);
              }
              so.unshift(`useDBNull: true,`);
            } else if (isArray) {
              if (v2.__name === FileUpload.name) {
                // res.push(`this.ui.QuestionFileUpload(mode, {`);
                res.push(`new QuestionSimple("Hidden", {`);
              } else {
                res.push(`this.ui.QuestionRecordArray(${JSON.stringify(v2.__name)}, {`);
              }
            }

          } else if (v2.__is("table") && attrs.lookup?.length && !isArray) {
            let lookup = attrs.lookup?.first() as ValueTree<lookup<any, any>>;
            let select = attrs.select?.first() as ValueTree<select<any, any>> | undefined;
            lookupSelect(res, lookup, select, k, added, extra);
          } else if (v2.__is("table") && (attrs.belongsTo?.length) && !isArray) {
            //these are required because they contain data
            if (attrs.field?.first()?.hidden || !attrs.field?.first()?.unique) res.push(`new QuestionSimple("Hidden", {`);
            else res.push(`this.ui.QuestionBelongsToUnique${js ? '' : `<c.${v2.__name}, c.${v.__name}>`}(this.${v2.__name}(mode), mode, ${j(attrs.belongsTo.first() ?? null)}, {`);
          } else if (v2.__is("table") && (attrs.hasOne?.length) && !isArray) {
            //these are required because they contain data
            if (attrs.field?.first()?.hidden) res.push(`new QuestionSimple("Hidden", {`);
            else res.push(`this.ui.QuestionHasOne${js ? '' : `<c.${v2.__name}, c.${v.__name}>`}(this.${v2.__name}(mode), mode, ${j(attrs.hasOne.first() ?? null)}, {`);
          } else if (v2.__is("table") && (hasMany?.remote || relations?.remote) && isArray) {
            if (!field?.arrayList) {
              res.push(`new QuestionSimple("Hidden", {`);
            } else {
              extra.hasMany = undefined;
              extra.prejoin = undefined;
              extra.relations = undefined;
              res.push(`this.ui.QuestionHasMany${js ? '' : `<c.${v2.__name}, c.${v.__name}>`}(${j(v2.__name)}, ${j(hasMany?.remote || relations?.remote)}, ${j(prejoin)}, {`);
            }
          } else if (v2.__is("table") && (attrs.relationPrisma?.length || field?.clientSideLoad)) {
            if (!isArray)
              res.push(`new QuestionSubGroup(this.${v2.__name}(mode), {`);
            else if (!field?.arrayList)
              res.push(`new QuestionSimple("Hidden", {`);
            else
              res.push(`this.ui.QuestionRecordArray(${JSON.stringify(v2.__name)}, {`);

          } else if (v2.__is("table") && field?.clientSideOnly) {
            res.push(`new QuestionSimple("Hidden", {`);
          }
          if (res.length === 0) {
            console.log();
            throw `${v.__name} ${isArray} ${k} ${v2.__name} ${v2.__type} ${JSON.stringify(attrs)} not handled`;
          }
          res.push(so);
          res.push(`}),`);

          if (output[v.__name] && output[v.__name][k])
            so.push(...`__prismafield: ${JSON.stringify({ "": output[v.__name][k].trim(), ...added }, null, Object.keys(added).length ? 2 : undefined)},`.split("\n"));
          res[0] = `${JSON.stringify(k)}: ${res[0]}`;
          res.unshift(`// ${k}: ${v2.__name} ${JSON.stringify(extra)} //`);
          return res;
        }),
      ], `}${js ? "" : ` as const`},`,
    ], `})`,
  ];

  function lookupSelect(res: RecursiveArray<string>, lookup: ValueTree<lookup<any, any>>, select: ValueTree<select<any, any>> | undefined, k: string, added: any, extra: any) {
    const check = (k: any, v: any) => ["method", "queryName_internal"].indexOf(k) === -1 ? v : undefined;
    res.push(...`this.ui.select${js ? '' : `<c.${lookup.targetTable}, c.${v.__name}>`} (mode, ${j(lookup, check, 2)}, ${j(select, check, 2)})({`.split("\n"));
    const relation = k.slice(0, k.length - 2);
    if (output[v.__name] && output[v.__name][relation]) {
      added[relation] = output[v.__name][relation];
    }
    extra.lookup = undefined;
  }
}

export function printCubes_jsonschema(root: GraphRoot) {
  const scalars = Object.entries(root.scals).reduce((n, [k, v]) => {
    // const attrs = JSON.parse(JSON.stringify(v.__typeAttributes()));
    // Object.keys(attrs).forEach((k) => { attrs[k] = attrs[k] && attrs[k].first() });
    let type = "string";
    switch (v.__name) {
      case "Boolean": type = "boolean"; break;
      case "Float":
      case "Int": type = "number"; break;
    }
    n[k] = { type, title: k };
    return n;
  }, {} as any);
  const enums = Object.entries(root.enums).reduce((n, [k, v]) => {
    // const attrs = fixAttrs(v.__typeAttributes());
    n[k] = { enum: v.__listMembers().map(e => e[0]), title: k };
    return n;
  }, {} as any);
  const records = Object.entries(root.types).map(([k, v]) => {
    const base = v.__type === "table" ? "makeTable" : "makeRecord"
    const attrs = JSON.stringify(fixAttrs(v.__typeAttributes()), null, 2)
    return [
      `export class ${v.__name} extends ${base}(${attrs}) {`,
      `  // static register = root.register(() => new this());`,
      v.__listMembers().map(([k, v]) => {
        let isArray = false, isArrayRequired = false;
        if (v instanceof IsArray) { isArrayRequired = v.__required; v = v.__wrap; isArray = true; }
        let attrs = JSON.stringify(fixAttrs(v.__fieldAttributes()), null, 2);
        let field = `Field(${v.__required}, { ${v.__name} }, ${attrs})()`;
        return `  ${k} = ${isArray ? `new IsArray(${isArrayRequired}, ${field})` : field}`
      }).join("\n"),
      `}`
    ].join("\n")
  }).join("\n");
  const directives = Object.entries(root.directives).map(([k, v]) => {
    // const base = v.__type === "table" ? "makeTable" : "makeRecord"
    // const attrs = JSON.stringify(fixAttrs(v.__typeAttributes()), null, 2)
    // makeDirective({ FIELD_DEFINITION: true, ENUM_VALUE: true }, false)


    /** remember to update fixAttrs */
    const doMultiple = v.__name === "index";

    return [
      `export class ${v.__name} extends makeDirective(${JSON.stringify(v.__host)}, ${doMultiple}) {`,
      `  // static register = root.register(() => new this());`,
      v.__listMembers().map(([k, v]) => {
        let isArray = false, isArrayRequired = false;
        if (v instanceof IsArray) { isArrayRequired = v.__required; v = v.__wrap; isArray = true; }
        let attrs = JSON.stringify(fixAttrs(v.__fieldAttributes()), null, 2);
        let field = `Field(${v.__required}, { ${v.__name} }, ${attrs})()`;
        return `  ${k} = ${isArray ? `new IsArray(${isArrayRequired}, ${field})` : field}`
      }).join("\n"),
      `}`
    ].join("\n")
  }).join("\n");

  const directivesLookup = [
    `type Directives = typeof Directives;`,
    `const Directives = {\n${Object.values(root.directives).map(e => `  ${e.__name},`).join("\n")}\n};`
  ].join("\n");


  console.log(AnyMember.fieldLengths);
  console.log(AnyMember.typeLengths);
  return `${directives}\n${directivesLookup}\n${scalars}\n${enums}\n${records}\nroot.items.forEach(e => { e(); });`
  function fixAttrs(v: Attributes<any>) {
    const attrs: any = JSON.parse(JSON.stringify(v));
    Object.keys(attrs).forEach((k) => { if (k !== "index") attrs[k] = attrs[k] && attrs[k].first(); });
    return attrs;
  }
}




// export class TimestampConfiguration extends makeRecord({}) {
//   // static register = root.register(() => new this());
//   createdAt = Field(false, { String }, {})();
//   updatedAt = Field(false, { String }, {})();
// }

// export class ConfirmType extends makeEnum({}) {
//   // static register = root.register(() => new this());
//   CREATE = new EM({ field: { title: "Create" } });
//   UPDATE = new EM({});
//   DELETE = new EM({});
// }
// export class lookup<T, H> extends makeDirective({ FIELD_DEFINITION: true }, false) {
//   // static register = root.register(() => new this());
//   queryName_internal = Field(false, { String }, {})<`List${ string }Lookup`>()
//   queryName = Field(false, { String }, {})()
//   querySort = Field(false, { String }, {})() //, "" as "ASC" | "DESC")
//   // method = new LookupMethod()
//   item = Field(false, { String }, {})()
//   list = new IsArray(Field(false, { String }, {})
//     <MemberKeys<T> | `${ MemberKeys<T>} / ${ string }`>());
//   filter = new IsArray(Field(false, { FilterWith }, {})());
//   remoteID = Field(false, { String }, {})<MemberKeys<T>>();
//   localItem = Field(false, { String }, {})<MemberKeys<H>>();
// }
// // type AnyValue<T extends AnyType<any, any, any, any>> = GetAnyType<T>[0] // T extends AnyType<infer V, infer X, infer Y, infer Z> ? V : any;


// export class FilterWith<T, H> extends makeRecord({}) {
//   // static register = root.register(() => new this());
//   filterThis = Field(false, { String }, {})<MemberKeys<T>>()
//   filterWith = Field(false, { String }, {})<MemberKeys<H> | `../ ${ string }` | `${ MemberKeys<H>} / ${ string }`>()
//   filterConst = Field(false, { String }, {})()
//   onlyfor = new IsArray(true, Field(false, { ConfirmType }, {})())
//   queryName = Field(false, { String }, {})()
// }


// export class field<T, H> extends makeDirective({ FIELD_DEFINITION: true, ENUM_VALUE: true }, false) {
//   // static register = root.register(() => new this());
//   title = Field(false, { String }, {})()
//   helptext = Field(false, { String }, {})()
//   lefticon = new IsArray(Field(false, { String }, {})())
//   righticon = new IsArray(Field(false, { String }, {})())
//   confirm = new IsArray(Field(false, { ConfirmType }, {})())
//   prevent = new IsArray(Field(false, { ConfirmType }, {})())
//   onlyfor = new IsArray(Field(false, { ConfirmType }, {})())
//   hidden = Field(false, { Boolean }, {})()

//   arrayList = new IsArray(Field(false, { String }, {})<MemberKeys<T> | `${ MemberKeys<T>} / ${ string }`>())
//   replicate = new IsArray(Field(false, { String }, {})<MemberKeys<H> | `../ ${ string }` | `${ MemberKeys<H>} / ${ string }`>())
// }
// export class info extends makeDirective({ ENUM: true, OBJECT: true }, true) {
//   // static register = root.register(() => new this());
//   name = Field(false, { String }, {})()
// }

// export class Customer extends makeTable({}) {
//   // static register = root.register(() => new this());
//   Address = Field(false, { ContactInfo }, {})()
// }

// export class Rental extends makeTable({}) {
//   // static register = root.register(() => new this());
//   customerID = Field(false, { Customer }, {
//     lookup: {
//       filter: [],
//     }
//   })()
// }

// export class ContactInfo extends makeRecord({}) {
//   // static register = root.register(() => new this());
//   Name = Field(true, { String }, {})()
//   // Address: Member<false, GoogleAddress, field<{ lefticon: ["map"], }>;
//   // Email: Member<false, GEmail, { field: { lefticon: ["email"], } }>;
//   // Phone: Member<false, GPhone, { field: { lefticon: ["phone"], } }>;
//   // Fax: Member<false, GPhone, { field: { lefticon: ["fax"], } }>;
//   // Notes: Member<String();
// }

