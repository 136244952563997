
import { Link, Outlet, isRouteErrorResponse, useRouteError } from "react-router-dom";
import { useAngular, useObservable } from "react-utils";
import { BlockStack, Box, Button, Card, Divider, InlineStack, Page } from "@shopify/polaris";
import { LinkLikeComponentProps } from "@shopify/polaris/utilities/link";
import { PropsWithChildren, useContext, useLayoutEffect } from "react";
import { AuthService, SignoutContext } from "./service-auth";
import { CUBES_LOGO } from "common";
import { AppService, useLocation, useNavigate, } from "./service-app";
import PageGettingStarted from "./page-getting-started";
import { asCustomer } from "../flags";


export function AppLinks({ url, ...rest }: LinkLikeComponentProps) {
  return <Link to={url} {...rest} />;
}

export function ReactRootView() {
  return <Outlet />;
}


export function ErrorBoundary() {
  const error = useRouteError();

  return <Page title="Error" >
    <Card>
      {isRouteErrorResponse(error) ? (
        <div>
          <h1>Oops!</h1>
          <h2>{error.status}</h2>
          <p>{error.statusText}</p>
          {error.data?.message && <p>{error.data.message}</p>}
        </div>
      ) : (
        <div>
          <code>{(error as any).stack}</code>
        </div>
      )}
    </Card>
  </Page>
}

const stylesInlineStack = {
  "--pc-inline-stack-align": "start",
  "--pc-inline-stack-block-align": "center",
  "--pc-inline-stack-wrap": "wrap",
  "--pc-inline-stack-gap-xs": "var(--p-space-200)",
} as any;

export default function App({ children }: PropsWithChildren<object>) {
  const { get } = useAngular();
  const app = get(AppService);
  const auth = get(AuthService);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const signOut = useContext(SignoutContext);

  useObservable(app.statusChange);

  useLayoutEffect(() => {
    if (app.status && !app.gettingStartedComplete && pathname === "/billing") { navigate("/getting-started", { replace: true }); }
    else if (pathname === "/") { navigate("/billing", { replace: true }); }
  }, [pathname, navigate, app.status, app.gettingStartedComplete]);

  const button = (url: string, text: string, disabled = false) =>
    <Button
      url={url}
      variant="tertiary"
      role={url === pathname ? "tab-selected" : "tab"}
      disabled={disabled}
      size="large"
    >{text}</Button>;

  console.log(app.status, app.statusError);

  return (
    <BlockStack gap="400" inlineAlign="center">
      <Box maxWidth="62.375rem" width="100%" paddingInline={{ xs: "100", sm: "600" }}>
        <BlockStack gap="400">
          <Card>
            <BlockStack gap="400">
              <InlineStack align="space-between" blockAlign="center" gap="400">
                <div><img alt="Cubes Storage" src={CUBES_LOGO} height="40" className="mr-2" /></div>
                {!!asCustomer && <div>Viewing customer as branch</div>}
                <Button variant="tertiary" size="large" onClick={signOut}>Sign out</Button>
              </InlineStack>
            </BlockStack>
          </Card>
          {!app.status && app.statusError && <Card>
            An error occured. {app.statusError.message}
          </Card>}
          {app.status && <Card padding="400">
            <BlockStack gap="0" inlineAlign="stretch">
              <div className="Polaris-InlineStack Custom-Tab-Buttons" style={stylesInlineStack}>
                {button("/getting-started", "Getting Started")}
                {button("/billing", "Billing Info", !app.gettingStartedComplete)}
                {button("/ledger", "Charges & Payments")}
              </div>
              <Divider />
              {children}
            </BlockStack>
          </Card>}
        </BlockStack>
      </Box>
    </BlockStack>
  );

}