var styles = {
  "IndexTable": "Polaris-IndexTable",
  "IndexTableWrapper": "Polaris-IndexTable__IndexTableWrapper",
  "IndexTableWrapper-scrollBarHidden": "Polaris-IndexTable__IndexTableWrapper--scrollBarHidden",
  "IndexTableWrapperWithSelectAllActions": "Polaris-IndexTable__IndexTableWrapperWithSelectAllActions",
  "LoadingContainer-enter": "Polaris-IndexTable__LoadingContainer--enter",
  "LoadingContainer-enter-active": "Polaris-IndexTable--loadingContainerEnterActive",
  "LoadingContainer-exit": "Polaris-IndexTable__LoadingContainer--exit",
  "LoadingContainer-exit-active": "Polaris-IndexTable--loadingContainerExitActive",
  "LoadingPanel": "Polaris-IndexTable__LoadingPanel",
  "LoadingPanelRow": "Polaris-IndexTable__LoadingPanelRow",
  "LoadingPanelText": "Polaris-IndexTable__LoadingPanelText",
  "Table": "Polaris-IndexTable__Table",
  "Table-scrolling": "Polaris-IndexTable__Table--scrolling",
  "TableCell-first": "Polaris-IndexTable__TableCell--first",
  "TableCell": "Polaris-IndexTable__TableCell",
  "TableHeading-first": "Polaris-IndexTable__TableHeading--first",
  "TableHeading-second": "Polaris-IndexTable__TableHeading--second",
  "Table-sticky": "Polaris-IndexTable__Table--sticky",
  "Table-unselectable": "Polaris-IndexTable__Table--unselectable",
  "TableRow": "Polaris-IndexTable__TableRow",
  "TableRow-unclickable": "Polaris-IndexTable__TableRow--unclickable",
  "toneSuccess": "Polaris-IndexTable--toneSuccess",
  "TableRow-child": "Polaris-IndexTable__TableRow--child",
  "toneWarning": "Polaris-IndexTable--toneWarning",
  "toneCritical": "Polaris-IndexTable--toneCritical",
  "toneSubdued": "Polaris-IndexTable--toneSubdued",
  "TableRow-subheader": "Polaris-IndexTable__TableRow--subheader",
  "TableRow-selected": "Polaris-IndexTable__TableRow--selected",
  "TableRow-hovered": "Polaris-IndexTable__TableRow--hovered",
  "TableRow-disabled": "Polaris-IndexTable__TableRow--disabled",
  "ZebraStriping": "Polaris-IndexTable__ZebraStriping",
  "TableHeading": "Polaris-IndexTable__TableHeading",
  "TableHeading-flush": "Polaris-IndexTable__TableHeading--flush",
  "TableHeading-align-center": "Polaris-IndexTable--tableHeadingAlignCenter",
  "TableHeading-align-end": "Polaris-IndexTable--tableHeadingAlignEnd",
  "TableHeading-extra-padding-right": "Polaris-IndexTable--tableHeadingExtraPaddingRight",
  "TableHeading-sortable": "Polaris-IndexTable__TableHeading--sortable",
  "TableHeadingSortButton": "Polaris-IndexTable__TableHeadingSortButton",
  "TableHeadingSortIcon": "Polaris-IndexTable__TableHeadingSortIcon",
  "TableHeadingSortButton-heading-align-end": "Polaris-IndexTable--tableHeadingSortButtonHeadingAlignEnd",
  "TableHeadingSortButton-heading-align-end-currently-sorted": "Polaris-IndexTable--tableHeadingSortButtonHeadingAlignEndCurrentlySorted",
  "TableHeadingSortIcon-heading-align-end": "Polaris-IndexTable--tableHeadingSortIconHeadingAlignEnd",
  "TableHeadingSortButton-heading-align-end-previously-sorted": "Polaris-IndexTable--tableHeadingSortButtonHeadingAlignEndPreviouslySorted",
  "right-aligned-sort-button-slide-out": "Polaris-IndexTable--rightAlignedSortButtonSlideOut",
  "reveal-right-aligned-sort-button-icon": "Polaris-IndexTable--revealRightAlignedSortButtonIcon",
  "TableHeadingUnderline": "Polaris-IndexTable__TableHeadingUnderline",
  "TableHeadingTooltipUnderlinePlaceholder": "Polaris-IndexTable__TableHeadingTooltipUnderlinePlaceholder",
  "TableHeadingSortIcon-visible": "Polaris-IndexTable__TableHeadingSortIcon--visible",
  "TableHeadingSortSvg": "Polaris-IndexTable__TableHeadingSortSvg",
  "SortableTableHeadingWithCustomMarkup": "Polaris-IndexTable__SortableTableHeadingWithCustomMarkup",
  "SortableTableHeaderWrapper": "Polaris-IndexTable__SortableTableHeaderWrapper",
  "ColumnHeaderCheckboxWrapper": "Polaris-IndexTable__ColumnHeaderCheckboxWrapper",
  "FirstStickyHeaderElement": "Polaris-IndexTable__FirstStickyHeaderElement",
  "TableHeading-unselectable": "Polaris-IndexTable__TableHeading--unselectable",
  "TableCell-flush": "Polaris-IndexTable__TableCell--flush",
  "Table-sticky-scrolling": "Polaris-IndexTable--tableStickyScrolling",
  "TableHeading-last": "Polaris-IndexTable__TableHeading--last",
  "Table-sticky-last": "Polaris-IndexTable--tableStickyLast",
  "Table-sortable": "Polaris-IndexTable__Table--sortable",
  "StickyTable": "Polaris-IndexTable__StickyTable",
  "StickyTableHeader": "Polaris-IndexTable__StickyTableHeader",
  "StickyTableHeader-isSticky": "Polaris-IndexTable__StickyTableHeader--isSticky",
  "StickyTableColumnHeader": "Polaris-IndexTable__StickyTableColumnHeader",
  "StickyTableHeadings": "Polaris-IndexTable__StickyTableHeadings",
  "StickyTableHeading-second": "Polaris-IndexTable__StickyTableHeading--second",
  "unselectable": "Polaris-IndexTable--unselectable",
  "StickyTableHeading-second-scrolling": "Polaris-IndexTable--stickyTableHeadingSecondScrolling",
  "ScrollLeft": "Polaris-IndexTable__ScrollLeft",
  "ScrollRight": "Polaris-IndexTable__ScrollRight",
  "ScrollRight-onboarding": "Polaris-IndexTable__ScrollRight--onboarding",
  "SelectAllActionsWrapper": "Polaris-IndexTable__SelectAllActionsWrapper",
  "SelectAllActionsWrapperWithPagination": "Polaris-IndexTable__SelectAllActionsWrapperWithPagination",
  "SelectAllActionsWrapperSticky": "Polaris-IndexTable__SelectAllActionsWrapperSticky",
  "SelectAllActionsWrapperAtEnd": "Polaris-IndexTable__SelectAllActionsWrapperAtEnd",
  "SelectAllActionsWrapperAtEndAppear": "Polaris-IndexTable__SelectAllActionsWrapperAtEndAppear",
  "BulkActionsWrapper": "Polaris-IndexTable__BulkActionsWrapper",
  "BulkActionsWrapperVisible": "Polaris-IndexTable__BulkActionsWrapperVisible",
  "PaginationWrapper": "Polaris-IndexTable__PaginationWrapper",
  "PaginationWrapperWithSelectAllActions": "Polaris-IndexTable__PaginationWrapperWithSelectAllActions",
  "PaginationWrapperScrolledPastTop": "Polaris-IndexTable__PaginationWrapperScrolledPastTop",
  "ScrollBarContainer": "Polaris-IndexTable__ScrollBarContainer",
  "ScrollBarContainerWithPagination": "Polaris-IndexTable__ScrollBarContainerWithPagination",
  "ScrollBarContainerScrolledPastTop": "Polaris-IndexTable__ScrollBarContainerScrolledPastTop",
  "ScrollBarContainerWithSelectAllActions": "Polaris-IndexTable__ScrollBarContainerWithSelectAllActions",
  "ScrollBarContainerSelectAllActionsSticky": "Polaris-IndexTable__ScrollBarContainerSelectAllActionsSticky",
  "scrollBarContainerCondensed": "Polaris-IndexTable--scrollBarContainerCondensed",
  "scrollBarContainerHidden": "Polaris-IndexTable--scrollBarContainerHidden",
  "ScrollBar": "Polaris-IndexTable__ScrollBar",
  "disableTextSelection": "Polaris-IndexTable--disableTextSelection",
  "EmptySearchResultWrapper": "Polaris-IndexTable__EmptySearchResultWrapper",
  "condensedRow": "Polaris-IndexTable--condensedRow",
  "CondensedList": "Polaris-IndexTable__CondensedList",
  "HeaderWrapper": "Polaris-IndexTable__HeaderWrapper",
  "StickyTable-condensed": "Polaris-IndexTable__StickyTable--condensed",
  "StickyTableHeader-condensed": "Polaris-IndexTable__StickyTableHeader--condensed",
  "ScrollBarContent": "Polaris-IndexTable__ScrollBarContent"
};

export { styles as default };
