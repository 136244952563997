import React from "react";
import { Badge } from "@shopify/polaris";
import { ArrayColumn, ColumnBase, KeyColumnBase } from '../utils';
import { RentalStatus } from "prisma-client";


export function valMarkup(col: ColumnBase, row: any) {

  if(col.markup) return col.markup(col.get(row));

  if (col.key === "IS_TESTING") {
    debugger;// this is old code, we should be using col.markup
    return col.get(row) ? (<Badge progress="incomplete" tone="critical">Test</Badge>) : null;
  }

  if (col.key === "RentalStatus") {
    debugger;// this is old code, we should be using col.markup
    switch (col.get(row) as RentalStatus | undefined) {
      case "Reserved": return (<Badge progress="incomplete" tone="critical">{col.valText(row)}</Badge>);
      case "Scheduled": return (<Badge progress="complete" tone="attention">{col.valText(row)}</Badge>);
      case "Rented": return (<Badge progress="complete" tone="success">{col.valText(row)}</Badge>);
      case "Moving_Out": return (<Badge progress="incomplete" tone="attention">{col.valText(row)}</Badge>);
      case "Completed": return (<Badge progress="incomplete" tone="critical">{col.valText(row)}</Badge>);
      case "Retained": return (<Badge progress="complete" tone="success">{col.valText(row)}</Badge>);
      case "Released": return (<Badge progress="complete" tone="info">{col.valText(row)}</Badge>);
      case "Archived": return (<Badge progress="complete" tone="info">{col.valText(row)}</Badge>);
      case undefined: return <Badge progress="complete">Not Rented</Badge>;
      default: return <Badge progress="complete">{col.valText(row)}</Badge>;
    }
  }

  if (col.key === "line/paymentLine/PaymentStatus") {
    debugger;// this is old code, we should be using col.markup
    switch (col.get(row)) {
      case "Cleared": return (<Badge progress="complete" tone="success">Cleared</Badge>);
      case "Approved": return (<Badge progress="partiallyComplete" tone="warning">Approved</Badge>);
      case "Declined": return (<Badge progress="complete" tone="critical">Declined</Badge>);
      case "Bounced": return (<Badge progress="complete" tone="critical">Bounced</Badge>);
      // case "Voided": return (<Badge progress="partiallyComplete" tone="">Voided</Badge>);
      case undefined: return null;
      default: return (<Badge progress="incomplete" tone="critical"></Badge>);
    }
  }

  if (col.filterType === "boolean") {
    debugger; // this is old code, we should be using col.markup
    switch (col.get(row)) {
      case true: return (<Badge progress="complete" tone="success">Yes</Badge>);
      case false: return (<Badge progress="incomplete" tone="critical">No</Badge>);
      default: return null;
    }
  }

  // if (col.filterType === "currency") {
  //   const val = col.get(row);
  //   if (Number.isNaN(val)) return "";
  //   return col.text(val);
  // }

  return col.valText(row);

}
