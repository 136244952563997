import { Observable } from 'rxjs';
// import { Buffer } from "buffer/";
import { Prisma } from "prisma-client";
import { Roles, TYPE_NAMES, SPPI, TableViewColumn, TABLE_NAMES, root, } from './datamath';
import { ProxyPromise, proxy } from "./ProxyPromise";
import { NgZone } from '@angular/core';
import exifReader from 'exif-reader';

const as = <T>(a: T) => a;

export const is = <T>(a: any, p: boolean): a is T => p;

export const isTable = (a: any): a is TABLE_NAMES => typeof a === "string" && root.types[a]?.__is("table");
export const isRecord = (a: any): a is TABLE_NAMES => typeof a === "string" && root.types[a]?.__is("record");
export const isTypeName = (a: any): a is TYPE_NAMES => typeof a === "string" && !!root.types[a];

export const satisfies =
  <T>() =>
    <R extends T>(a: R): R =>
      a;

export const uncap = <T extends string>(str: T): Uncapitalize<T> => {
  return str[0].toLowerCase() + str.slice(1) as any;
}

export const predicate = <T>(a: any, b: boolean = !!a): a is T => b;

export function NonNullable<T>(obj: T): obj is NonNullable<T> {
  return obj !== undefined && obj !== null;
}
export function truthy<T>(
  obj: T
): obj is Exclude<T, false | null | undefined | 0 | ''> {
  return !!obj;
}
/* , T extends Record<keyof R, any> */
export const recordToRecords = <
  R extends {},
  A extends [keyof R, keyof R] = [
    keyof R,
    keyof R
  ]
>(
  obj: {},
  [key, val]: A
) => {
  return Object.keys(obj).map((e) => ({ [key]: e, [val]: (obj as any)[e] }));
};

export const noop = (...args: any) => { };

export const keys: <T>(a: T) => (keyof T)[] = Object.keys;

export function toKeys(obj: any) {
  return Object.keys(obj).map((e) => [e, obj[e]]) as [string, any][];
}

export function toKeysTyped<T extends Record<string, any>>(
  obj: T,
  vals: true
): T[keyof T][];
export function toKeysTyped<T extends Record<string, any>>(
  obj: T,
  vals: false
): [keyof T, T[keyof T]][];
export function toKeysTyped<T extends Record<string, any>>(
  obj: T,
  vals: boolean
): any[] {
  return Object.keys(obj).map(!vals ? (e) => [e, obj[e]] : (e) => obj[e]);
}

export const keyPred =
  <T extends Record<string, any>, K extends keyof T, V extends T[K]>(
    key: K,
    value: V
  ) =>
    (f: T): f is { [P in K]: V } & T =>
      f[key] === value;

export type Distribute<T> = T extends any ? T : never;
export type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any ? R : never

type MappedOverloads<T extends Record<keyof T, any>, U extends Record<keyof T, any>> =
  UnionToIntersection<{ [K in keyof T]:
    (v: T[K], k: K) => U[K]
  }[keyof T]>;

export function objMap<T extends Record<keyof T, any>, U extends Record<keyof T, any>, M extends <K extends keyof T>(v: T[K], k: K) => U[K]>(
  obj: T,
  map: M
): U {
  let output: U = {} as never;
  for (let item in obj) output[item] = (map as any)(obj[item], item);
  return output;
}
export type RecordOf<T extends {}> = Record<keyof T, T[keyof T]>;
export type ArrayOf<T extends any[]> = T[number][];

export type ObservableType<T> = T extends Observable<infer X> ? X : never;
export type Complete<T> = {
  [P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>>
  ? T[P]
  : T[P] | undefined;
};

export type Intersect<T> = (T extends any ? (x: T) => any : never) extends (
  x: infer R
) => any
  ? R
  : never;



type IndexableKeyTypes = string //| number | symbol
type Indexable<T = unknown> = Record<string, T>
type JustIndexableTypes<T> = T extends IndexableKeyTypes ? T : never
type IndexableKeys<Rec> = KeysMatching<Rec, IndexableKeyTypes>;

type KeysMatching<Rec, Keys> = NonNullable<
  {
    [RecKey in keyof Rec]: Rec[RecKey] extends Keys ? RecKey : never
  }[keyof Rec]
>

export type GroupBy<K extends IndexableKeys<T>, T extends Indexable> = {
  [KV in JustIndexableTypes<T[K]>]: T extends Record<K, KV> ? T[] : never
}
export type KeysOnly<T> = { [P in keyof T]: undefined };
// export type ValueIntersectionByKeyUnion<T, TKey extends keyof Intersect<KeysOnly<T>> = keyof Intersect<KeysOnly<T>>> =
export type ValueIntersectionByKeyUnion<T, TKey extends T extends any ? keyof T : never = T extends any ? keyof T : never> =
  // T extends string | number | symbol | Function | undefined | null ? T :
  T extends Record<TKey, any> ? {
    [P in TKey]: T extends Record<P, any> ? (k: T[P]) => void : never;
  }[TKey] extends (k: infer I) => void ? I : never : never;

export type ValueIntersection<T> =
  T extends string | number | symbol | Function | undefined | null ? T :
  (k: T) => void extends (k: infer I) => void ? I : never;

// type AllPossibleTypes<T> = T extends string | number | symbol | Function | undefined | null | Array<any> ? T : {
//   [K in keyof Intersect<T>]?: AllPossibleTypes<ValueIntersectionByKeyUnion<T, K>>;
// };
export const thrower = (msg: string) => { throw new Error(msg); }

type Head<T> = T extends [infer I, ...infer _Rest] ? I : never
type Tail<T> = T extends [infer _I, ...infer Rest] ? Rest : never

type Zip_DeepMergeTwoTypes<T, U> = T extends []
  ? U
  : U extends []
  ? T
  : [
    DeepMergeTwoTypes<Head<T>, Head<U>>,
    ...Zip_DeepMergeTwoTypes<Tail<T>, Tail<U>>
  ]


/**
 * Take two objects T and U and create the new one with uniq keys for T a U objectI
 * helper generic for `DeepMergeTwoTypes`
 */
type GetObjDifferentKeys<
  T,
  U,
  T0 = Omit<T, keyof U> & Omit<U, keyof T>,
  T1 = { [K in keyof T0]: T0[K] }
> = T1
/**
 * Take two objects T and U and create the new one with the same objects keys
 * helper generic for `DeepMergeTwoTypes`
 */
type GetObjSameKeys<T, U> = Omit<T | U, keyof GetObjDifferentKeys<T, U>>

type MergeTwoObjects<
  T,
  U,
  // non shared keys are optional
  T0 = Partial<GetObjDifferentKeys<T, U>>
  // shared keys are recursively resolved by `DeepMergeTwoTypes<...>`
  & { [K in keyof GetObjSameKeys<T, U>]: DeepMergeTwoTypes<T[K], U[K]> },
  T1 = { [K in keyof T0]: T0[K] }
> = T1

// it merge 2 static types and try to avoid of unnecessary options (`'`)
export type DeepMergeTwoTypes<T, U> =
  // ----- 2 added lines ------
  [T, U] extends [any[], any[]]
  ? Zip_DeepMergeTwoTypes<T, U>
  // check if generic types are objects
  : [T, U] extends [{ [key: string]: unknown }, { [key: string]: unknown }]
  ? MergeTwoObjects<T, U>
  : T | U

export type PromiseReturnType<T> = T extends Promise<infer X> ? X : never;

export function Extract<T extends string, U extends string>(check: (e: any) => e is Extract<T, U>) { return check; }

// export type Equal<A, B, C> = A extends B ? B extends A ? C : never : never;

/** asserts T is NonNullable\<T\> */
export function okNull<T>(a: T, message?: string): asserts a is NonNullable<T> {
  if ((a ?? null) === null) { debugger; throw new Error(message || "Assertion Failed"); }
}
/** common: asserts T  */
export function ok<T>(a: T, message?: string): asserts a {
  if (!a) { debugger; throw new Error(message || "Assertion Failed"); }
}
export async function readExif(file: File) {
  const reader = file.stream().getReader();
  let item = await reader.read();
  let pos = 0, length = 0, buffer: any = null;
  while (!item.done && (!buffer || pos < length)) {
    if (!buffer)
      for (let i = 0; i < item.value.length; i++) {
        if (item.value[i] == 0xFF && item.value[i + 1] == 0xE1) {
          length = new DataView(item.value.buffer, item.value.byteOffset + i + 2).getUint16(0, false);
          buffer = Buffer.alloc(length);
          const val = item.value.slice(i + 4, length);
          buffer.set(val);
          pos = val.length;
          break;
        }
      }
    else if (pos < length) {
      const val = item.value.slice(0, length - pos);
      buffer.set(val, pos);
      pos += val.length;
    } else {
      break;
    }
    item = await reader.read();
  }
  reader.cancel();
  reader.releaseLock();
  console.log(buffer);
  return exifReader(buffer);
}



export type RecursiveUnwrap<T> =
  T extends Prisma.PrismaPromise<infer X> ? RecursiveUnwrap<X> :
  T extends Promise<infer X> ? RecursiveUnwrap<X> :
  T extends ProxyPromise ? any :
  T;

export interface DataQueryAction<T> {
  req: ProxyPromise;
  res?: (data: RecursiveUnwrap<T>) => void;
}

export interface RequestsTupleItem<T> {
  statusCode: 400 | 500 | 200;
  success: boolean;
  body: RecursiveUnwrap<T>;
}


export type MapDataQueryGraph<Tuple extends readonly unknown[]> = {
  [K in keyof Tuple]: K extends `${number}` ? DataQueryAction<Tuple[K]> : never;
};

export type MapTuple<Tuple extends readonly unknown[]> = {
  [K in keyof Tuple]: K extends `${number}` ? RecursiveUnwrap<Tuple[K]> : never
};


export type MapRequestsTuple<Tuple extends readonly unknown[]> = {
  [K in keyof Tuple]: K extends `${number}` ? RequestsTupleItem<Tuple[K]> : never
};



export class DataQueryGraph {
  constructor(
    public readonly root: TYPE_NAMES,
    public readonly id: string | undefined,
    public readonly role: Roles
  ) {

  }
  actions: DataQueryAction<any>[] = [];
  /** These functions are called after the main request starts, and then awaited before calling action results */
  extraRequests: (() => Promise<unknown>)[] = [];
  hookBeforeResponse: (() => void)[] = [];
  hookAfterResponse: (() => void)[] = [];

  errors: Error[] = [];
  proxy = proxy;

  getRequests() {
    const valid = this.actions
      .map((e, i) => e.req instanceof ProxyPromise || this.errors[i])
      .every(e => e !== true ? console.log(e) : true);
    if (!valid) throw "Invalid requests were found";

    return this.actions.map(e => e.req);
  }


  // addAction<E extends Prisma.PrismaPromise<any>>(req: E, res?: (data: UnwrapPromise<E>) => void): this is [...P, E];
  // addAction(req: Omit<ProxyPromise, "#error">, res?: (data: any) => void): this is [...P, any];
  // addAction(req: ProxyPromise, res?: (data: any) => void) {
  //   if (!(req instanceof ProxyPromise)) req = new ProxyPromise(req);
  //   this.actions.push({ req, res });
  //   this.errors.push(new Error("An error occured for this request"));
  //   return true;
  // }
  /** 
   * The callback is called after the main request starts, and then awaited alongside it in a Promise.all. 
   * Rejections are not caught so they will cause the entire request to fail.
   */
  addExtraRequest<T>(cb: () => Promise<T>) {
    this.extraRequests.push(cb);
  }
  /** 
   * Returns a promise hook that resolves after the request is made but before the promises are resolved. 
   * Since all hooks are resolved syncly, this makes very little difference.
   */
  addHookBeforeResponse() {
    return new Promise<void>(res => {
      this.hookBeforeResponse.push(res);
    });
  }
  /** 
   * Returns a promise hook that resolves after the request is made and the promises are resolved. 
   * Since all hooks are resolved syncly, this makes very little difference.
   */
  addHookAfterResponse() {
    return new Promise<void>(res => {
      this.hookAfterResponse.push(res);
    });
  }

  addPromise<E extends Prisma.PrismaPromise<any>>(req: E): E;
  addPromise(req: Omit<ProxyPromise, "#error">): Promise<any>;
  addPromise(req: ProxyPromise) {
    if (!(req instanceof ProxyPromise))
      req = new ProxyPromise(req);
    return new Promise(res => {
      this.actions.push({ req, res })
      this.errors.push(new Error("An error occured for this request"));
    });
  }

}

export * from "./ProxyPromise";
export const asUtc = (date: Date) => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
export const asLocal = (date: Date) => new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

export type TypedChanges<T> = {
  [K in keyof T]?: {
    previousValue: T[K];
    currentValue: T[K];
    firstValue: boolean;
  }
}

export const LOADING_FIELD_SCROLLER = Symbol("LOADING_FIELD_SCROLLER");

export * from "./cubes-logo";

/** Wraps the method in another function which runs the original function inside zone.run and returns the result */
export function ZoneGuard() {
  // technically the target does not actually contain zone, since it is the prototype of the class
  return (_target: { zone: NgZone }, _propertyKey: string, descriptor: PropertyDescriptor) => {
    const original = descriptor.value;
    descriptor.value = function (this: { zone: NgZone }, ...args: any[]) {
      return original.apply(this, args);
    };
    return descriptor;
  };
}

export function formatFileSize(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024,
    dm = decimals < 0 ? 0 : decimals,
    sizes = [
      'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB',
      // github copilot filled these in :D
      'EB', 'ZB', 'YB', 'BB', 'NB', 'DB', 'CB', 'XB', 'WB', 'VB'
    ],
    i = Math.floor(Math.log(bytes) / Math.log(k)),
    tooLong = (bytes / Math.pow(k, i)).toFixed(0).length > 3 ? 1 : 0;
  return parseFloat((bytes / Math.pow(k, i + tooLong)).toFixed(dm)) + ' ' + sizes[i + tooLong];
}

export const arrayListKeys = (e: SPPI | TableViewColumn): SPPI => typeof e === "string" ? e : e.key;

/** 
 * From https://github.com/JamesEggers1/node-ABAValidator/blob/master/src/aba-validation.ts
 */
export function checkRoutingNumber(routingNumber: string): boolean {

  const match = routingNumber.match(/^\s*([\d]{9})\s*$/);

  if (!match) { return false; }

  const weights = [3, 7, 1];
  const aba = match[1];

  let sum = 0;
  for (let i = 0; i < 9; ++i) {
    sum += +aba.charAt(i) * weights[i % 3];
  }

  return (sum !== 0 && sum % 10 === 0);

}

