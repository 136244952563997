import { card, cvc, expiration } from 'creditcards';
import { CardType, defaults as cardTypes } from 'creditcards/types';
import { is } from "common";

export const CreditCardParser = {
  cardLookup: cardTypes.reduce((n, e) => (n[e.name] = e, n), {} as Record<string, CardType>),
  formatUserInput(number: string) {
    number = number.split(" ").join("");
    const type = card.type(number, true);
    return !type ? number : this.cardLookup[type].group(number).join(" ");
  },

  parseCardNumber(number: string, partial: boolean) {
    const type = card.type(number, partial);
    if (!type)
      return;
    const mask1 = type ? this.cardLookup[type].group("9".repeat(25)).join(" ") : "9999 ".repeat(5);
    const mask2 = "9".repeat(this.cardLookup[type].cvcLength);
  },

  checkCardNumber(value: string) {
    const res = card.isValid(value);
    console.log(value, res);
    return res;
  },
  checkExpiration(value: string) {
    let [month, year] = value.split("/");
    year = this.formatFullYear(year);
    if (!expiration.year.isValid(+year))
      return false;
    if (!expiration.month.isValid(+month))
      return false;
    if (expiration.isPast(+month, +year))
      return false;
    return true;
  },
  checkCVV(ccn: string, cvv: string) {
    if (!ccn || !cvv)
      return false;
    const type = card.type(ccn);
    return cvc.isValid(cvv, type);
  },

  formatFullYear(year: string | number): string {
    // it it's a number type, convert it to a string
    if (typeof year === "number")
      year = "" + year;
    // make sure the string is a parsable number
    if (!is<`${number}`>(year, !isNaN(+year)))
      return "";
    // if it's a two digit number, convert it to a four digit year, then return the year
    return year.length === 2 ? this.resolveFullYear(year).toFixed(0) : year.length === 4 ? year : "";
  },
  resolveFullYear(d: `${number}`) {
    // please tell me no credit card takes 70 years to expire
    const EPOCH = new Date().getFullYear() - 30;
    // get the remainder of dividing by 100
    const EPOCH2 = EPOCH % 100;
    // get the century
    const CENTURY = Math.floor(EPOCH / 100) * 100;
    // parse the input year
    const input = +d;
    // add the century, plus 100 if the input is less than the cutoff in the lower century, plus the input
    return CENTURY + (EPOCH2 > input ? 100 : 0) + input;
  },
};
