import { Box, Icon, Listbox, Spinner, TextField } from "@shopify/polaris";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { PopoverDropdown, useComboBoxState } from "./TextFieldDropdown";
import { LocationIcon } from "@shopify/polaris-icons";




export function QuestionAutoComplete<T extends Record<string, any>, V extends (string & keyof T) | undefined>({
  value, onChange, delay, minLength = 3, onSearch, optionValue, optionLabel, searchOnFocus, ...textFieldProps
}: {
  label: string;
  error?: string;
  required?: boolean;
  value: T | null;
  onChange: (value: T | null, id?: string) => void;
  optionLabel: string & keyof T;
  optionValue: V;
  minLength?: number;
  delay?: number;
  searchOnFocus?: boolean;
  onSearch: (input: string) => Promise<T[]>;
} & Omit<React.ComponentProps<typeof TextField>, "onChange" | "value">) {
  // console.log("QuestionAutoComplete");
  const [options, setOptions] = useState<T[] | null>(null);
  const [loading, setLoading] = useState(false);
  const timeout = useRef<any>();
  const [raw, setRaw] = useState("");

  const { focus, open, setState, onFocus, onBlur } = useComboBoxState();

  const wasOpen = useRef(false);

  useEffect(() => { wasOpen.current = open; }, [open]);

  const onSelect = (index: string) => {
    if (!options) return;
    const value = options[+index];
    setState({ focus: false, open: false });
    onChange(value);
  };

  useLayoutEffect(() => {
    // sets the field text when the value changes
    setRaw(value?.[optionLabel] ?? "");
  }, [value, optionLabel]);

  useLayoutEffect(() => {
    // sets the field text when the field closes (automatic forceSelection)
    if (focus || open) return;
    setRaw(value?.[optionLabel] ?? "");
  }, [focus, open, optionLabel, value]);


  const onChangeField = (raw: string) => {
    console.log(raw, raw.length >= minLength);
    setRaw(raw);
    setLoading(false);
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = undefined;
    }
    if (raw === "") {
      setOptions(null);
      onChange(null);
    } else if (raw.length >= minLength) {
      timeout.current = setTimeout(async () => {
        timeout.current = undefined;
        setLoading(true);
        setOptions(await onSearch(raw));
        setLoading(false);
        setState({ open: true });
      }, delay ?? 500);
    } else {

    }
  };


  const emptyMarkup = (text: string) => (
    <Box paddingBlock="200">
      <Listbox accessibilityLabel={text}>
        <Listbox.Option key="" value="" disabled={true} selected={false}>
          {text}
        </Listbox.Option>
      </Listbox>
    </Box>
  );

  return <PopoverDropdown
    focus={focus}
    open={open}
    setState={setState}
    activator={<TextField
      role="combobox"
      prefix={<Icon source={LocationIcon} />}
      suffix={loading && <Spinner size="small" />}
      onFocus={onFocus}
      onBlur={onBlur}
      value={raw}
      onChange={onChangeField}
      {...textFieldProps}
    />}
    dropdown={<>
      {raw.length < minLength ? emptyMarkup("Minimum length is " + minLength) : null}
      {options && (
        options.length
          ? (
            <Box paddingBlock="100">
              <Listbox key={JSON.stringify(options)} onSelect={onSelect} accessibilityLabel="Address results">
                {options.map((e, i) => <Listbox.Option value={"" + i} key={e[optionLabel]}>{e[optionLabel]}</Listbox.Option>)}
              </Listbox>
            </Box>
          )
          : emptyMarkup("No results found")
      )}
    </>
    }
  />;
};


