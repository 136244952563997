
import { Attributes, SCALAR_NAMES, TYPE_NAMES, resolveDots, index_makeName, TABLE_NAMES, GenericPathProxyWithTable } from "./cubes-utils";
import { AnyMember, EnumType, IsArray, RecordType, ScalarType, truthy, RecordMember, Wrapping, MemberKeys } from "./graphql-declarator";
import { ScalarJSON, Boolean, ID, } from "./cubes-amazon-old";
import { TableSecurity, TableType } from "./cubes-schema-helpers";
import { QueryJoiner, QueryJoinerValue } from "./createJoinQuery";
import { RLS, rls, rowlevelsecurity } from "./cubes-schema";
import { Root } from "./cubes-index";

const ENABLE_RLS = true;
const CREATE_USERS = true;


export const output = {} as any;
export const header = {} as any;

export function sqlPermissions(root: Root, user: string) {
  const userRoles = {
    web_central_admin: `${user}_web_central_admin`,
    web_central_user: `${user}_web_central_user`,
    web_dealer_admin: `${user}_web_dealer_admin`,
    web_dealer_user: `${user}_web_dealer_user`,
  }
  const allRoles = {
    app: `${user}_app`,
    web_admin: `${user}_web_admin`,
    web_user: `${user}_web_user`,
    // ...userRoles,
  };

  // `CREATE ROLE ${roles.app} WITH LOGIN NOSUPERUSER INHERIT NOCREATEDB NOCREATEROLE NOREPLICATION;`,
  // `GRANT rds_iam TO ${roles.app};`,
  // "arn:aws:rds-db:*:976272590702:dbuser:*/cubeswebdev2_app",
  // "arn:aws:rds-db:*:976272590702:dbuser:*/cubeswebdev2_web_admin",
  // "arn:aws:rds-db:*:976272590702:dbuser:*/cubeswebdev2_web_central",
  // "arn:aws:rds-db:*:976272590702:dbuser:*/cubeswebdev2_web_dealer"
  return [

    // this is needed regardless of whether it is enabled
    `
CREATE OR REPLACE FUNCTION set_user_id(user_id uuid) RETURNS VOID LANGUAGE PLPGSQL AS $$
BEGIN
  EXECUTE 'SET LOCAL rls_security.user_id = ' || quote_literal(user_id);
END
$$;

CREATE OR REPLACE FUNCTION set_user_level(user_level "BranchUserLevel") RETURNS VOID LANGUAGE PLPGSQL AS $$
BEGIN
  EXECUTE 'SET LOCAL rls_security.user_level = ' || quote_literal(user_level);
END
$$;

CREATE OR REPLACE FUNCTION set_branch_id(branch_id uuid) RETURNS VOID LANGUAGE PLPGSQL AS $$
BEGIN
  EXECUTE 'SET LOCAL rls_security.branch_id = ' || quote_literal(branch_id);
END
$$;

CREATE OR REPLACE FUNCTION set_branch_type(branch_type "BranchType") RETURNS VOID LANGUAGE PLPGSQL AS $$
BEGIN
  EXECUTE 'SET LOCAL rls_security.branch_type = ' || quote_literal(branch_type);
END
$$;

CREATE OR REPLACE FUNCTION set_division_id(division_id uuid) RETURNS VOID LANGUAGE PLPGSQL AS $$
BEGIN
  EXECUTE 'SET LOCAL rls_security.division_id = ' || quote_literal(division_id);
END
$$;
`, `
CREATE OR REPLACE FUNCTION raise_exception_id_must_not_be_updated() RETURNS TRIGGER AS $$
BEGIN
  RAISE EXCEPTION 'id must not be updated';
  RETURN NEW;
END;
$$ LANGUAGE plpgsql;
    `,
    ...CREATE_USERS ? Object.keys(allRoles).map(e =>
      `
CREATE ROLE ${allRoles[e]} WITH
  LOGIN
  INHERIT
  NOSUPERUSER
  NOCREATEDB
  NOCREATEROLE
  NOREPLICATION
  NOBYPASSRLS;
GRANT rds_iam TO ${allRoles[e]};

      `) : [],
    ...Object.keys(allRoles).map(e => `
GRANT CONNECT ON DATABASE ${user} TO ${allRoles[e]};`
    ), `
GRANT USAGE ON SCHEMA public TO PUBLIC;
GRANT ALL ON SCHEMA public TO postgres;
      `,
    ...Object.entries(root.types).map(([key, val]) => {

      const attrs = val.__typeAttributes();
      if (val.__is("table") && !attrs.model?.length) throw new Error("no model for " + key);
      if (!attrs.model?.length) return "";
      if (attrs.mapsTo?.length) key = attrs.mapsTo.first()?.name || "";
      if (!key) throw new Error("no key");

      const lines = [
        `-- ${key}
CREATE OR REPLACE TRIGGER prevent_primary_key_update_trigger
BEFORE UPDATE OF id ON "${key}" FOR EACH ROW WHEN (OLD.id <> NEW.id)
EXECUTE FUNCTION raise_exception_id_must_not_be_updated();`,
        ...Object.keys(allRoles).map((role: keyof typeof allRoles) => `REVOKE ALL ON TABLE public."${key}" FROM ${allRoles[role]};`),
      ];

      // select polname, pg_class.relname from pg_catalog.pg_policy inner join pg_catalog.pg_class on polrelid = pg_class.oid

      const rls2 = attrs.rls?.first();
      const doRLS = ENABLE_RLS && !!rls2;

      lines.push(`ALTER TABLE public."${key}" ${doRLS ? "ENABLE" : "DISABLE"} ROW LEVEL SECURITY;`);

      if (doRLS) {
        lines.push(
          `DROP POLICY IF EXISTS write_rls_app ON public."${key}";`,
          `CREATE POLICY write_rls_app ON public."${key}" TO ${allRoles.app} USING (true);`,
          `DROP POLICY IF EXISTS write_rls_web_admin ON public."${key}";`,
          `CREATE POLICY write_rls_web_admin ON public."${key}" TO ${allRoles.web_admin} USING (true);`,
          makePolicy(key as TABLE_NAMES, "read", allRoles.web_user, rls2),
          makePolicy(key as TABLE_NAMES, "write", allRoles.web_user, rls2),
        );
      }

      if (attrs.authPostgres?.length) {
        lines.push(...attrs.authPostgres.map(e => {
          if (!e.privelages || !e.role) throw new Error("privelages or role not specified");
          return e.privelages.length
            ? `GRANT ${e.privelages.join(", ")} ON TABLE public."${key}" TO ${allRoles[e.role]};`
            : `-- ${key} - ${allRoles[e.role]} - no grants`;
        }));
      }

      return lines.join("\n");

    })
  ].filter(truthy).join("\n");
}
// const PERMISSION_VALUE = `"public"."UserPermission"."value"::UUID[]`;
// function makeRolesPolicy(
//   rls: rowlevelsecurity<any>["attr"],
//   role: "web_central_admin" | "web_central_user" | "web_dealer_admin" | "web_dealer_user",
//   val: RecordType,
//   lines: string[],
//   key: string,
//   allRoles: { web_central_admin: string; web_central_user: string; web_dealer_admin: string; web_dealer_user: string; app: string; web_admin: string; web_user: string; }
// ) {
//   const using = rls[role] === false ? "false" :
//     rls[role] ? Object.entries(rls[role]).map(([k, v]: [string, Record<string, string>]) => {
//       const item: RecordMember<any> = (val as any)[k];
//       if (typeof v === "string") {
//         if (item.__fieldAttributes().belongsTo?.length && !k.endsWith("ID"))
//           k = k + "ID";
//         return `"${k}" = current_setting('${v}')::UUID`;
//       } else {
//         return `EXISTS(SELECT 1 FROM "${item.__name}" WHERE ${Object.entries(v).map(([k2, v2]) => {
//           const item2: RecordMember<any> = (item as any)[k2];
//           if (item2.__fieldAttributes().belongsTo?.length && !k2.endsWith("ID"))
//             k2 = k2 + "ID";
//           if (v2.startsWith("rls_security."))
//             return `"${k2}" = current_setting('${v2}')::UUID`;
//           const val2: RecordMember<any> = (val as any)[v2];
//           if (val2.__fieldAttributes().belongsTo?.length && !v2.endsWith("ID"))
//             v2 = v2 + "ID";
//           return `"${k2}" = "${val.__name}"."${v2}"`;
//         }
//         ).join(" AND ")})`;
//       }
//     }).join(" AND ") : "";
//   return [
//     `DROP POLICY IF EXISTS ${key}_rls_${role} ON public."${key}";`,
//     `CREATE POLICY ${key}_rls_${role} ON public."${key}" TO ${allRoles[role]} ${!using ? `USING (true)` : `USING(${using})`};`
//   ].join("\n");
// }

function makePolicy(table: TABLE_NAMES, action: "read" | "write", user: string, tableRLS: RLS) {

  if (action !== "read" && action !== "write") throw new Error("invalid action " + action);
  const isRead = action === "read";

  const PermissionName = (level: "table" | "groups" | "rows") => `${action} in ${table} for ${level} of ${tableRLS.remoteTable}`;
  const root = `FROM public."UserPermission"`;
  return /*sql*/`
DROP POLICY IF EXISTS ${action}_rls_${user} ON public."${table}";
CREATE POLICY ${action}_rls_${user}
ON public."${table}" 
FOR ${isRead ? "SELECT" : "ALL"}
TO ${user} 
USING(EXISTS(SELECT 1 ${new QueryJoiner({ userperm: "UserPermission", table }, {}, root)
      .build(({ userperm, table }) => ({
        [userperm.userID.__]: `current_setting('rls_security.user_id')::UUID`,
        [userperm.perm.name.__]: `'${PermissionName("table")}'`
      }), [])
      .format()
    }) OR EXISTS(SELECT 1 ${new QueryJoiner({ userperm: "UserPermission", table }, {}, root)
      .build(({ userperm, table }) => ({
        [userperm.userID.__]: `current_setting('rls_security.user_id')::UUID`,
        [userperm.perm.name.__]: `'${PermissionName("rows")}'`,
        ["table:" + tableRLS.rows]: new QueryJoinerValue(userperm.value.__, val => `ANY(${val}::UUID[])`),
      }), ["table:" + tableRLS.rows])
      .format()
    }) OR EXISTS(SELECT 1 ${new QueryJoiner({ userperm: "UserPermission", table }, {}, root)
      .build(({ userperm, table }) => ({
        [userperm.userID.__]: `current_setting('rls_security.user_id')::UUID`,
        [userperm.perm.name.__]: `'${PermissionName("groups")}'`,
        ["table:" + tableRLS.groups]: new QueryJoinerValue(userperm.value.__, val => `ANY(${val}::UUID[])`),
      }), ["table:" + tableRLS.groups])
      .format()
    }));`;
}
const j = JSON.stringify;


export function CreateSQL(root: Root, client_build_output: string | boolean, kysely: string | boolean) {
  const scalarMap: Record<SCALAR_NAMES, (flag: string) => string> = {
    ScalarDate: (flag: string) => `String${flag}`,
    ScalarDateTime: (flag: string) => "DateTime" + flag,
    ScalarEmail: (flag: string) => "String" + flag,
    ScalarIPAddress: (flag: string) => "String" + flag,
    ScalarJSON: (flag: string) => "Json" + flag,
    ScalarPhone: (flag: string) => "String" + flag,
    ScalarTime: (flag: string) => `String${flag}`,
    ScalarTimestamp: (flag: string) => "DateTime" + flag,
    ScalarURL: (flag: string) => "String" + flag,
    Boolean: (flag: string) => "Boolean" + flag,
    Float: (flag: string) => "Float" + flag,
    Int: (flag: string) => "Int" + flag,
    String: (flag: string) => "String" + flag,
    ID: (flag: string) => `String${flag} @db.Uuid`,
    CubesDinero: (flag: string) => `Int${flag}`,
  }
  const relations: any = {};
  const hasMany: any = {};
  const hasOne: any = {};
  const records: Record<string, Record<string, Record<string, RecordMember<any>>>> = {} as any;
  const recordHosts: Record<string, string[]> = {};
  const tables = Object.values(root.types)
    .map(item => {
      output[item.__name] = {};
      item.__listMembers().forEach(([key, field]) => {
        let isArray = false;
        if (field instanceof IsArray) { field = field.__wrap; isArray = true; }
        const attrs: Attributes<any> = field.__fieldAttributes();
        if (field instanceof RecordType) {

          let key1 = field instanceof TableType ? `${field.__name}` : `${field.__name}_${item.__name}_${key}`
          // key1 += ` @relation("Child_${item.__name}_${key}")`;
          if (!recordHosts[key1]) recordHosts[key1] = [];
          if (item instanceof TableType) recordHosts[key1].push(item.__name);
          if (!(field instanceof TableType)) {
            if (!(records)[field.__name]) (records)[field.__name] = {};
            if (!(records)[field.__name][item.__name]) (records)[field.__name][item.__name] = {};
            (records)[field.__name][item.__name][key] = field;
          }

          field.__listMembers().forEach(([childKey, child]) => {
            let key2 = `${child.__name}_${field.__name}_${childKey}`;
            if (!recordHosts[key2]) recordHosts[key2] = [];

            if (child instanceof TableType) {
              // recordHosts[key2].push(field.__name);
            } else if (child instanceof RecordType) {
              recordHosts[key2].push(key1);
            }
          });
        }

        if (item instanceof TableType) {
          if (attrs.index?.length) attrs.index.forEach(e => {
            const name = index_makeName(item.__name, key);
            relations[name] = index_makeName(item.__name, field instanceof RecordType ? field.__name : key);
          });
          [...attrs.belongsTo ?? []].forEach(e => {
            const name = index_makeName(item.__name, e.root || key);
            relations[name] = item.__name;
            console.log("belongsTo", name);

          });
          [...attrs.hasOne ?? []].forEach(e => {
            const name = e.relationName || index_makeName(field.__name, (item.__name));
            hasOne[name] = key;
            console.log("hasOne", name, item.__name);
          });
          if (attrs.hasMany?.length) attrs.hasMany.forEach(e => {
            const name = e.indexName || index_makeName(field.__name, (item.__name));
            hasMany[name] = item.__name;
          })
        }
      });

      return item;
    })
    .lift(e => { console.log(relations); return e; })
    .filter((e): e is RecordType => e instanceof RecordType)
    .sort((a, b) => +(a instanceof TableType) - +(b instanceof TableType))
    .map((e) => createDefinition(e))
    .filter(truthy)
    .join("\n");

  const enums = Object.values(root.enums)
    .map((e) => createDefinition(e))
    .filter(truthy)
    .join("\n");

  return (client_build_output ? `
generator client {
  provider = "prisma-client-js"
  previewFeatures = ["relationJoins"]
  ${typeof client_build_output === "string" ? `output = "${client_build_output}"` : ""}
  binaryTargets = ["native", "debian-openssl-1.0.x", "rhel-openssl-1.0.x", "rhel-openssl-3.0.x", "linux-arm64-openssl-1.0.x", "linux-arm64-openssl-3.0.x"]
}

generator json {
  provider = "prisma-json-types-generator"
  namespace = "PrismaJson"
  ${typeof client_build_output === "string" ? `clientOutput = "${client_build_output}"` : ""}
  useType = "AllTypes"
}
` : "") + (kysely ? `
generator kysely {
  provider = "prisma-kysely"
  ${typeof kysely === "string" ? `output = "${kysely}"` : ""}
  fileName = "types.ts"
  enumFileName = "enums.ts"
}
` : "") + `
datasource db {
  provider = "postgresql"
  url      = env("DATABASE_URL")
}
${enums}
${tables}
`;

  function makeRelator(key: string) { return key.slice(0, 1).toLowerCase() + key.slice(1) + "ID"; }
  function checkRelators(item: AnyMember<any, any>, fields: string[]) { return fields.map(k => item.__member(k).__is("table") ? makeRelator(k) : k).join(",") }
  function createDefinition(item: AnyMember<any, any>): string {
    if (item instanceof TableType) {
      let members = item.__listMembers();
      const mapto = item.__typeAttributes().mapsTo?.first()?.name;
      const indexPrisma = item.__typeAttributes().indexPrisma;

      const headerlines = members.map(([key, field]) => {

        const attrs: Attributes<any> = field.__fieldAttributes();
        const isRelation = !!attrs.belongsTo?.first()?.isRelation;
        if (attrs.index?.length) return attrs.index.map(e => {
          return `  @@index([${[key + (isRelation ? "ID" : ""), ...(e.sortKeyFields || [])].join(",")}])`;
        }).filter(truthy).join("\n");

        return;
      }).filter(truthy);

      indexPrisma?.forEach(e => {
        const fields = e.fields.map(k => item.__member(k).__is("table") ? makeRelator(k) : k).map(e => `"${e}"`).join(",");
        headerlines.push(`  @@index(${[
          `[${fields}]`,
          e.name ? `name: "${e.name}"` : "",
          e.map ? `map: "${e.map}"` : "",
          e.length ? `length: ${e.length}` : "",
          e.sort ? `sort: "${e.sort}"` : "",
          typeof e.clustered === "boolean" ? `clustered: ${e.clustered ? "TRUE" : "FALSE"}` : "",
          e.type ? `type: ${e.type}` : "",
          e.ops ? `ops: ${e.ops}` : "",
        ].filter(truthy).join(", ")})`);
      });

      headerlines.push(...item.__typeAttributes().uniquePrisma?.map(e =>
        e.fields && `@@unique([${checkRelators(item, e.fields)}])`
      ).filter(truthy) ?? []);

      if (mapto) headerlines.push(`  @@map("${mapto}")`);

      const map: Record<string, boolean> = members.reduce((n, [k, v]) => Object.assign(n, { [k]: true }), {} as any)
      const resFields: any = {};
      const itemhasOne: any = {};
      const jsonFields: any = {};

      members.forEach(([key, field]): string => {

        let isArray = false;
        if (field instanceof IsArray) { field = field.__wrap; isArray = true; }
        const isRequired = field.__required;
        if (isArray && isRequired)
          throw new Error(`cannot make list elements required on ${item.__name} ${field.__name}`);
        const attrs = field.__fieldAttributes();
        if (attrs.field?.first()?.clientSideOnly) return '';
        if (field instanceof ID && attrs.primaryKey?.length) {
          resFields[key] = `${scalarMap.ID("")} @id @default(dbgenerated(\"gen_random_uuid()\"))`;
          const mapsTo = attrs.mapsTo?.first()?.name;
          if (mapsTo) resFields[key] += ` @map(${JSON.stringify(mapsTo)})`;
          return resFields[key];
        } else if (field instanceof TableType) {
          return accTableTypeField(isArray, isRequired, resFields, key, field, attrs, map, itemhasOne);
        } else if (field instanceof RecordType) {
          // return accRecordTypeField(isArray, isRequired, key, resFields, field);
          const attr = isArray ? "[]" : !isRequired ? "?" : "";
          jsonFields[key] = field.__name;
          resFields[key] = `Json${attr}`;
          const mapsTo = attrs.mapsTo?.first()?.name;
          if (mapsTo) resFields[key] += ` @map(${JSON.stringify(mapsTo)})`;
          return resFields[key];
        } else {

          const attr = isArray ? "[]" : !isRequired ? "?" : "";
          const name = field instanceof ScalarType ? scalarMap[field.__name as SCALAR_NAMES](attr) : field.__name + attr;
          resFields[key] = `${name}`;
          if (field instanceof ScalarJSON) {
            if (field.type.startsWith('"')) console.log(field.type, key, field.__name);
            jsonFields[key] = field.type;
          }

          switch (key) {
            case "updatedAt": resFields[key] += " @updatedAt"; break;
            case "createdAt": resFields[key] += " @default(now())"; break;
          }
          if (itemhasOne[key] || attrs.field?.first()?.unique) resFields[key] += " @unique";
          if (field instanceof EnumType) resFields[key] += ` @default(${field.__listMembers()[0][0]})`;

          const def = attrs.field?.first()?.default;
          if (field instanceof ScalarType && def !== undefined) resFields[key] += ` @default(${JSON.stringify(def)})`

          const mapsTo = attrs.mapsTo?.first()?.name;
          if (mapsTo) resFields[key] += ` @map(${JSON.stringify(mapsTo)})`;

          return resFields[key];
        }

      });
      output[item.__name] = resFields;
      header[item.__name] = headerlines.map(e => e.trim()).filter(truthy);
      return `model ${item.__name} {\n${headerlines.join("\n")}\n${Object.entries(resFields).map(([k, v]) => {
        let lines = "";
        if (jsonFields[k]) lines += `  /// [${jsonFields[k]}]\n`
        return lines + `  ${k} ${v}`;
      }).join("\n")}\n}`

    } else if (item instanceof RecordType) {
      return "";
      // return printRecordType(item.__name, item.__listMembers(), item);
      if (!records[item.__name]) return "";
      let recordUses = records[item.__name] || { "none": { "none": item } };
      return Object.entries(recordUses).flatMap(([hostName, hostFields]) => {
        return Object.entries(hostFields).map(([hostFieldName, item]) => {
          let current = `${item.__name}_${hostName}_${hostFieldName}`
          return printRecordType(current, item.__listMembers(), item, recordHosts[current], hostFieldName);
        })
      }).join("\n")
    } else if (item instanceof EnumType) {
      return `enum ${item.__name} { \n${item.__listMembers().map(e => "  " + e[0]).join("\n")} \n}`;
    }

    throw new Error("unsupported type " + item.__name);

    function printRecordType(
      name: string,
      members: [string, RecordMember<any> & Record<string, any>][],
      item: RecordMember<any>,
      hosts: string[],
      key: string
    ) {
      const resFields: any = {};
      if (hosts) {
        hosts.map(e => {
          resFields[`host_${e}`] = `${e}? @relation("Child_${e}_${key}")`;
        });
      }

      members.forEach(([key, field]) => {
        if (key === "id")
          throw new Error(`id is defined for ${item.__name}`);
        if (hosts && hosts.indexOf("host_" + key) !== -1)
          throw new Error(`host_${key} is defined for ${item.__name} but also used as a host`);
        let isArray = false;
        if (field instanceof IsArray) { field = field.__wrap; isArray = true; }
        const isRequired = field.__required;
        if (isArray && isRequired)
          throw new Error(`cannot make list elements required on ${item.__name} ${field.__name}`);
        if (field instanceof TableType) {
          throw new Error("table references are not supported inside subtypes");
        } else if (field instanceof RecordType) {
          return accRecordTypeField(isArray, isRequired, key, resFields, field);
        } else {
          const attr = isArray ? "[]" : !isRequired ? "?" : "";
          const name = field instanceof ScalarType ? scalarMap[field.__name as SCALAR_NAMES](attr) : field.__name + attr;
          return resFields[key] = `${name}`;
        }
      });
      return `model ${name} {\n${Object.values(resFields).join("\n")}\n}`;
    }

    function accTableTypeField(isArray: boolean, isRequired: boolean, resFields: any, key: string, field: TableType, attrs: Attributes<any>, map: Record<string, boolean>, itemhasOne: any) {
      const attr = isArray ? "[]" : !isRequired ? "?" : "";
      resFields[key] = `${field.__name}${attr}`;
      [...attrs.relationPrisma ?? []].forEach(e => {
        if (e.references && e.fields) {
          const name = index_makeName(item.__name, e.fields.join("_"));
          const actions = `${e.onUpdate ? `, onUpdate: ${e.onUpdate}` : ''}${e.onDelete ? `, onDelete: ${e.onDelete}` : ''}`;
          resFields[key] += ` @relation("${name}", fields: [${checkRelators(item, e.fields)}], references: [${checkRelators(field, e.references)}]${actions})`;
        } else if (e.remote) {
          const remote = (root.types[field.__name] as any)[e.remote] as RecordMember<any>;
          const { fields } = remote.__fieldAttributes().relationPrisma?.first() ?? {};
          const name = fields && index_makeName(field.__name, fields.join("_"));
          if (name) resFields[key] += ` @relation("${name}")`;
        }
      });

      [...attrs.belongsTo ?? []].forEach(e => {
        const name = index_makeName(item.__name, e.root || key);
        if (isArray)
          throw new Error(`belongsTo found on Array member ${key} on ${item.__name}`);
        const actions = `${e.onUpdate ? `, onUpdate: ${e.onUpdate}` : ''}${e.onDelete ? `, onDelete: ${e.onDelete}` : ''}`;
        if (e.root) {
          resFields[key] += ` @relation("${name}", fields: [${[e.root, ...checkRelators(field, e.fields ?? [])].join(",")}], references: [id]${actions})`;
          if (!map[makeRelator(key)])
            throw new Error(`no ID field ${makeRelator(key)} in ${item.__name}`);
          if (!relations[name])
            throw new Error("didn't register " + name);
        } else {
          resFields[key] += ` @relation("${name}", fields: [${makeRelator(key)}], references: [id]${actions})`;
          resFields[makeRelator(key)] = `${scalarMap.ID(!isRequired ? "?" : "")} `;
          if (!relations[name])
            throw new Error("didn't register " + name);
        }
        if (hasOne[name]) {
          if (resFields[makeRelator(key)])
            resFields[makeRelator(key)] += ` @unique`;
          else
            itemhasOne[makeRelator(key)] = true;
        }

      });
      [...attrs.hasOne ?? []].forEach(e => {
        if (isArray) throw new Error(`hasOne found on Array member ${key} on ${item.__name}`);
        const name = e.relationName || index_makeName(field.__name, (item.__name));
        resFields[key] += ` @relation("${name}")`;
        if (!relations[name]) throw new Error("didn't register " + name);
      });


      [...attrs.hasMany ?? []].forEach(e => {
        let name = e.indexName || index_makeName(field.__name, (item.__name));
        resFields[key] += ` @relation("${name}")`;
        if (!relations[name]) {
          throw new Error(`Relation ${name} not found for field ${key} in ${item.__name}`);
        }
      });


      return resFields[key];
    }



    function accRecordTypeField(isArray: boolean, isRequired: boolean, key: string, resFields: any, field: RecordType) {
      const attr = isArray ? "[]" : !isRequired ? "?" : "";
      const lowcap = key.slice(0, 1).toLowerCase() + key.slice(1);
      resFields[key] =
        `${field.__name}_${item.__name}_${key}${attr} @relation("Child_${item.__name}_${key}", fields:[${lowcap + "ID"}], references: [id])`;
      resFields[lowcap + "ID"] =
        `${scalarMap.ID(attr)} @unique`;
      return resFields[key];
    }
  }
}

function CreateGraphQL(root: Root) {
  const scals = Object.values(root.scals)
    .map((e) => createDefinition(e))
    .filter(truthy)
    .join("\n");
  const enums = Object.values(root.enums)
    .map((e) => createDefinition(e))
    .filter(truthy)
    .join("\n");
  const types = Object.values(root.types)
    .filter((e): e is RecordType => !(e instanceof TableType))
    .map((e) => createDefinition(e))
    .filter(truthy)
    .join("\n");

  const tables = Object.values(root.types)
    .filter((e): e is TableType => e instanceof TableType)
    .map((e) => createDefinition(e))
    .filter(truthy)
    .join("\n");

  return [scals, enums, types, tables].join("\n\n");


  function createDefinition(item: AnyMember<any, any>) {

  }
}
